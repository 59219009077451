import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import PricingCard from '../PricingCard/PricingCard';

const PricingTable = ({
	create_checkout_session_endpoint,
	stripe_public_key,
	stripe_product_essential_id,
	stripe_product_advanced_id,
	stripe_product_ultimate_id
}) => {
	const { user } = useAuth();
	const { subscription } = useAuth();
	const navigate = useNavigate();

	const handleSubscriptionClick = async (priceID) => {
		if (!user) {
			// Redirect to sign-up page if the user is not authenticated
			navigate('/signup');
			return;
		}

		// Make sure Stripe is loaded before using it
		const stripe = window.Stripe(stripe_public_key);

		const createCheckoutSession = async (priceID, user) => {
			try {
				const response = await fetch(create_checkout_session_endpoint, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'X-CSRF-Token': document.querySelector('[name="csrf-token"]')
							.content
					},
					body: JSON.stringify({
						priceId: priceID,
						email: user.email,
						first_name: user.first_name,
						last_name: user.last_name,
						price_id: priceID
					})
				});

				const data = await response.json();

				if (data.portalUrl) {
					// Redirect to the Stripe Customer Portal
					window.location.href = data.portalUrl;
				} else if (data.sessionId) {
					// Redirect to the Stripe Checkout
					await stripe.redirectToCheckout({ sessionId: data.sessionId });
				} else {
					console.error('Unexpected response:', data);
				}
			} catch (error) {
				console.error('Error creating Checkout Session:', error);
			}
		};

		await createCheckoutSession(priceID, user);
	};

	const planHierarchy = [
		'Essential Fantasy Fitness',
		'Advanced Fantasy Fitness',
		'Ultimate Fantasy Fitness'
	];

	const getButtonText = (productName) => {
		if (!user) {
			return 'Sign up and subscribe';
		}

		if (subscription && subscription.status === 'active') {
			const currentPlanIndex = planHierarchy.indexOf(subscription.product_name);
			const targetPlanIndex = planHierarchy.indexOf(productName);

			if (currentPlanIndex === targetPlanIndex) {
				return 'Your current plan';
			}

			if (currentPlanIndex < targetPlanIndex) {
				return 'Upgrade';
			}

			return 'Switch';
		}

		// If user is logged in but no subscription exists
		return 'Subscribe';
	};

	const getButtonColor = (productName) => {
		if (!user) {
			return 'bg-blue-500 text-white hover:bg-blue-600';
		}

		if (subscription && subscription.status === 'active') {
			const currentPlanIndex = planHierarchy.indexOf(subscription.product_name);
			const targetPlanIndex = planHierarchy.indexOf(productName);

			if (currentPlanIndex === targetPlanIndex) {
				return 'bg-gray-500 text-white hover:bg-gray-600';
			}

			if (currentPlanIndex < targetPlanIndex) {
				return 'bg-blue-500 text-white hover:bg-blue-600';
			}

			return 'bg-blue-500 text-white hover:bg-blue-600';
		}

		return 'bg-blue-500 text-white hover:bg-blue-600';
	};

	return (
		<div className="flex flex-col items-center space-y-8 max-h-screen overflow-y-auto">
			<div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
				<PricingCard
					title="Essential Fantasy Fitness"
					description="Start strong with Essential Fantasy Fitness: ad-free experience and key tools to kick off your fantasy journey."
					price="$4"
					billingFrequency="per month"
					features={[
						'Roster Sync™ - connect your Yahoo, Sleeper, or FFPC rosters with Fantasy Fitness to gain valuable insights about your team',
						'Manage up to 5 rosters - featuring advanced roster metrics, and the ability to add roster notes, player-specific notes, and more',
						'Collaborate with co-managers - seamlessly share and manage roster access',
						'College Prospect Profiles - complete with Prospect Score™, qualitative analysis, stats and more',
						'Side-by-Side Compare Tool™ - compare up to five prospects, players or teams side-by-side',
						"Playoff Opponent Lists™ - strategize team stacks with each player's Week 15, 16, 17 matchups",
						'Exclusive access to our premium Discord channels',
						'Ad-free experience'
					]}
					buttonText={getButtonText('Essential Fantasy Fitness')}
					buttonColor={getButtonColor('Essential Fantasy Fitness')}
					priceId={stripe_product_essential_id}
					handleSubscriptionClick={handleSubscriptionClick}
				/>

				<PricingCard
					title="Advanced Fantasy Fitness"
					description="Level up with Advanced Fantasy Fitness: ad-free experience and enhanced tools to refine your strategy and manage more rosters."
					price="$8"
					billingFrequency="per month"
					features={[
						'Roster Sync™ - connect your Yahoo, Sleeper, or FFPC rosters with Fantasy Fitness to gain valuable insights about your team',
						'Manage up to 20 rosters - featuring advanced roster metrics, and the ability to add roster notes, player-specific notes, and more',
						'Collaborate with co-managers - seamlessly share and manage roster access',
						'College Prospect Profiles - complete with Prospect Score™, qualitative analysis, stats and more',
						'Side-by-Side Compare Tool™ - compare up to five prospects, players or teams side-by-side',
						"Playoff Opponent Lists™ - strategize team stacks with each player's Week 15, 16, 17 matchups",
						'Draft Tracker with Roster Builder™ - your cutting-edge draft room companion',
						'Players Exposures Overview™ - instantly track your players exposures across platforms, formats, and scoring types',
						'Exclusive access to our premium Discord channels',
						'Ad-free experience'
					]}
					buttonText={getButtonText('Advanced Fantasy Fitness')}
					buttonColor={getButtonColor('Advanced Fantasy Fitness')}
					priceId={stripe_product_advanced_id}
					handleSubscriptionClick={handleSubscriptionClick}
					isDarkBg={true}
					isMostPopular={true}
				/>

				<PricingCard
					title="Ultimate Fantasy Fitness"
					description="Go all-in with Ultimate Fantasy Fitness: ad-free experience, top-tier tools, and exclusive features to dominate every league."
					price="$12"
					billingFrequency="per month"
					features={[
						'Roster Sync™ - connect your Yahoo, Sleeper, or FFPC rosters with Fantasy Fitness to gain valuable insights about your team',
						'Manage up to 100 rosters - featuring advanced roster metrics, and the ability to add roster notes, player-specific notes, and more',
						'Collaborate with co-managers - seamlessly share and manage roster access',
						'College Prospect Profiles - complete with Prospect Score™, qualitative analysis, stats and more',
						'Side-by-Side Compare Tool™ - compare up to five prospects, players or teams side-by-side',
						"Playoff Opponent Lists™ - strategize team stacks with each player's Week 15, 16, 17 matchups",
						'Draft Tracker with Roster Builder™ - your cutting-edge draft room companion',
						'Players Exposures Overview™ - instantly track your players exposures across platforms, formats, and scoring types',
						'Exclusive access to our premium Discord channels',
						'Ad-free experience'
					]}
					buttonText={getButtonText('Ultimate Fantasy Fitness')}
					buttonColor={getButtonColor('Ultimate Fantasy Fitness')}
					priceId={stripe_product_ultimate_id}
					handleSubscriptionClick={handleSubscriptionClick}
				/>
			</div>
		</div>
	);
};

export default PricingTable;
