import React, { useState, useEffect, useRef } from 'react';

const FilterByTeamMultiSelect = ({ filter, onChange }) => {
	const [
		isDropdownOpen,
		setIsDropdownOpen
	] = useState(false);
	const dropdownRef = useRef();

	const teams = [
		'Arizona Cardinals',
		'Atlanta Falcons',
		'Baltimore Ravens',
		'Buffalo Bills',
		'Carolina Panthers',
		'Chicago Bears',
		'Cincinnati Bengals',
		'Cleveland Browns',
		'Dallas Cowboys',
		'Denver Broncos',
		'Detroit Lions',
		'Green Bay Packers',
		'Houston Texans',
		'Indianapolis Colts',
		'Jacksonville Jaguars',
		'Kansas City Chiefs',
		'Las Vegas Raiders',
		'Los Angeles Chargers',
		'Los Angeles Rams',
		'Miami Dolphins',
		'Minnesota Vikings',
		'New England Patriots',
		'New Orleans Saints',
		'New York Giants',
		'New York Jets',
		'Philadelphia Eagles',
		'Pittsburgh Steelers',
		'San Francisco 49ers',
		'Seattle Seahawks',
		'Tampa Bay Buccaneers',
		'Tennessee Titans',
		'Washington Commanders'
	];

	const handleCheckboxChange = (event) => {
		const { value, checked } = event.target;
		let updatedTeams = filter.team ? filter.team.split(',') : [];

		if (checked) {
			updatedTeams.push(value);
		} else {
			updatedTeams = updatedTeams.filter((team) => team !== value);
		}

		onChange('team', updatedTeams.join(','));
	};

	const handleDropdownClick = () => {
		setIsDropdownOpen((prev) => !prev);
	};

	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setIsDropdownOpen(false);
		}
	};

	useEffect(
		() => {
			if (isDropdownOpen) {
				document.addEventListener('click', handleClickOutside);
			} else {
				document.removeEventListener('click', handleClickOutside);
			}

			return () => {
				document.removeEventListener('click', handleClickOutside);
			};
		},
		[
			isDropdownOpen
		]
	);

	return (
		<div className="flex items-center my-2 lg:my-0 mr-2 md:mr-4">
			<label className="text-sm sm:text-base mr-2">Filter By Teams</label>
			<div className="relative">
				{/* Dropdown Button */}
				<button
					className="bg-white p-2 border border-gray-300 rounded-md text-xs sm:text-base w-48 flex justify-between items-center"
					type="button"
					onClick={handleDropdownClick}
				>
					<span className="overflow-hidden text-ellipsis whitespace-nowrap w-full text-left">
						{filter.team ? filter.team.split(',').join(', ') : 'Select teams'}
					</span>
					<span className="ml-2">
						<i className="fa fa-chevron-down" />
					</span>
				</button>

				{/* Dropdown Menu */}
				{isDropdownOpen && (
					<div
						id="team-dropdown"
						ref={dropdownRef}
						className="absolute z-20 w-60 bg-white border border-gray-300 rounded-md shadow-lg transition-all ease-in-out duration-300 max-h-60 overflow-y-auto mt-1"
					>
						<div className="p-2">
							{teams.map((team) => (
								<div key={team} className="flex items-center mb-1">
									<input
										type="checkbox"
										id={team}
										value={team}
										checked={filter.team.split(',').includes(team)}
										onChange={handleCheckboxChange}
										className="mr-2"
									/>
									<label htmlFor={team} className="text-sm sm:text-base">
										{team}
									</label>
								</div>
							))}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default FilterByTeamMultiSelect;
