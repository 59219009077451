import React from 'react';
import { Link } from 'react-router-dom';
import PageTitle from '../../FormElements/PageTitle';
import GlobalErrorsBox from '../../ErrorComponents/GlobalErrorBox/GlobalErrorsBox';
import FormSection from '../../FormElements/FormSection';
import FormSubmit from '../../FormElements/FormSubmit';
import Label from '../../FormElements/Label';
import TextInput from '../../FormElements/TextInput';
import PhoneNumberInput from '../../FormElements/PhoneNumberInput';
import EmailInput from '../../FormElements/EmailInput';
import PasswordInput from '../../FormElements/PasswordInput';
import { useAuth } from '../../../contexts/AuthContext';
import { getTermsOfService } from '../../../utils/getTermsOfService';
import { getPrivacyPolicy } from '../../../utils/getPrivacyPolicy';

class UserSignupForm extends React.Component {
	constructor() {
		super();
		this.state = {
			first_name: '',
			last_name: '',
			phone_number: '',
			email: '',
			password: '',
			agreedToTerms: false,
			errorsList: [],
			fieldErrors: {},
			isModalOpen: false,
			isPrivacyModalOpen: false
		};
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.togglePrivacyModal = this.togglePrivacyModal.bind(this);
	}

	componentDidMount() {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}

	onChange(e) {
		let { ...state } = this.state;
		state[e.target.name] = e.target.value;
		this.setState(state);
	}

	handleCheckboxChange(e) {
		this.setState({ agreedToTerms: e.target.checked });
	}

	async onSubmit(e) {
		e.preventDefault();
		e.stopPropagation();

		if (!this.state.agreedToTerms) {
			this.setState({
				errorsList: [
					'You must agree to the terms of service.'
				]
			});
			return;
		}

		fetch(this.props.create_user_endpoint, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				user: {
					first_name: this.state.first_name,
					last_name: this.state.last_name,
					phone_number: this.state.phone_number,
					email: this.state.email,
					password: this.state.password
				}
			})
		})
			.then((response) => {
				if (!response.ok) {
					return response.json().then((errorData) => {
						throw new Error(JSON.stringify(errorData));
					});
				}
				return response.json();
			})
			.then((data) => {
				this.signInUser(data.is_new_user);
			})
			.catch((error) => {
				const errorData = JSON.parse(error.message);
				this.setState({
					fieldErrors: errorData.field_errors
				});
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});
			});
	}

	async signInUser(isNewUser) {
		const { signIn } = this.props;
		const credentials = {
			email: this.state.email,
			password: this.state.password,
			is_new_user: isNewUser
		};

		try {
			const errorData = await signIn(credentials);
			if (errorData) {
				this.setState({
					errorsList: errorData.errors || [
						'An error occurred during login.'
					]
				});
			}
		} catch (error) {
			this.setState({
				errorsList: [
					'An error occurred during login.'
				]
			});
		}
	}

	toggleModal() {
		this.setState((prevState) => ({
			isModalOpen: !prevState.isModalOpen
		}));
	}

	togglePrivacyModal() {
		this.setState((prevState) => ({
			isPrivacyModalOpen: !prevState.isPrivacyModalOpen
		}));
	}

	render() {
		const termsOfServiceText = getTermsOfService(); // Get the Terms of Service text
		const privacyPolicyText = getPrivacyPolicy(); // Get the Privacy Policy text

		return (
			<div className="lg:w-1/2 py-4 mx-auto">
				<div className="justify-self-center w-full">
					<PageTitle title={'Create your user profile'} />
					<GlobalErrorsBox globalErrors={this.state.errorsList} />
					<FormSection>
						<Label htmlFor="first_name">First Name *</Label>
						<TextInput
							type="text"
							id="first_name"
							name="first_name"
							value={this.state.first_name}
							onChange={this.onChange}
							fieldErrors={this.state.fieldErrors}
						/>
						<Label htmlFor="last_name">Last Name *</Label>
						<TextInput
							type="text"
							id="last_name"
							name="last_name"
							value={this.state.last_name}
							onChange={this.onChange}
							fieldErrors={this.state.fieldErrors}
						/>
						<Label htmlFor="phone_number">Phone *</Label>
						<PhoneNumberInput
							id="phone_number"
							name="phone_number"
							value={this.state.phone_number}
							onChange={this.onChange}
							fieldErrors={this.state.fieldErrors}
						/>
						<Label htmlFor="email">Email *</Label>
						<EmailInput
							id="email"
							name="email"
							value={this.state.email}
							onChange={this.onChange}
							fieldErrors={this.state.fieldErrors}
						/>
						<div>
							<Label htmlFor="password">Password *</Label>
							<PasswordInput
								id="password"
								name="password"
								value={this.state.password}
								onChange={this.onChange}
								fieldErrors={this.state.fieldErrors}
							/>
						</div>
						<div className="mt-4 flex items-center">
							<input
								type="checkbox"
								id="agreeToTerms"
								name="agreeToTerms"
								checked={this.state.agreedToTerms}
								onChange={(e) => this.handleCheckboxChange(e)}
								className="mr-2 h-4 w-4"
							/>
							<label htmlFor="agreeToTerms" className="text-gray-800">
								I agree to the Fantasy Fitness, LLC{' '}
								<button
									type="button"
									onClick={this.toggleModal}
									className="text-blue-500 hover:underline"
								>
									Terms of Service
								</button>
								{' and '}
								<button
									type="button"
									onClick={this.togglePrivacyModal}
									className="text-blue-500 hover:underline"
								>
									Privacy Policy
								</button>
							</label>
						</div>
					</FormSection>
					<div className="grid">
						<div className="mt-4">
							<FormSubmit onSubmit={this.onSubmit} label={'Sign up'} />
						</div>
					</div>
					<p className="text-center mt-2 text-gray-800">
						Already a member?{' '}
						<Link to="/signin" className="text-blue-500 hover:underline">
							Sign in
						</Link>
						.
					</p>
				</div>

				{/* Terms of Service Modal */}
				{this.state.isModalOpen && (
					<div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
						<div
							className="bg-white p-6 rounded-md shadow-lg w-11/12 md:w-1/2"
							style={{ maxHeight: '90vh', overflowY: 'auto' }}
						>
							<h2 className="text-xl font-semibold mb-4">Terms of Service</h2>
							<div className="mb-4">{termsOfServiceText}</div>
							<div className="flex justify-center mt-4">
								<button
									type="button"
									onClick={this.toggleModal}
									className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
								>
									Close
								</button>
							</div>
						</div>
					</div>
				)}

				{/* Privacy Policy Modal */}
				{this.state.isPrivacyModalOpen && (
					<div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
						<div
							className="bg-white p-6 rounded-md shadow-lg w-11/12 md:w-1/2"
							style={{ maxHeight: '90vh', overflowY: 'auto' }}
						>
							<h2 className="text-xl font-semibold mb-4">Privacy Policy</h2>
							<div className="mb-4">{privacyPolicyText}</div>
							<div className="flex justify-center mt-4">
								<button
									type="button"
									onClick={this.togglePrivacyModal}
									className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
								>
									Close
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

const UserFormWithAuth = (props) => {
	const { signIn } = useAuth();
	return <UserSignupForm {...props} signIn={signIn} />;
};

export default UserFormWithAuth;
