import React from 'react';
import ReactApexChart from 'react-apexcharts';

const SuccessRatesChart = ({ teamData, leagueData, teamName }) => {
	// Extract the last word from the team name
	const teamNameLastWord = teamName.split(' ').pop();

	// Ensure data exists and provide fallbacks, converting values to numbers
	const calculateSuccessRate = (conversions, attempts) => {
		if (
			!conversions ||
			!attempts ||
			isNaN(conversions) ||
			isNaN(attempts) ||
			attempts === 0
		) {
			return 0;
		}
		return Math.round(parseFloat(conversions) / parseFloat(attempts) * 100);
	};

	const teamThirdDownSuccess = calculateSuccessRate(
		teamData && teamData.avg_third_down_conversions,
		teamData && teamData.avg_third_down_attempts
	);
	const leagueThirdDownSuccess = calculateSuccessRate(
		leagueData && leagueData.avg_third_down_conversions,
		leagueData && leagueData.avg_third_down_attempts
	);

	const teamFourthDownSuccess = calculateSuccessRate(
		teamData && teamData.avg_fourth_down_conversions,
		teamData && teamData.avg_fourth_down_attempts
	);
	const leagueFourthDownSuccess = calculateSuccessRate(
		leagueData && leagueData.avg_fourth_down_conversions,
		leagueData && leagueData.avg_fourth_down_attempts
	);

	const teamRedZoneSuccess = calculateSuccessRate(
		teamData && teamData.avg_red_zone_conversions,
		teamData && teamData.avg_red_zone_attempts
	);
	const leagueRedZoneSuccess = calculateSuccessRate(
		leagueData && leagueData.avg_red_zone_conversions,
		leagueData && leagueData.avg_red_zone_attempts
	);

	// Chart configuration
	const chartOptions = {
		series: [
			{
				name: `${teamNameLastWord}`,
				data: [
					teamThirdDownSuccess,
					teamFourthDownSuccess,
					teamRedZoneSuccess
				]
			},
			{
				name: 'League Avg',
				data: [
					leagueThirdDownSuccess,
					leagueFourthDownSuccess,
					leagueRedZoneSuccess
				]
			}
		],
		options: {
			chart: {
				type: 'bar',
				height: 350
			},
			plotOptions: {
				bar: {
					columnWidth: '55%',
					distributed: false // Set to false to group bars by category
				}
			},
			colors: [
				'#2980B9',
				'#E67E22'
			],
			stroke: {
				width: 1,
				colors: [
					'#fff'
				]
			},
			title: {
				text: 'Success Rates',
				align: 'center',
				style: { fontSize: '14px' }
			},
			xaxis: {
				categories: [
					'3rd Down',
					'4th Down',
					'Red Zone'
				]
			},
			yaxis: {
				title: {
					text: 'Conversion Rate (%)'
				},
				labels: {
					formatter: (val) => Math.round(val) // Remove decimal places
				}
			},
			tooltip: {
				y: {
					formatter: (val) => `${Math.round(val)}%`
				}
			},
			legend: {
				position: 'top',
				horizontalAlign: 'center'
			}
		}
	};

	return (
		<div className="bg-white p-4 rounded-lg shadow-lg border border-gray-300">
			<ReactApexChart
				options={chartOptions.options}
				series={chartOptions.series}
				type="bar"
				height={250}
			/>
		</div>
	);
};

export default SuccessRatesChart;
