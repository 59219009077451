import React from 'react';

const Modal = ({ children, onClose, modalRef }) => {
	return (
		<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
			<div
				className="relative bg-white rounded-2xl shadow-lg py-6 px-8 w-full max-w-4xl"
				style={{
					maxHeight: '90vh',
					overflowY: 'auto'
				}}
				ref={modalRef}
			>
				<button
					className="absolute top-2 right-2 p-2 rounded-full hover:bg-gray-200 transition duration-300"
					onClick={onClose}
				>
					<svg
						className="h-6 w-6 text-gray-600"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M6 18L18 6M6 6l12 12"
						/>
					</svg>
				</button>
				{children}
			</div>
		</div>
	);
};

export default Modal;
