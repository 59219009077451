import React, { useState, useEffect, Fragment, useRef } from 'react';
import PlayerSearchBar from '../../SearchBars/PlayerSearchBar/PlayerSearchBar';
import StatusWidget from '../../StatusWidget/StatusWidget';
import ComparePlayerGeneralInfo from './ComparePlayerGeneralInfo/ComparePlayerGeneralInfo';
import ComparePlayerFantasyFitnessScores from './ComparePlayerFantasyFitnessScores/ComparePlayerFantasyFitnessScores';
import ComparePlayerProspectScores from './ComparePlayerProspectScores/ComparePlayerProspectScores';
import ComparePlayerPassingStats from './ComparePlayerPassingStats/ComparePlayerPassingStats';
import ComparePlayerRushingStats from './ComparePlayerRushingStats/ComparePlayerRushingStats';
import ComparePlayerReceivingStats from './ComparePlayerReceivingStats/ComparePlayerReceivingStats';
import ComparePlayerTeamEnvironmentScores from './ComparePlayerTeamEnvironmentScores/ComparePlayerTeamEnvironmentScores';
import ComparePlayerAthleticismScores from './ComparePlayerAthleticismScores/ComparePlayerAthleticismScores';

const PlayerComparisonModal = ({
	getPlayersEndpoint,
	get_prospects_endpoint,
	isProspectProfileCard,
	isPlayerCompareModalOpen,
	handlePlayerComparModalClose,
	selectedPlayers,
	handlePlayerSelect,
	handlePlayerRemove
}) => {
	const [
		showComparison,
		setShowComparison
	] = useState(false);
	const modalRef = useRef(null);

	const handleClickOutsidePlayerComparisonModal = (event) => {
		if (!modalRef.current.contains(event.target)) {
			isPlayerCompareModalOpen && handlePlayerComparModalClose();
		}
	};

	// Prevent background scrolling when the modal is open
	useEffect(
		() => {
			if (isPlayerCompareModalOpen) {
				document.body.style.overflow = 'hidden';
			} else {
				document.body.style.overflow = 'auto';
			}
		},
		[
			isPlayerCompareModalOpen
		]
	);

	useEffect(
		() => {
			if (isPlayerCompareModalOpen) {
				document.addEventListener(
					'mousedown',
					handleClickOutsidePlayerComparisonModal
				);
			} else {
				document.removeEventListener(
					'mousedown',
					handleClickOutsidePlayerComparisonModal
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					handleClickOutsidePlayerComparisonModal
				);
			};
		},
		[
			isPlayerCompareModalOpen
		]
	);

	useEffect(
		() => {
			if (!isPlayerCompareModalOpen) {
				setShowComparison(false);
			}
		},
		[
			isPlayerCompareModalOpen
		]
	);

	const handleCompareClick = () => {
		setShowComparison(true);
	};

	const comparisonTable = (
		<div className="mt-4 overflow-y-auto border border-gray-200 rounded-md">
			<div className="overflow-x-auto max-h-screen">
				<table className="min-w-full divide-y divide-gray-200">
					<thead className="bg-gray-50 sticky top-0 bg-gray-100 z-50">
						<tr>
							<th
								scope="col"
								className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase bg-gray-100"
							>
								Category
							</th>
							{selectedPlayers.map((player, index) => (
								<th
									key={`player-${index}`}
									scope="col"
									className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase bg-gray-100"
								>
									<div className="flex items-center">
										<div className="relative mr-4">
											<img
												src={`${player.image_url}?w=75&h=75`}
												alt={`${player.first_name} ${player.last_name}`}
												style={{
													width: '75px',
													height: '75px',
													minWidth: '75px',
													minHeight: '75px',
													objectFit: 'cover',
													border: '1px solid #ddd',
													borderRadius: '50%',
													backgroundColor: 'white'
												}}
											/>
											<div className="absolute top-2 left-12 transform translate-x-1/2 -translate-y-1/2">
												<StatusWidget
													status={player.status}
													description={player.injury_description}
													hideLabel={true}
													is75pxThumbnail={true}
												/>
											</div>
										</div>
										<div>{`${player.first_name} ${player.last_name}`}</div>
									</div>
								</th>
							))}
						</tr>
					</thead>
					<tbody className="bg-white divide-y divide-gray-200">
						<ComparePlayerGeneralInfo
							selectedPlayers={selectedPlayers}
							isProspectProfileCard={isProspectProfileCard}
						/>
						{isProspectProfileCard ? (
							<Fragment>
								<tr>
									<td colSpan={selectedPlayers.length + 1}>
										<div className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase bg-gray-100">
											Fantasy Fitness Scores
										</div>
									</td>
								</tr>
								<ComparePlayerProspectScores
									selectedPlayers={selectedPlayers}
									isProspectProfileCard={isProspectProfileCard}
								/>
							</Fragment>
						) : (
							<Fragment>
								<tr>
									<td colSpan={selectedPlayers.length + 1}>
										<div className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase bg-gray-100">
											Fantasy Fitness Scores
										</div>
									</td>
								</tr>
								<ComparePlayerFantasyFitnessScores
									selectedPlayers={selectedPlayers}
								/>
							</Fragment>
						)}
						{selectedPlayers.some(
							(player) => player.position === 'quarterback'
						) && (
							<Fragment>
								<tr>
									<td colSpan={selectedPlayers.length + 1}>
										<div className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase bg-gray-100">
											Passing Stats
										</div>
									</td>
								</tr>
								<ComparePlayerPassingStats
									selectedPlayers={selectedPlayers}
									isProspectProfileCard={isProspectProfileCard}
								/>
							</Fragment>
						)}

						{selectedPlayers.some(
							(player) =>
								player.position === 'quarterback' ||
								player.position === 'running back'
						) && (
							<Fragment>
								<tr>
									<td colSpan={selectedPlayers.length + 1}>
										<div className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase bg-gray-100">
											Rushing Stats
										</div>
									</td>
								</tr>
								<ComparePlayerRushingStats
									selectedPlayers={selectedPlayers}
									isProspectProfileCard={isProspectProfileCard}
								/>
							</Fragment>
						)}

						{selectedPlayers.some(
							(player) =>
								player.position === 'wide receiver' ||
								player.position === 'tight end' ||
								player.position === 'running back'
						) && (
							<Fragment>
								<tr>
									<td colSpan={selectedPlayers.length + 1}>
										<div className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase bg-gray-100">
											Receiving Stats
										</div>
									</td>
								</tr>
								<ComparePlayerReceivingStats
									selectedPlayers={selectedPlayers}
									isProspectProfileCard={isProspectProfileCard}
								/>
							</Fragment>
						)}
						<tr>
							<td colSpan={selectedPlayers.length + 1}>
								<div className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase bg-gray-100">
									Athleticism
								</div>
							</td>
						</tr>
						<ComparePlayerAthleticismScores selectedPlayers={selectedPlayers} />
						{isProspectProfileCard ? null : (
							<Fragment>
								<tr>
									<td colSpan={selectedPlayers.length + 1}>
										<div className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase bg-gray-100">
											Team Environment
										</div>
									</td>
								</tr>
								<ComparePlayerTeamEnvironmentScores
									selectedPlayers={selectedPlayers}
								/>
							</Fragment>
						)}
						{/*<Fragment>
							<tr>
								<td colSpan={selectedPlayers.length + 1}>
									<div className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase bg-gray-100 border-t border-b border-gray-300">
										Tale of the Tape
									</div>
								</td>
							</tr>
							<ComparePlayerTape selectedPlayers={selectedPlayers} />
						</Fragment> */}
					</tbody>
				</table>
			</div>
		</div>
	);

	return (
		<div
			className={`fixed inset-0 top-0 left-0 w-full h-full bg-black bg-opacity-50 ${isPlayerCompareModalOpen
				? 'block'
				: 'hidden'}`}
			style={{
				zIndex: 1000,
				overflowY: 'auto'
			}}
		>
			<div
				className="p-4 relative mx-auto bg-white rounded-lg shadow-lg overflow-auto max-w-full md:max-w-screen-2xl"
				style={{
					zIndex: 1001
				}}
				ref={modalRef}
			>
				<div className="flex items-center justify-between mb-4">
					<div className="flex items-center">
						<h2 className="text-2xl font-bold mr-4">Compare Players</h2>
						{showComparison && (
							<button
								className="pt-0.5 text-sm text-blue-500 rounded hover:text-blue-600 hover:underline"
								onClick={() => {
									setShowComparison(false);
								}}
							>
								Edit
							</button>
						)}
					</div>
					<button
						className="p-2 rounded-full hover:bg-gray-200 transition duration-300"
						onClick={handlePlayerComparModalClose}
					>
						<svg
							className="h-6 w-6 text-gray-600"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M6 18L18 6M6 6l12 12"
							/>
						</svg>
					</button>
				</div>
				{!showComparison ? (
					<div>
						{selectedPlayers.length < 5 && (
							<PlayerSearchBar
								className="border border-gray-300 rounded-lg p-2 w-full test-sm text-gray-800"
								getPlayersEndpoint={getPlayersEndpoint}
								get_prospects_endpoint={get_prospects_endpoint}
								isProspectProfileCard={isProspectProfileCard}
								handlePlayerSelect={handlePlayerSelect}
								isVisibleByDefault={true}
								isCompareModal={true}
								isDarkMode={true}
							/>
						)}
						<div className="mt-4 flex flex-col">
							<h3 className="text-lg font-bold mb-2">
								Selected Players to Compare
							</h3>
							<div>
								{selectedPlayers.map((player, index) => (
									<div
										key={player.id + '-player'}
										className="flex flex-col lg:flex-row justify-between items-center border-gray-200 border py-4 rounded-xl my-6 shadow-sm bg-white"
									>
										<div className="flex items-center w-full ml-10 flex-grow">
											<div className="flex items-center">
												<div className="relative mr-4">
													<img
														src={`${player.image_url}?w=75&h=75`}
														alt={`${player.first_name} ${player.last_name}`}
														style={{
															width: '75px',
															height: '75px',
															objectFit: 'cover',
															border: '1px solid #ddd',
															borderRadius: '50%',
															backgroundColor: 'white'
														}}
													/>
													<div className="absolute top-2 left-12 transform translate-x-1/2 -translate-y-1/2">
														<StatusWidget
															status={player.status}
															description={player.injury_description}
															hideLabel={true}
															is75pxThumbnail={true}
														/>
													</div>
												</div>
												<div>
													<div className="text-2xl text-gray-700">
														{player.first_name} {player.last_name}
													</div>
												</div>
											</div>
										</div>
										<div className="lg:mr-10">
											{index !== 0 && (
												<button
													className="text-sm text-gray-500 hover:text-gray-800"
													onClick={() =>
														handlePlayerRemove(player.id, player.position)}
												>
													Remove
												</button>
											)}
										</div>
									</div>
								))}
							</div>
							<div className="mt-2 self-end">
								<button
									className={`px-4 py-2 rounded ${selectedPlayers.length < 2 ||
									selectedPlayers.length > 5
										? 'bg-gray-300 text-gray-500 cursor-not-allowed'
										: 'bg-blue-500 hover:bg-blue-600 text-white'}`}
									onClick={handleCompareClick}
									disabled={
										selectedPlayers.length < 2 || selectedPlayers.length > 5
									}
								>
									Compare
								</button>
							</div>
						</div>
					</div>
				) : (
					comparisonTable
				)}
			</div>
		</div>
	);
};

export default PlayerComparisonModal;
