import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ArticleCard from '../../../Article/ArticleCard/ArticleCard';
import BackToTopButton from '../../../Buttons/BackToTopButton/BackToTopButton';
import LoadingSpinner from '../../../LoadingSpinner/LoadingSpinner';

export default function NewsCenterIndexCard({ get_articles_endpoint }) {
	const [
		articles,
		setArticles
	] = useState([]);
	const [
		categories,
		setCategories
	] = useState([]);
	const [
		loading,
		setLoading
	] = useState(true);
	const [
		error,
		setError
	] = useState(null);
	const [
		showBackToTop,
		setShowBackToTop
	] = useState(false);

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	// Handle scroll event to show "Back to Top" button
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 420) {
				setShowBackToTop(true);
			} else {
				setShowBackToTop(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		// Cleanup event listener on unmount
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	useEffect(
		() => {
			window.scrollTo(0, 0); // Scroll to the top of the window when the component mounts

			let queryParams = '';

			if (categories.length)
				queryParams += `&categories=${categories.join(',')}`;

			const fetchData = async () => {
				try {
					const url = `${get_articles_endpoint}?article_type=News${queryParams}`;

					const response = await fetch(url);
					if (!response.ok) {
						throw new Error('Failed to fetch articles');
					}

					const result = await response.json();
					setArticles(result.data || []);
				} catch (error) {
					setError(
						error.message || 'An error occurred while fetching articles.'
					);
				} finally {
					setLoading(false);
				}
			};

			fetchData();
		},
		[
			get_articles_endpoint,
			categories
		]
	);

	return (
		<div>
			<h1 className="text-5xl mb-4 text-center">News Center</h1>
			{loading && <LoadingSpinner />}
			{error && <div>Error: {error}</div>}

			{/* Filter Section */}
			<div className="mt-14">
				<div className="mb-6">
					<div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:items-center md:justify-start md:space-x-10">
						{/* Filter by Topic */}
						<div className="flex flex-wrap items-center space-x-1 md:space-x-1">
							<span className="font-semibold text-gray-700 whitespace-nowrap hidden md:block mr-2">
								Filter by Topic
							</span>
							<div className="flex flex-wrap space-x-1.5 md:space-x-2 space-y-0">
								{[
									'News',
									'Rankings',
									'Dynasty',
									'Best Ball',
									'DFS'
								].map((category) => (
									<button
										key={category}
										onClick={() => {
											setCategories((prevCategories) => {
												if (prevCategories.includes(category)) {
													// Remove the category if it is already selected
													return prevCategories.filter((t) => t !== category);
												} else {
													// Add the category if it is not already selected
													return [
														...prevCategories,
														category
													];
												}
											});
										}}
										className={`text-xs md:text-base bg-white px-2 md:px-3 py-1 rounded-full border ${categories.includes(
											category
										)
											? 'bg-blue-500 text-white'
											: 'border-gray-400 text-gray-700'}`}
									>
										{category}
									</button>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Articles Section */}
			{!loading &&
			!error &&
			articles.length === 0 && (
				<div className="text-center text-gray-500 mt-8">
					<p>No articles found.</p>
				</div>
			)}

			{!loading &&
			!error &&
			articles.length > 0 && (
				<div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
					{articles.map((article) => (
						<ArticleCard key={article.id} article={article} />
					))}
				</div>
			)}

			{/* Disclaimer Section */}
			{loading || error ? null : (
				<div className="mt-14 text-sm text-gray-500 px-6">
					<p className="font-medium">Disclaimer: Content Aggregation</p>
					<p className="mt-2">
						The articles featured in our "News Center" are curated and
						aggregated from publicly available sources such as Yahoo Sports,
						ESPN, CBS Sports, and other publishers. We do not host or control
						the content of these articles, and we are not affiliated with or
						endorsed by the article creators or platforms. Links direct users to
						the original sources where the full articles can be accessed.
					</p>
					<p className="mt-2">
						We strive to provide accurate and up-to-date content, but we cannot
						be responsible for any discrepancies or changes made by the original
						publishers. For any concerns regarding content, please contact us
						directly.
					</p>
				</div>
			)}

			{/* Back to Top Widget */}
			{showBackToTop && <BackToTopButton />}
		</div>
	);
}

NewsCenterIndexCard.propTypes = {
	get_articles_endpoint: PropTypes.string.isRequired
};
