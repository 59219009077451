import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import TopHeadlinesCard from '../News/TopHeadlinesCard/TopHeadlinesCard';
import DashboardSpotlightCarousel from './DashboardSpotlightCarousel/DashboardSpotlightCarousel';
import RostersListCard from '../Rosters/RostersListCard/RostersListCard';
import CoursesListCard from '../Courses/CoursesListCard/CoursesListCard';
import SocialPostsEmbed from '../SocialPostsEmbed/SocialPostsEmbed';
import DashboardVideoCarousel from '../Dashboard/DashboardVideoCarousel/DashboardVideoCarousel';
import PricingTable from '../PricingTable/PricingTable';

const Dashboard = ({
	get_articles_endpoint,
	get_player_endpoint,
	get_team_endpoint,
	get_prospect_endpoint,
	get_courses_endpoint,
	get_rosters_by_user_endpoint,
	get_social_posts_endpoint,
	create_checkout_session_endpoint,
	stripe_public_key,
	stripe_product_essential_id,
	stripe_product_advanced_id,
	stripe_product_ultimate_id
}) => {
	const { user } = useAuth();
	const modalRef = useRef(null);
	const [
		planName,
		setPlanName
	] = useState('');
	const [
		isPricingTableModalOpen,
		setIsPricingTableModalOpen
	] = useState(false);
	// State variable for user profile creation success modal
	const [
		isUserProfileSuccessModalOpen,
		setIsUserProfileSuccessModalOpen
	] = useState(false);
	// State variable for subscription success modal
	const [
		isSubscriptionSuccessModalOpen,
		setIsSubscriptionSuccessModalOpen
	] = useState(false);

	// User profile success modal if present
	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		if (params.get('user_profile_success')) {
			setIsUserProfileSuccessModalOpen(true);
		}
	}, []);

	// UseEffect to check query params and open the subscription success modal if present
	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		if (params.get('subscription_success')) {
			setIsSubscriptionSuccessModalOpen(true);
			setPlanName(params.get('plan_name'));
		}
	}, []);

	const closePricingTableModal = () => {
		setIsPricingTableModalOpen(false);
	};

	const closeUserProfileSuccessModal = () => {
		setIsUserProfileSuccessModalOpen(false);

		const url = new URL(window.location);
		const params = new URLSearchParams(url.search);

		if (
			params.has('user_profile_success') &&
			params.get('user_profile_success') === 'true'
		) {
			params.delete('user_profile_success');

			const newUrl = params.toString()
				? `${url.pathname}?${params.toString()}`
				: url.pathname;
			window.history.replaceState({}, '', newUrl);
		}
	};

	const closeSubscriptionSuccessModal = () => {
		setIsSubscriptionSuccessModalOpen(false);

		const url = new URL(window.location);
		const params = new URLSearchParams(url.search);

		if (params.has('subscription_success') && params.has('plan_name')) {
			// Remove the parameters
			params.delete('subscription_success');
			params.delete('plan_name');

			// Construct the new URL
			const newUrl = '/dashboard';
			window.history.replaceState({}, '', newUrl);
		}
	};

	const closePricingTableModalOnClickOutside = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			setIsPricingTableModalOpen(false);
		}
	};

	const closeUserProfileSuccessModalOnClickOutside = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			setIsUserProfileSuccessModalOpen(false);

			const url = new URL(window.location);
			const params = new URLSearchParams(url.search);

			if (
				params.has('user_profile_success') &&
				params.get('user_profile_success') === 'true'
			) {
				params.delete('user_profile_success');

				const newUrl = params.toString()
					? `${url.pathname}?${params.toString()}`
					: url.pathname;
				window.history.replaceState({}, '', newUrl);
			}
		}
	};

	const closeSubscriptionSuccessModalOnClickOutside = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			setIsSubscriptionSuccessModalOpen(false);

			const url = new URL(window.location);
			const params = new URLSearchParams(url.search);

			if (params.has('subscription_success') && params.has('plan_name')) {
				// Remove the parameters
				params.delete('subscription_success');
				params.delete('plan_name');

				// Construct the new URL
				const newUrl = '/dashboard';
				window.history.replaceState({}, '', newUrl);
			}
		}
	};

	useEffect(
		() => {
			if (isPricingTableModalOpen) {
				document.addEventListener(
					'mousedown',
					closePricingTableModalOnClickOutside
				);
			} else {
				document.removeEventListener(
					'mousedown',
					closePricingTableModalOnClickOutside
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					closePricingTableModalOnClickOutside
				);
			};
		},
		[
			isPricingTableModalOpen
		]
	);

	useEffect(
		() => {
			if (isUserProfileSuccessModalOpen) {
				document.addEventListener(
					'mousedown',
					closeUserProfileSuccessModalOnClickOutside
				);
			} else {
				document.removeEventListener(
					'mousedown',
					closeUserProfileSuccessModalOnClickOutside
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					closeUserProfileSuccessModalOnClickOutside
				);
			};
		},
		[
			isUserProfileSuccessModalOpen
		]
	);

	useEffect(
		() => {
			if (isSubscriptionSuccessModalOpen) {
				document.addEventListener(
					'mousedown',
					closeSubscriptionSuccessModalOnClickOutside
				);
			} else {
				document.removeEventListener(
					'mousedown',
					closeSubscriptionSuccessModalOnClickOutside
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					closeSubscriptionSuccessModalOnClickOutside
				);
			};
		},
		[
			isSubscriptionSuccessModalOpen
		]
	);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const renderPlanFeatures = () => {
		switch (planName) {
			case 'essential':
				return (
					<Fragment>
						<div className="space-y-4 mb-3">
							You have successfully subscribed to the{' '}
							<p className="inline font-semibold">
								Essential Fantasy Fitness
							</p>{' '}
							plan.
							<p className="text-gray-800">
								Please enjoy the following features with your subscription:
							</p>
						</div>
						<ul className="list-disc ml-5 text-gray-700 space-y-2">
							<li className="ml-4">
								Roster Sync™ - connect your Yahoo, Sleeper, or FFPC rosters with
								Fantasy Fitness to gain valuable insights about your team
							</li>
							<li className="ml-4">
								Add up to 5 rosters - featuring advanced roster metrics, and the
								ability to add roster notes, player-specific notes, and more
							</li>
							<li className="ml-4">
								Collaborate with co-managers - seamlessly share and manage
								roster access
							</li>
							<li className="ml-4">
								College Prospect Profiles - complete with Prospect Score™,
								qualitative analysis, stats, videos and more
							</li>
							<li className="ml-4">
								Side-by-Side Compare Tool™ - compare up to five college
								prospects, NFL players or teams side-by-side
							</li>
							<li className="ml-4">
								Playoff Opponent Lists™ - strategize team stacks with each
								player's Week 15, 16, 17 matchups
							</li>
							<li className="ml-4">
								Exclusive access to our premium Discord channels
							</li>
							<li className="ml-4">Ad-free experience</li>
						</ul>
					</Fragment>
				);
			case 'advanced':
				return (
					<Fragment>
						<div className="space-y-4 mb-3">
							You have successfully subscribed to the{' '}
							<p className="inline font-semibold">
								Advanced Fantasy Fitness
							</p>{' '}
							plan.
							<p className="text-gray-800">
								Please enjoy the following features with your subscription:
							</p>
						</div>
						<ul className="list-disc ml-5 text-gray-700 space-y-2">
							<li className="ml-4">
								Roster Sync™ - connect your Yahoo, Sleeper, or FFPC rosters with
								Fantasy Fitness to gain valuable insights about your team
							</li>
							<li className="ml-4">
								Add up to 20 rosters - featuring advanced roster metrics, and
								the ability to add roster notes, player-specific notes, and more
							</li>
							<li className="ml-4">
								Collaborate with co-managers - seamlessly share and manage
								roster access
							</li>
							<li className="ml-4">
								College Prospect Profiles - complete with Prospect Score™,
								qualitative analysis, stats, videos and more
							</li>
							<li className="ml-4">
								Side-by-Side Compare Tool™ - compare up to five college
								prospects, NFL players or teams side-by-side
							</li>
							<li className="ml-4">
								Playoff Opponent Lists™ - strategize team stacks with each
								player's Week 15, 16, 17 matchups
							</li>
							<li className="ml-4">
								Draft Tracker with Roster Builder™ - your cutting-edge draft
								room companion
							</li>
							<li className="ml-4">
								Players Exposures Overview™ - instantly track your players
								exposures across platforms, formats, and scoring types
							</li>
							<li className="ml-4">
								Exclusive access to our premium Discord channels
							</li>
							<li className="ml-4">Ad-free experience</li>
						</ul>
					</Fragment>
				);
			case 'ultimate':
				return (
					<Fragment>
						<div className="space-y-4 mb-3">
							You have successfully subscribed to the{' '}
							<p className="inline font-semibold">
								Ultimate Fantasy Fitness
							</p>{' '}
							plan.
							<p className="text-gray-800">
								Please enjoy the following features with your subscription:
							</p>
						</div>
						<ul className="list-disc ml-5 text-gray-700 space-y-2">
							<li className="ml-4">
								Roster Sync™ - connect your Yahoo, Sleeper, or FFPC rosters with
								Fantasy Fitness to gain valuable insights about your team
							</li>
							<li className="ml-4">
								Add up to 100 rosters - featuring advanced roster metrics, and
								the ability to add roster notes, player-specific notes, and more
							</li>
							<li className="ml-4">
								Collaborate with co-managers - seamlessly share and manage
								roster access
							</li>
							<li className="ml-4">
								College Prospect Profiles - complete with Prospect Score™,
								qualitative analysis, stats, videos and more
							</li>
							<li className="ml-4">
								Side-by-Side Compare Tool™ - compare up to five college
								prospects, NFL players or teams side-by-side
							</li>
							<li className="ml-4">
								Playoff Opponent Lists™ - strategize team stacks with each
								player's Week 15, 16, 17 matchups
							</li>
							<li className="ml-4">
								Draft Tracker with Roster Builder™ - your cutting-edge draft
								room companion
							</li>
							<li className="ml-4">
								Players Exposures Overview™ - instantly track your players
								exposures across platforms, formats, and scoring types
							</li>
							<li className="ml-4">
								Exclusive access to our premium Discord channels
							</li>
							<li className="ml-4">Ad-free experience</li>
						</ul>
					</Fragment>
				);
			default:
				return null;
		}
	};

	return (
		<div>
			{user &&
			user.first_name && (
				<header className="pb-3 lg:pb-6">
					<h1 className="text-2xl lg:text-4xl font-bold text-gray-800">
						Welcome back, {user.first_name}
					</h1>
				</header>
			)}

			<main>
				<div className="grid grid-cols-12 gap-6">
					{/* Top Headlines */}
					<div className="col-span-12 xl:col-span-3">
						<TopHeadlinesCard get_articles_endpoint={get_articles_endpoint} />
					</div>

					{/* Spotlight Section */}
					<div className="col-span-12 xl:col-span-9">
						<DashboardSpotlightCarousel
							get_player_endpoint={get_player_endpoint}
							get_team_endpoint={get_team_endpoint}
							get_prospect_endpoint={get_prospect_endpoint}
						/>
					</div>
				</div>

				{/* Cards Section */}
				<div className="col-span-12 grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
					{/* Rosters */}
					<RostersListCard
						user={user}
						get_rosters_by_user_endpoint={get_rosters_by_user_endpoint}
					/>

					{/* Courses */}
					<CoursesListCard get_courses_endpoint={get_courses_endpoint} />
				</div>

				{/* Cards Section Pt. 2 */}
				<div className="grid grid-cols-12 gap-6 mt-6">
					{/* Twitter Feed */}
					<div className="col-span-12 xl:col-span-4">
						<SocialPostsEmbed
							get_social_posts_endpoint={get_social_posts_endpoint}
						/>
					</div>

					{/* Featured Videos */}
					<div className="col-span-12 xl:col-span-8">
						<DashboardVideoCarousel get_player_endpoint={get_player_endpoint} />
					</div>
				</div>
			</main>

			{/* Pricing Table */}
			{isPricingTableModalOpen && (
				<div
					className="fixed inset-0 flex justify-center items-center"
					style={{
						zIndex: 1000,
						backgroundColor: 'rgba(0, 0, 0, 0.5)'
					}}
				>
					<div
						ref={modalRef}
						className="relative bg-white rounded-lg p-6 shadow-lg"
						style={{ width: 'auto', maxHeight: 'auto', overflowY: 'auto' }}
					>
						<button
							className="absolute top-0 right-0 p-4"
							onClick={closePricingTableModal}
						>
							<svg
								className="h-6 w-6 text-gray-500"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
						<h2 className="text-2xl font-bold mb-4">Our Pricing</h2>
						<div className="overflow-x-auto">
							<div className="flex flex-col">
								<PricingTable
									create_checkout_session_endpoint={
										create_checkout_session_endpoint
									}
									stripe_public_key={stripe_public_key}
									stripe_product_essential_id={stripe_product_essential_id}
									stripe_product_advanced_id={stripe_product_advanced_id}
									stripe_product_ultimate_id={stripe_product_ultimate_id}
								/>
							</div>
						</div>
					</div>
				</div>
			)}

			{/* User Profile Success Modal */}
			{isUserProfileSuccessModalOpen && (
				<div
					className="fixed inset-0 flex justify-center items-center"
					style={{
						zIndex: 1000,
						backgroundColor: 'rgba(0, 0, 0, 0.5)'
					}}
				>
					<div
						ref={modalRef}
						className="relative bg-white rounded-lg p-6 shadow-2xl mx-4 md:mx-auto"
						style={{
							width: 'auto',
							maxHeight: '95%',
							overflowY: 'auto',
							maxWidth: '1200px'
						}}
					>
						<button
							className="absolute top-4 right-4 p-2 text-gray-600 hover:text-gray-900 transition-colors"
							onClick={closeUserProfileSuccessModal}
						>
							<svg
								className="h-6 w-6"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
						<h2 className="text-xl font-extrabold mb-5 text-gray-800">
							Welcome to Fantasy Fitness!
						</h2>
						<div className="space-y-4 mb-5" style={{ maxWidth: '975px' }}>
							<p className="text-md text-gray-600">
								Enjoy our free features and when you’re ready to take your
								fantasy fitness to the next level, select a plan that's right
								for you.
							</p>
						</div>
						<div>
							<PricingTable
								create_checkout_session_endpoint={
									create_checkout_session_endpoint
								}
								stripe_public_key={stripe_public_key}
								stripe_product_essential_id={stripe_product_essential_id}
								stripe_product_advanced_id={stripe_product_advanced_id}
								stripe_product_ultimate_id={stripe_product_ultimate_id}
							/>
						</div>
						<div className="mt-6 space-y-4">
							<p className="text-gray-800">
								If you have any questions or need assistance, please email{' '}
								<a
									href="mailto:matt@fantasyfitness.io?subject=Inquiry"
									className="text-blue-600 underline hover:text-blue-800"
								>
									matt@fantasyfitness.io
								</a>
							</p>
							<p className="text-gray-800">
								We hope you enjoy your fantasy fitness journey!
							</p>
							<p className="text-gray-800">
								Sincerely, <br />Matt
							</p>
						</div>
					</div>
				</div>
			)}

			{/* Subscription Success Modal */}
			{isSubscriptionSuccessModalOpen && (
				<div
					className="fixed inset-0 flex justify-center items-center"
					style={{
						zIndex: 1000,
						backgroundColor: 'rgba(0, 0, 0, 0.6)'
					}}
				>
					<div
						ref={modalRef}
						className="relative bg-white rounded-lg p-8 shadow-2xl max-w-3xl w-full mx-4 md:mx-auto"
						style={{ maxHeight: '90vh', overflowY: 'auto' }}
					>
						<button
							className="absolute top-4 right-4 p-2 text-gray-600 hover:text-gray-900 transition-colors"
							onClick={closeSubscriptionSuccessModal}
						>
							<svg
								className="h-6 w-6"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
						<div className="flex items-center mb-6">
							<i className="fa-regular fa-circle-check text-green-500 text-2xl mr-2" />
							<h2 className="text-xl text-green-600 ">Success!</h2>
						</div>
						<div className="space-y-4 mb-4">
							<p className="text-gray-800">
								Welcome to Fantasy Fitness! We are excited to have you aboard.
							</p>
							<div className="text-gray-800">{renderPlanFeatures()}</div>
							<p className="text-gray-800">
								If you have any questions or need assistance, please email{' '}
								<a
									href="mailto:matt@fantasyfitness.io?subject=Subscription%20Assistance"
									className="text-blue-600 underline hover:text-blue-800"
								>
									matt@fantasyfitness.io
								</a>
							</p>
							<p className="text-gray-800">
								We hope you enjoy your fantasy fitness journey!
							</p>
							<p className="text-gray-800">
								Sincerely, <br />Matt
							</p>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default Dashboard;
