import React from 'react';
import DateDataType from '../DataTypes/DateDataTypes';
import TranslationDataType from '../DataTypes/TranslationDataTypes';

export default class Cell extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		let value;

		if (this.props.type === 'translation') {
			value = <TranslationDataType value={this.props.value} />;
		} else if (this.props.type === 'date') {
			value = <DateDataType value={this.props.value} />;
		} else {
			value = this.props.value;
		}

		return (
			<div>
				<span>
					{value} {this.props.icon}
				</span>
			</div>
		);
	}
}
