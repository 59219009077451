import React from 'react';
import ReactApexChart from 'react-apexcharts';

const OffensiveEfficiencyChart = ({ teamData, leagueData, teamName }) => {
	// Extract the last word from the team name
	const teamNameLastWord = teamName.split(' ').pop();

	// Ensure data exists and provide fallbacks, converting values to numbers
	const teamAvgYardsPerPlay =
		teamData && teamData.avg_yards_per_play
			? parseFloat(teamData.avg_yards_per_play)
			: 0;
	const leagueAvgYardsPerPlay =
		leagueData && leagueData.avg_yards_per_play
			? parseFloat(leagueData.avg_yards_per_play)
			: 0;

	const teamAvgYardsPerPass =
		teamData && teamData.avg_yards_per_pass
			? parseFloat(teamData.avg_yards_per_pass)
			: 0;
	const leagueAvgYardsPerPass =
		leagueData && leagueData.avg_yards_per_pass
			? parseFloat(leagueData.avg_yards_per_pass)
			: 0;

	const teamAvgYardsPerRush =
		teamData && teamData.avg_yards_per_rush
			? parseFloat(teamData.avg_yards_per_rush)
			: 0;
	const leagueAvgYardsPerRush =
		leagueData && leagueData.avg_yards_per_rush
			? parseFloat(leagueData.avg_yards_per_rush)
			: 0;

	// Chart configuration
	const chartOptions = {
		series: [
			{
				name: `${teamNameLastWord}`,
				data: [
					teamAvgYardsPerPlay,
					teamAvgYardsPerPass,
					teamAvgYardsPerRush
				]
			},
			{
				name: 'League Avg',
				data: [
					leagueAvgYardsPerPlay,
					leagueAvgYardsPerPass,
					leagueAvgYardsPerRush
				]
			}
		],
		options: {
			chart: {
				type: 'bar',
				height: 350
			},
			plotOptions: {
				bar: {
					columnWidth: '55%',
					distributed: false // Set to false to group bars by category
				}
			},
			colors: [
				'#2980B9',
				'#E67E22'
			],
			stroke: {
				width: 1,
				colors: [
					'#fff'
				]
			},
			title: {
				text: 'Efficiency',
				align: 'center',
				style: { fontSize: '14px' }
			},
			xaxis: {
				categories: [
					'Avg Yards per Play',
					'Avg Yards per Pass',
					'Avg Yards per Rush'
				]
			},
			yaxis: {
				title: {
					text: 'Yards'
				}
			},
			tooltip: {
				y: {
					formatter: (val) => `${Math.round(val)} Yards`
				}
			},
			legend: {
				position: 'top',
				horizontalAlign: 'center'
			}
		}
	};

	return (
		<div className="bg-white p-4 rounded-lg shadow-lg border border-gray-300">
			<ReactApexChart
				options={chartOptions.options}
				series={chartOptions.series}
				type="bar"
				height={250}
			/>
		</div>
	);
};

export default OffensiveEfficiencyChart;
