import React from 'react';
import { Link } from 'react-router-dom';
import MetricsIcon from '../../../../assets/images/courses/metrics.png';

const CourseCard = ({ course, getDifficultyLevel }) => {
	return (
		<Link
			to={`/courses/${course.id}`}
			className="p-6 bg-white shadow rounded-xl border border-gray-300 hover:shadow-lg transition-shadow duration-300 ease-in-out transform hover:scale-101 block"
		>
			{/* Title and Icon in one row */}
			<div className="flex items-center mb-4">
				<img
					src={MetricsIcon}
					alt="Course Icon"
					className="w-20 h-20 hover:shadow-lg transition-transform transform hover:scale-105 backdrop-blur-md"
				/>
				<h2 className="ml-4 text-xl font-bold text-gray-800">{course.title}</h2>
			</div>

			<p className="text-gray-600 mb-4">{course.description}</p>

			<hr className="border-gray-300 -mx-6 my-4" />
			<div className="space-y-4">
				{course.level !== undefined && (
					<p className="flex items-center text-gray-700">
						<i className="fa-solid fa-stairs mr-2" />
						<span className="pr-2">Difficulty level:</span>
						<span>{getDifficultyLevel(course.level)}</span>
					</p>
				)}
				{course.total_duration && (
					<p className="flex items-center text-gray-700">
						<i className="fa-solid fa-clock mr-2" />
						<span>Total duration: {course.total_duration} minutes</span>
					</p>
				)}
				{course.updated_at && (
					<p className="flex items-center text-gray-700">
						<i className="fa-solid fa-circle-exclamation mr-2" />
						<span>
							Last updated:{' '}
							{new Date(course.updated_at).toLocaleDateString('en-US', {
								year: 'numeric',
								month: 'long',
								day: 'numeric'
							})}
						</span>
					</p>
				)}
			</div>
		</Link>
	);
};

export default CourseCard;
