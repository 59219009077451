import React, { useState, useEffect } from 'react';
import BackToTopButton from '../../Buttons/BackToTopButton/BackToTopButton';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

export default function GlossaryTermsIndexCard({
	get_glossary_terms_endpoint
}) {
	const [
		glossaryTerms,
		setGlossaryTerms
	] = useState([]);
	const [
		loading,
		setLoading
	] = useState(true);
	const [
		error,
		setError
	] = useState(null);
	const [
		showBackToTop,
		setShowBackToTop
	] = useState(false);

	const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

	useEffect(
		() => {
			window.scrollTo(0, 0);

			const fetchData = async () => {
				try {
					const url = `${get_glossary_terms_endpoint}`;

					const response = await fetch(url);
					if (!response.ok) {
						throw new Error('Failed to fetch articles');
					}

					const result = await response.json();
					setGlossaryTerms(result.data);
				} catch (error) {
					setError(
						error.message || 'An error occurred while fetching articles.'
					);
				} finally {
					setLoading(false);
				}
			};

			fetchData();
		},
		[
			get_glossary_terms_endpoint
		]
	);

	useEffect(() => {
		const handleScroll = () => {
			const aSection = document.getElementById('A');
			if (aSection) {
				const aSectionTop = aSection.getBoundingClientRect().top;
				setShowBackToTop(aSectionTop < 0);
			}
		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	const scrollToSection = (letter) => {
		const section = document.getElementById(letter);
		if (section) {
			// Calculate the target scroll position of the section
			const offset = -14; // Adjust this value as needed
			const targetPosition =
				section.getBoundingClientRect().top + window.scrollY + offset;

			window.scrollTo({ top: targetPosition, behavior: 'smooth' });
		}
	};

	if (loading) {
		return <LoadingSpinner />;
	}

	if (error) {
		return <div className="text-center">{error}</div>;
	}

	// Group terms by their starting letter
	const groupedTerms = glossaryTerms.reduce((acc, term) => {
		const firstLetter = term.term[0].toUpperCase();
		if (!acc[firstLetter]) acc[firstLetter] = [];
		acc[firstLetter].push(term);
		return acc;
	}, {});

	return (
		<div>
			<h1 className="text-5xl mb-4 text-center">Glossary</h1>

			{/* Alphabet Navigation */}
			<div className="flex flex-wrap justify-center gap-2 mt-16 mb-12">
				{alphabet.map((letter) => (
					<button
						key={letter}
						onClick={() => scrollToSection(letter)}
						className="text-xl font-bold px-2 py-1 hover:underline"
					>
						{letter}
					</button>
				))}
			</div>

			{/* Glossary Terms by Letter */}
			<div>
				{alphabet.map((letter) => (
					<div key={letter} id={letter} className="my-6">
						<h2 className="text-2xl font-bold mb-3">{letter}</h2>
						<div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
							{(groupedTerms[letter] || []).map((term) => (
								<div
									key={term.id}
									className="bg-white shadow-md rounded-md p-4"
								>
									<h3 className="text-lg font-semibold">{term.term}</h3>
									<p className="py-3">{term.definition}</p>
									<p className="text-sm text-gray-500">{term.key_insight}</p>
								</div>
							))}
						</div>
					</div>
				))}
			</div>

			{/* Back to Top Widget */}
			{showBackToTop && <BackToTopButton />}
		</div>
	);
}
