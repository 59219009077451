import React, { useState, useEffect, useRef } from 'react';

const RosterDraftNotesCard = ({
	user,
	roster,
	get_draft_notes_by_roster_endpoint,
	update_draft_notes_by_roster_endpoint,
	isDraftModeEnabled
}) => {
	const [
		draftNotes,
		setDraftNotes
	] = useState('');
	const [
		showSuccessMessage,
		setShowSuccessMessage
	] = useState(false);
	const [
		isExpanded,
		setIsExpanded
	] = useState(false);
	const debounceTimeout = useRef(null);

	const getDraftNotes = async () => {
		const endpoint = get_draft_notes_by_roster_endpoint
			.replace('user_id', user.id)
			.replace('roster_id', roster.id);

		try {
			const response = await fetch(endpoint);
			if (!response.ok) {
				throw new Error('Failed to fetch notes');
			}

			const data = await response.json();
			setDraftNotes(data.data || '');
		} catch (error) {
			console.error('Error fetching notes:', error);
		}
	};

	const csrfToken = document.querySelector('[name="csrf-token"]').content;

	const saveDraftNotes = async (newDraftNotes) => {
		const endpoint = update_draft_notes_by_roster_endpoint
			.replace('user_id', user.id)
			.replace('roster_id', roster.id);

		try {
			const response = await fetch(endpoint, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					'X-CSRF-Token': csrfToken
				},
				body: JSON.stringify({ draft_notes: newDraftNotes })
			});

			if (!response.ok) {
				throw new Error('Failed to update draft notes');
			}

			setShowSuccessMessage(true);
			await getDraftNotes();

			setTimeout(() => {
				setShowSuccessMessage(false);
			}, 1500);
		} catch (error) {
			console.error('Failed to update draft notes:', error);
		}
	};

	const handleNotesChange = (e) => {
		const newDraftNotes = e.target.value;
		setDraftNotes(newDraftNotes);

		if (debounceTimeout.current) {
			clearTimeout(debounceTimeout.current);
		}

		debounceTimeout.current = setTimeout(() => {
			saveDraftNotes(newDraftNotes);
		}, 1500); // Save after 1.5 second of inactivity
	};

	useEffect(() => {
		getDraftNotes();

		return () => {
			if (debounceTimeout.current) {
				clearTimeout(debounceTimeout.current);
			}
		};
	}, []);

	return (
		<div>
			<div
				className={`relative flex-1 bg-gray-100 rounded-lg p-4 shadow-md flex flex-col w-full lg:w-auto ${isExpanded
					? 'h-96'
					: isDraftModeEnabled ? 'h-24 md:h-48' : 'h-48 md:h-60'}`}
			>
				<h3 className="font-semibold text-lg mb-2 text-gray-700 uppercase border-b border-gray-300 pb-2">
					DRAFT NOTES
				</h3>
				<textarea
					value={draftNotes}
					onChange={handleNotesChange}
					className="text-gray-700 flex-1 text-sm p-2 rounded-lg w-full h-full bg-gray-100 overflow-y-auto"
					placeholder="Click to add draft notes"
				/>
				<div className="text-right mt-2">
					<button
						onClick={() => setIsExpanded(!isExpanded)}
						className="text-blue-500 text-sm hover:underline focus:outline-none"
					>
						{isExpanded ? 'Collapse' : 'Expand'}
					</button>
				</div>
				{showSuccessMessage && (
					<div className="absolute inset-0 flex items-center justify-center bg-gray-100 rounded-lg transition-opacity duration-500">
						<div className="flex flex-col items-center">
							<i className="fa-regular fa-circle-check text-green-500 text-2xl" />
							<span className="text-green-500">Saved.</span>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default RosterDraftNotesCard;
