import React, { useState, useEffect } from 'react';

const BackToTopButton = () => {
	const [
		showBackToTop,
		setShowBackToTop
	] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			setShowBackToTop(window.scrollY > 300);
		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	return (
		showBackToTop && (
			<button
				onClick={scrollToTop}
				className="fixed bottom-4 right-4 lg:bottom-6 lg:right-6 xl:bottom-8 xl:right-8 bg-blue-500 text-white px-4 py-2 rounded-full shadow-lg hover:bg-blue-600 transition-colors"
			>
				<i className="fa fa-chevron-up mr-1.5 text-sm" aria-hidden="true" />
				Back to top
			</button>
		)
	);
};

export default BackToTopButton;
