import React, { useState, useEffect } from 'react';

const RosterPlayerNoteModal = ({
	userId,
	rosterId,
	player,
	isRosterPlayerNoteModalOpen,
	closeRosterPlayerNoteModal,
	setIsRosterPlayerNoteSuccessModalOpen,
	modalRef,
	get_roster_player_note_endpoint,
	create_roster_player_note_endpoint,
	update_roster_player_note_endpoint
}) => {
	const [
		noteObject,
		setNoteObject
	] = useState(null);
	const [
		noteContent,
		setNoteContent
	] = useState('');

	useEffect(
		() => {
			if (get_roster_player_note_endpoint) {
				const endpoint = get_roster_player_note_endpoint
					.replace('user_id', userId)
					.replace('roster_id', rosterId)
					.replace('player_id', player.id)
					.replace('player_type', player.position);
				fetch(endpoint).then((response) => response.json()).then((data) => {
					setNoteObject(data);
					setNoteContent(data.content);
				});
			}
		},
		[
			get_roster_player_note_endpoint,
			userId,
			player.id,
			player.position
		]
	);

	// Prevent background scrolling when the modal is open
	useEffect(
		() => {
			if (isRosterPlayerNoteModalOpen) {
				document.body.style.overflow = 'hidden';
			}

			return () => {
				// Cleanup on modal close or component unmount
				document.body.style.overflow = '';
			};
		},
		[
			isRosterPlayerNoteModalOpen
		]
	);

	const handleSaveNote = () => {
		let endpoint =
			noteObject && noteObject.id
				? update_roster_player_note_endpoint
				: create_roster_player_note_endpoint;
		endpoint = endpoint
			.replace('user_id', userId)
			.replace('roster_id', rosterId)
			.replace('player_id', player.id)
			.replace('player_type', player.position);

		// Wrapping the note content in the expected parameter structure
		const notePayload = {
			roster_player_note: {
				content: noteContent
			}
		};

		fetch(endpoint, {
			method: noteObject && noteObject.id ? 'PATCH' : 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(notePayload)
		})
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error('Failed to save note');
				}
			})
			.then((data) => {
				closeRosterPlayerNoteModal();
				setIsRosterPlayerNoteSuccessModalOpen(true);
			})
			.catch((error) => {
				console.error('Error saving note:', error);
			});
	};

	const handleNoteChange = (event) => {
		setNoteContent(event.target.value);
	};

	const handleClearNote = () => {
		setNoteContent('');
	};

	return (
		<div
			className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
			style={{ zIndex: 1000 }}
		>
			<div
				className="bg-white rounded-lg p-6 shadow-lg w-full lg:max-w-4xl"
				ref={modalRef}
			>
				<h2 className="text-2xl font-bold mb-4">
					Notes for {player.first_name} {player.last_name}
				</h2>
				<textarea
					value={noteContent}
					onChange={handleNoteChange}
					className="w-full h-40 p-2 border rounded-lg"
					placeholder="Enter note content..."
				/>
				<div className="mt-4 flex justify-between">
					{/* Left side: Clear Note */}
					<button
						className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition duration-300 ease-in-out"
						onClick={handleClearNote}
					>
						Clear note
					</button>

					{/* Right side: Cancel & Save */}
					<div className="flex space-x-4">
						<button
							className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600 transition duration-300 ease-in-out"
							onClick={closeRosterPlayerNoteModal}
						>
							Cancel
						</button>
						<button
							className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300 ease-in-out"
							onClick={handleSaveNote}
						>
							Save
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RosterPlayerNoteModal;
