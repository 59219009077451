import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Link } from 'react-router-dom';
import IssueForm from '../Issue/IssueForm';

export default function Footer({ create_issue_endpoint }) {
	const modalRef = useRef(null);
	const [
		name,
		setName
	] = useState('');
	const [
		email,
		setEmail
	] = useState('');
	const [
		description,
		setDescription
	] = useState('');
	const [
		errors,
		setErrors
	] = useState([]);
	const [
		isCreateIssueModalOpen,
		setIsCreateIssueModalOpen
	] = useState(false);
	const [
		isSubmitted,
		setIsSubmitted
	] = useState(false);

	const onChange = (e) => {
		const { name, value } = e.target;
		if (name === 'name') setName(value);
		else if (name === 'email') setEmail(value);
		else if (name === 'description') setDescription(value);
	};

	const onSubmit = (e) => {
		e.preventDefault();
		e.stopPropagation();

		fetch(create_issue_endpoint, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				name: name,
				email: email,
				description: description
			})
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					setIsSubmitted(true);
					setErrors([]);
					setName('');
					setEmail('');
					setDescription('');
				} else {
					setErrors(data.field_errors || []);
				}
			})
			.catch((error) => {
				// Handle network or unexpected error
				console.error('Create issue call unexpectedly failed.', error);
				setErrors([
					{
						message:
							'An unexpected error occurred reporting an issue. Please contact support.'
					}
				]);
			});
	};

	const handleCreateIssueModalOpen = () => {
		setIsCreateIssueModalOpen(true);
	};

	const handleCreateIssueModalClose = () => {
		setIsCreateIssueModalOpen(false);
		setIsSubmitted(false);
		setErrors([]);
		setName('');
		setEmail('');
		setDescription('');
	};

	const closeCreateIssueModalOnClickOutside = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			setIsCreateIssueModalOpen(false);
			setIsSubmitted(false);
			setErrors([]);
			setName('');
			setEmail('');
			setDescription('');
		}
	};

	useEffect(
		() => {
			if (isCreateIssueModalOpen) {
				document.addEventListener(
					'mousedown',
					closeCreateIssueModalOnClickOutside
				);
			} else {
				document.removeEventListener(
					'mousedown',
					closeCreateIssueModalOnClickOutside
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					closeCreateIssueModalOnClickOutside
				);
			};
		},
		[
			isCreateIssueModalOpen
		]
	);

	useEffect(
		() => {
			if (isCreateIssueModalOpen) {
				document.body.style.overflow = 'hidden';
			} else {
				document.body.style.overflow = 'auto';
			}
		},
		[
			isCreateIssueModalOpen
		]
	);

	return (
		<Fragment>
			<footer className="bg-gray-200 text-gray-800 mt-16">
				<hr className="border-gray-300" />
				<div className="container p-6 pb-2 md:pt-10 md:px-20">
					<div className="grid grid-cols-1 md:grid-cols-3 gap-10">
						<div className="mt-6 md:mt-0">
							<span className="font-semibold">Support</span>
							<ol className="mt-4">
								<Link to="/help-center">
									<li className="my-4 hover:underline cursor-pointer">
										Help Center
									</li>
								</Link>
								<li
									className="my-4 hover:underline cursor-pointer"
									onClick={handleCreateIssueModalOpen}
								>
									Report an issue
								</li>
								{/* <li className="my-4">Visit our blog</li> */}
							</ol>
						</div>
						<div className="mt-6 md:mt-0">
							<span className="font-semibold">Community</span>
							<ol className="mt-4">
								<li className="my-4">
									<a
										href="https://denverrescuemission.org/"
										className="hover:underline"
										target="_blank"
										rel="noopener noreferrer"
									>
										Combating homelessness
									</a>
								</li>
							</ol>
						</div>
						<div className="mt-6 md:mt-0">
							<span className="font-semibold">Fantasy Fitness™</span>
							<div className="mt-2">
								<a
									href="https://x.com/FantasyFitIO"
									target="_blank"
									rel="noopener noreferrer"
								>
									<i className="fab fa-x-twitter mr-5" />
								</a>
								<a
									href="https://www.youtube.com/@fantasyfitnessIO"
									target="_blank"
									rel="noopener noreferrer"
								>
									<i className="fab fa-youtube mr-5" />
								</a>
								<a
									href="https://www.instagram.com/fantasyfitnessio"
									target="_blank"
									rel="noopener noreferrer"
								>
									<i className="fab fa-instagram" />
								</a>
							</div>
						</div>
					</div>
					<div className="block mt-6 text-gray-800 text-start text-sm md:text-base">
						<hr className="my-4 border-gray-300" />
						<p className="py-2">
							Fantasy Fitness™ is a trademark of Fantasy Fitness, LLC. All
							rights reserved.
						</p>
						<p className="py-2">
							© {new Date().getFullYear()} Fantasy Fitness, LLC
						</p>
					</div>
				</div>

				{/* Report an issue modal */}
				{isCreateIssueModalOpen && (
					<div
						className="fixed inset-0 flex justify-center items-center"
						style={{
							zIndex: 1000,
							backgroundColor: 'rgba(0, 0, 0, 0.5)'
						}}
					>
						<div
							className="relative bg-white rounded-lg p-8 pt-6 shadow-xl w-full max-w-md"
							ref={modalRef}
						>
							<button
								className="absolute top-2 right-2 p-2 rounded-full hover:bg-gray-200 transition duration-300"
								onClick={handleCreateIssueModalClose}
							>
								<svg
									className="h-6 w-6 text-gray-600"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M6 18L18 6M6 6l12 12"
									/>
								</svg>
							</button>
							<IssueForm
								onChange={onChange}
								onSubmit={onSubmit}
								name={name}
								email={email}
								description={description}
								errors={errors}
								isSubmitted={isSubmitted}
								handleCreateIssueModalClose={handleCreateIssueModalClose}
							/>
						</div>
					</div>
				)}
			</footer>
		</Fragment>
	);
}
