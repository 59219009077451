import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import logo from '../../../assets/images/logo.jpeg';
import PlayerSearchBar from '../SearchBars/PlayerSearchBar/PlayerSearchBar';

const Header = (props) => {
	const { user, authenticated, signOut } = useAuth();

	const navigate = useNavigate();
	const location = useLocation();
	const [
		isDropdownOpen,
		setIsDropdownOpen
	] = useState(false);
	const dropdownRef = useRef(null);

	const handleSignOut = async () => {
		await signOut();
		setIsDropdownOpen(false);
	};

	const handleNavigation = (path) => {
		setIsDropdownOpen(false);
		navigate(path);
	};

	useEffect(
		() => {
			const handleClickOutside = (event) => {
				if (
					dropdownRef.current &&
					!dropdownRef.current.contains(event.target)
				) {
					setIsDropdownOpen(false);
				}
			};

			if (isDropdownOpen) {
				document.addEventListener('mousedown', handleClickOutside);
			} else {
				document.removeEventListener('mousedown', handleClickOutside);
			}

			return () => {
				document.removeEventListener('mousedown', handleClickOutside);
			};
		},
		[
			isDropdownOpen
		]
	);

	return (
		<nav className="relative flex items-center bg-ff-black shadow-xl">
			<div className="mx-auto">
				<Link to="/">
					<img src={logo} className="h-24" alt="Logo" />
				</Link>
			</div>
			<div className="absolute top-3 right-4">
				<PlayerSearchBar
					getPlayersEndpoint={props.get_players_endpoint}
					isOnHeader={true}
				/>
			</div>
			<div className="absolute top-8 left-7" style={{ zIndex: 100 }}>
				<Fragment>
					<i
						className="fa-solid fa-bars text-2xl text-white cursor-pointer"
						onClick={() => setIsDropdownOpen(!isDropdownOpen)}
					/>
					{isDropdownOpen && (
						<div
							ref={dropdownRef}
							className="absolute top-10 left-0 w-48 bg-white text-black rounded-lg shadow-lg z-10"
						>
							<ul className="list-none p-2 space-y-1">
								{authenticated && (
									<Fragment>
										{user &&
										user.is_front_office_mode_enabled && (
											<li>
												<button
													className="w-full text-left p-2 hover:bg-gray-100 rounded flex items-center text-md"
													onClick={() => handleNavigation('/front-office')}
												>
													<i className="fa-solid fa-building text-lg mr-3" />
													Front Office
												</button>
											</li>
										)}
										<li>
											<button
												className="w-full text-left p-2 hover:bg-gray-100 rounded flex items-center text-md"
												onClick={() => handleNavigation('/dashboard')}
											>
												<i className="fa-solid fa-tachometer text-lg mr-4" />
												Dashboard
											</button>
										</li>
										<li>
											<button
												className="w-full text-left p-2 hover:bg-gray-100 rounded flex items-center text-md"
												onClick={() => handleNavigation('/rosters')}
											>
												<i className="fa-solid fa-clipboard-list text-lg mr-4" />
												Rosters
											</button>
										</li>
										<li>
											<button
												className="w-full text-left p-2 hover:bg-gray-100 rounded flex items-center text-md"
												onClick={() => handleNavigation('/courses')}
											>
												<i className="fa-solid fa-chalkboard-teacher text-lg mr-4" />
												Courses
											</button>
										</li>
									</Fragment>
								)}
								<li>
									<button
										className="w-full text-left p-2 hover:bg-gray-100 rounded flex items-center text-md"
										onClick={() => handleNavigation('/news')}
									>
										<i className="fa-solid fa-newspaper text-lg mr-3" />News
										Center
									</button>
								</li>
								<li>
									<button
										className="w-full text-left p-2 hover:bg-gray-100 rounded flex items-center text-md"
										onClick={() => handleNavigation('/players')}
									>
										<i className="fa-solid fa-people-group text-lg mr-3" />NFL
										Players
									</button>
								</li>
								<li>
									<button
										className="w-full text-left p-2 hover:bg-gray-100 rounded flex items-center text-md"
										onClick={() => handleNavigation('/teams')}
									>
										<i className="fa-solid fa-football text-lg mr-3" />NFL Teams
									</button>
								</li>
								<li>
									<button
										className="w-full text-left p-2 hover:bg-gray-100 rounded flex items-center text-md"
										onClick={() => handleNavigation('/prospects')}
									>
										<i className="fa-solid fa-binoculars text-lg mr-3" />College
										Prospects
									</button>
								</li>
								<li>
									<button
										className="w-full text-left p-2 hover:bg-gray-100 rounded flex items-center text-md"
										onClick={() => handleNavigation('/glossary')}
									>
										<i className="fa-solid fa-book text-lg mr-3" />Glossary
									</button>
								</li>
								{authenticated ? (
									<Fragment>
										<li>
											<button
												className="w-full text-left p-2 hover:bg-gray-100 rounded flex items-center text-md"
												onClick={() => handleNavigation('/user-profile')}
											>
												<i className="fa-solid fa-circle-user text-lg mr-3" />
												User Profile
											</button>
										</li>
										<li>
											<button
												className="w-full text-left p-2 hover:bg-gray-100 rounded flex items-center text-md"
												onClick={handleSignOut}
											>
												<i className="fa-solid fa-arrow-right-from-bracket text-lg mr-3" />
												Sign Out
											</button>
										</li>
									</Fragment>
								) : (
									location.pathname !== '/signin' && (
										<li>
											<button
												className="w-full text-left p-2 hover:bg-gray-100 rounded flex items-center text-md"
												onClick={() => handleNavigation('/signin')}
											>
												<i className="fa-solid fa-arrow-right-to-bracket text-lg mr-3" />
												Sign In
											</button>
										</li>
									)
								)}
							</ul>
						</div>
					)}
				</Fragment>
			</div>
		</nav>
	);
};

export default Header;
