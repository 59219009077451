import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ScoringVsTurnoversChart = ({ teamData, leagueData, teamName }) => {
	// Extract the last word from the team name
	const teamNameLastWord = teamName.split(' ').pop();

	// Helper function to safely parse numerical data
	const parseValue = (data, key) =>
		data && data[key] ? parseFloat(data[key]) : 0;

	// Extracting values for both team and league
	const teamStats = {
		passingTDs: parseValue(teamData, 'avg_passing_touchdowns'),
		rushingTDs: parseValue(teamData, 'avg_rushing_touchdowns'),
		interceptions: parseValue(teamData, 'avg_interceptions_thrown'),
		fumblesLost: parseValue(teamData, 'avg_fumbles_lost'),
		sacksTaken: parseValue(teamData, 'avg_sacks_allowed')
	};

	const leagueStats = {
		passingTDs: parseValue(leagueData, 'avg_passing_touchdowns'),
		rushingTDs: parseValue(leagueData, 'avg_rushing_touchdowns'),
		interceptions: parseValue(leagueData, 'avg_interceptions_thrown'),
		fumblesLost: parseValue(leagueData, 'avg_fumbles_lost'),
		sacksTaken: parseValue(leagueData, 'avg_sacks_allowed')
	};

	const chartOptions = {
		series: [
			{
				name: `${teamNameLastWord}`,
				data: [
					teamStats.passingTDs,
					teamStats.rushingTDs,
					teamStats.interceptions,
					teamStats.fumblesLost,
					teamStats.sacksTaken
				]
			},
			{
				name: 'League Avg',
				data: [
					leagueStats.passingTDs,
					leagueStats.rushingTDs,
					leagueStats.interceptions,
					leagueStats.fumblesLost,
					leagueStats.sacksTaken
				]
			}
		],
		options: {
			chart: {
				type: 'bar',
				height: 350
			},
			plotOptions: {
				bar: {
					columnWidth: '85%',
					distributed: false // Set to false to group bars by category
				}
			},
			colors: [
				'#2980B9',
				'#E67E22'
			],
			stroke: {
				width: 1,
				colors: [
					'#fff'
				]
			},
			title: {
				text: 'Scoring vs Turnovers',
				align: 'center',
				style: { fontSize: '14px' }
			},
			xaxis: {
				categories: [
					'Pass TDs',
					'Rush TDs',
					'Int',
					'Fmbls Lost',
					'Sacks Tkn'
				],
				labels: {
					style: { fontSize: '12px' }
				}
			},
			yaxis: {
				title: { text: 'Count' },
				tickAmount: 2, // Adjusts the number of ticks (try different values based on your data range)
				labels: {
					formatter: (val) => val.toFixed(2) // Ensures values are shown with 2 decimal places (e.g., 1.25, 1.50, 1.75)
				}
			},
			tooltip: {
				y: { formatter: (val) => `${Math.round(val)}` }
			},
			legend: {
				position: 'top',
				horizontalAlign: 'center'
			}
		}
	};

	return (
		<div className="bg-white p-4 rounded-lg shadow-lg border border-gray-300">
			<ReactApexChart
				options={chartOptions.options}
				series={chartOptions.series}
				type="bar"
				height={250}
			/>
		</div>
	);
};

export default ScoringVsTurnoversChart;
