import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

const PlayerPositionCountCard = ({
	playerCounts,
	rookieCount,
	avgPlayerAge,
	avgPlayerExperience,
	players,
	isDraftModeEnabled
}) => {
	const [
		activeSlide,
		setActiveSlide
	] = useState(0);

	const positionAbbreviationMap = {
		quarterback: 'QB',
		'running back': 'RB',
		'wide receiver': 'WR',
		'tight end': 'TE'
	};

	// Group players by their abbreviated position
	const groupedPlayers = players.reduce((acc, player) => {
		const position = player.position; // e.g., "quarterback", "running back"
		const abbreviatedPosition = positionAbbreviationMap[position] || position;

		if (!acc[abbreviatedPosition]) {
			acc[abbreviatedPosition] = [];
		}
		acc[abbreviatedPosition].push(player);
		return acc;
	}, {});

	const positionOrder = [
		'QB',
		'RB',
		'WR',
		'TE'
	]; // Order for positions

	const playerData = positionOrder
		.map((position) => {
			const count = playerCounts[position] || 0;
			return [
				position,
				count
			];
		})
		.filter(
			(
				[
					,
					count
				]
			) => count > 0
		);

	const totalPlayers = playerData.reduce(
		(
			sum,
			[
				,
				count
			]
		) => sum + count,
		0
	);

	const positionColors = {
		QB: '#9647B8',
		RB: '#15997E',
		WR: '#E67E22',
		TE: '#2980B9'
	};

	const chartData = {
		series: playerData.map(
			(
				[
					,
					count
				]
			) => count
		),
		options: {
			chart: {
				type: 'donut',
				toolbar: { show: false }
			},
			labels: playerData.map(
				(
					[
						position
					]
				) => position
			),
			dataLabels: {
				enabled: true,
				formatter: (val, opts) => {
					const count = opts.w.globals.series[opts.seriesIndex];
					return `${count}`;
				},
				style: {
					fontSize: '12px',
					fontWeight: 'normal',
					colors: [
						'#FFF'
					]
				},
				dropShadow: {
					enabled: true,
					top: 1,
					left: 1,
					blur: 2,
					color: '#000',
					opacity: 0.7
				}
			},
			plotOptions: {
				pie: {
					dataLabels: { offset: -1 },
					donut: { size: '65%' }
				}
			},
			colors: playerData.map(
				(
					[
						position
					]
				) => positionColors[position] || '#000'
			),
			legend: { show: false },
			tooltip: !isDraftModeEnabled
				? {
						custom: ({ seriesIndex, w }) => {
							const status = w.config.labels[seriesIndex]; // Get the injury status from the label
							const playerList = groupedPlayers[status] || []; // Get the player names for that status

							// Return the custom tooltip content
							return `
                <div class="px-2 py-1 bg-white shadow-lg border border-gray-300 rounded text-black">
                  <strong>${status}</strong>
                  <ul>
                    ${playerList
											.map(
												(player) =>
													`<li style="color: black;">${player.first_name} ${player.last_name}</li>`
											)
											.join('')}
                  </ul>
                </div>
              `;
						}
					}
				: {} // Default behavior if !isDraftModeEnabled is true
		}
	};

	const handleSlideChange = (direction) => {
		setActiveSlide((prev) => {
			const newIndex = prev + direction;
			if (newIndex < 0) return 1;
			if (newIndex > 1) return 0;
			return newIndex;
		});
	};

	return (
		<div className="relative bg-white p-4 rounded-lg shadow-lg border border-gray-300 cursor-pointer">
			<h3 className="text-sm font-medium text-gray-800 mb-2 text-center">
				Position Overview
			</h3>

			{/* Navigation Controls */}
			<div className="absolute bottom-1 left-0 right-0 flex justify-between items-center w-full px-1 z-10">
				<button
					className="relative text-xl text-gray-700 focus:outline-none px-2 transition-all duration-300 hover:bg-gray-100 rounded-md"
					onClick={() => handleSlideChange(-1)} // Navigate to previous slide
					aria-label="Previous Slide"
				>
					&lt;
				</button>
				<button
					className="relative text-xl text-gray-700 focus:outline-none px-2 transition-all duration-300 hover:bg-gray-100 rounded-md"
					onClick={() => handleSlideChange(1)} // Navigate to next slide
					aria-label="Next Slide"
				>
					&gt;
				</button>
			</div>

			<div className="flex justify-center">
				{activeSlide === 0 && (
					<div className="flex flex-col items-center">
						<Chart
							options={chartData.options}
							series={chartData.series}
							type="donut"
							width="100%"
						/>
						<div className="grid grid-cols-2 gap-x-4 mt-1 mb-4">
							{playerData.map(([ position
							]) => (
								<div key={position} className="flex items-center mb-2">
									<span
										className="inline-block w-3 h-3 rounded-full"
										style={{ backgroundColor: positionColors[position] }}
									/>
									<span className="ml-2 text-sm capitalize">{position}</span>
								</div>
							))}
						</div>
					</div>
				)}

				{activeSlide === 1 && (
					<div className="overflow-y-auto my-2">
						<div className="mb-2">
							<label className="block text-xs uppercase font-medium text-gray-700">
								Rookie Count
							</label>
							<p>{rookieCount || 'N/A'}</p>
						</div>

						<div className="mb-2">
							<label className="block text-xs uppercase font-medium text-gray-700">
								Average Player Age
							</label>
							<p>{avgPlayerAge || 'N/A'} yrs</p>
						</div>
					</div>
				)}
			</div>
			{/* Slide Indicator */}
			<div className="absolute bottom-3.5 left-0 right-0 flex justify-center">
				{[
					0,
					1
				].map((index) => (
					<div
						key={index}
						className={`w-2 h-2 rounded-full mx-1 ${activeSlide === index
							? 'bg-gray-800'
							: 'bg-gray-300'}`}
					/>
				))}
			</div>
		</div>
	);
};

export default PlayerPositionCountCard;
