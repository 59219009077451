import React, { useEffect, useRef, Fragment } from 'react';

const RosterFormModal = ({
	isRosterProfileUpdate,
	modalRef,
	isOpen,
	closeModal,
	editingRoster,
	rosterTeamName,
	setRosterTeamName,
	rosterLeagueName,
	setRosterLeagueName,
	rosterPlatform,
	setRosterPlatform,
	rosterUsername,
	setRosterUsername,
	rosterUrl,
	setRosterUrl,
	rosterFormat,
	setRosterFormat,
	rosterScoring,
	setRosterScoring,
	rosterChampionshipYears,
	setRosterChampionshipYears,
	additionalEmails,
	handleEmailChange,
	handleAddEmail,
	handleRemoveEmail,
	handleSaveRoster,
	isDraftModeEnabled,
	errors = {},
	clearErrors
}) => {
	const handleCloseModal = () => {
		clearErrors();
		closeModal();
	};

	const handleClickOutsideRosterCreateModal = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			handleCloseModal();
		}
	};

	useEffect(
		() => {
			if (isOpen) {
				document.addEventListener(
					'mousedown',
					handleClickOutsideRosterCreateModal
				);
			} else {
				document.removeEventListener(
					'mousedown',
					handleClickOutsideRosterCreateModal
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					handleClickOutsideRosterCreateModal
				);
			};
		},
		[
			isOpen
		]
	);

	if (!isOpen) return null;

	return (
		<div
			className="fixed inset-0 z-50 overflow-auto flex w-full"
			style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
		>
			<div
				className="relative p-8 pt-6 bg-white m-auto flex-col flex rounded-lg max-w-xl w-full"
				ref={modalRef}
			>
				<button
					className="absolute top-2 right-2 p-2 rounded-full hover:bg-gray-200 transition duration-300"
					onClick={handleCloseModal}
				>
					<svg
						className="h-6 w-6 text-gray-500"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M6 18L18 6M6 6l12 12"
						/>
					</svg>
				</button>
				<h2 className="text-2xl font-bold mb-4">
					{editingRoster ? 'Edit Roster' : 'Create Roster'}
				</h2>
				<form>
					<label className="block mb-2">
						Team Name *
						<input
							type="text"
							className={`block w-full p-2 border ${errors && errors.team_name
								? 'border-red-500'
								: 'border-gray-300'} rounded mt-1`}
							value={rosterTeamName}
							onChange={(e) => setRosterTeamName(e.target.value)}
							placeholder={`Enter your team name`}
							required
						/>
						{errors &&
						errors.team_name && (
							<p className="text-red-500 text-sm">
								{errors && errors.team_name[0]}
							</p>
						)}
					</label>
					<label className="block mb-2">
						League Name *
						<input
							type="text"
							className={`block w-full p-2 border ${errors && errors.league_name
								? 'border-red-500'
								: 'border-gray-300'} rounded mt-1`}
							value={rosterLeagueName}
							onChange={(e) => setRosterLeagueName(e.target.value)}
							placeholder={`Enter your league name`}
							required
						/>
						{errors &&
						errors.league_name && (
							<p className="text-red-500 text-sm">
								{errors && errors.league_name[0]}
							</p>
						)}
					</label>
					<label className="block mb-2">
						Platform
						<select
							value={rosterPlatform}
							onChange={(e) => setRosterPlatform(e.target.value)}
							className="mt-1 p-2 border rounded w-full"
						>
							<option value="">Select your platform...</option>
							<option value="Yahoo">Yahoo</option>
							<option value="FFPC">FFPC</option>
							<option value="Sleeper">Sleeper</option>
							<option value="Underdog">Underdog</option>
							<option value="ESPN">ESPN</option>
							<option value="NFL">NFL</option>
						</select>
					</label>
					{/* Conditionally show instructions for finding roster URL */}
					{[
						'Yahoo',
						'Sleeper',
						'FFPC'
					].includes(rosterPlatform) && (
						<Fragment>
							<p className="text-sm text-gray-500 mb-2">
								{`⚠️ To sync your roster, you must provide your ${rosterPlatform} ${rosterPlatform ===
								'Sleeper'
									? ' username and'
									: ''} roster url.`}
							</p>
						</Fragment>
					)}
					{/* Conditionally show the "Username" field if platform is "Sleeper" */}
					{rosterPlatform === 'Sleeper' && (
						<label className="block mb-2">
							Username
							<input
								type="text"
								className={`block w-full p-2 border ${errors && errors.username
									? 'border-red-500'
									: 'border-gray-300'} rounded mt-1`}
								value={rosterUsername}
								onChange={(e) => setRosterUsername(e.target.value)}
								placeholder={`Enter your ${rosterPlatform} username`}
							/>
							{errors &&
							errors.username && (
								<p className="text-red-500 text-sm">{errors.username[0]}</p>
							)}
						</label>
					)}
					{/* Conditionally show the "Roster URL" field */}
					{[
						'Yahoo',
						'Sleeper',
						'FFPC'
					].includes(rosterPlatform) && (
						<label className="block mb-2">
							Roster URL
							<input
								type="text"
								className="block w-full p-2 border border-gray-300 rounded mt-1"
								value={rosterUrl}
								onChange={(e) => setRosterUrl(e.target.value)}
								placeholder={`Enter your ${rosterPlatform} roster url`}
							/>
							<p className="text-sm text-gray-500 mt-1.5 mb-2">
								{rosterPlatform === 'Yahoo' ? (
									'e.g. https://football.fantasysports.yahoo.com/f1/635750/7'
								) : rosterPlatform === 'Sleeper' ? (
									'e.g. https://sleeper.com/leagues/1245144575087372272/team'
								) : rosterPlatform === 'FFPC' ? (
									'e.g. https://myffpc.com/SetLineup.aspx?ltuid=693-C4WS9E304D39'
								) : (
									''
								)}
							</p>
						</label>
					)}
					<label className="block mb-2">
						Format
						<select
							value={rosterFormat}
							onChange={(e) => setRosterFormat(e.target.value)}
							className="mt-1 p-2 border rounded w-full"
						>
							<option value="">Select your format...</option>
							<option value="Dynasty">Dynasty</option>
							<option value="Keeper">Keeper</option>
							<option value="Redraft">Redraft</option>
							<option value="Best Ball">Best Ball</option>
						</select>
					</label>
					<label className="block mb-2">
						Scoring
						<select
							value={rosterScoring}
							onChange={(e) => setRosterScoring(e.target.value)}
							className="mt-1 p-2 border rounded w-full"
						>
							<option value="">Select your scoring type...</option>
							<option value="Half PPR">Half PPR</option>
							<option value="Half PPR - Superflex">Half PPR - Superflex</option>
							<option value="PPR">PPR</option>
							<option value="PPR - TE Premium">PPR - TE Premium</option>
							<option value="PPR - Superflex">PPR - Superflex</option>
							<option value="PPR - Superflex - TE Premium">
								PPR - Superflex - TE Premium
							</option>
							<option value="Standard">Standard</option>
							<option value="Standard - Superflex">Standard - Superflex</option>
						</select>
					</label>
					<label className="block mb-2">
						Championships (in years)
						<input
							type="text"
							className={`block w-full p-2 border ${errors &&
							errors.championship_years
								? 'border-red-500'
								: 'border-gray-300'} rounded mt-1`}
							value={rosterChampionshipYears.join(', ')}
							onChange={(e) => {
								const inputValue = e.target.value.trim();
								setRosterChampionshipYears(
									inputValue === ''
										? []
										: inputValue.split(',').map((year) => year.trim())
								);
							}}
							placeholder="Enter your championships (in years)"
						/>
						{errors && errors.championship_years ? (
							<p className="text-red-500 text-base">
								{errors.championship_years[0]}
							</p>
						) : (
							<p className="text-sm text-gray-500 mt-1.5 mb-2">
								Comma separated (e.g. 2023, 2024)
							</p>
						)}
					</label>
					<label className="block mb-2">
						{additionalEmails.length > 1 ? 'Emails' : 'Email'}
						{additionalEmails.map((email, index) => (
							<div key={index} className="flex items-center mb-2">
								<input
									type="email"
									className={`block w-full p-2 border rounded mt-1 ${index === 0
										? 'bg-gray-100 text-gray-500 cursor-not-allowed'
										: 'border-gray-300'}`}
									value={email}
									onChange={(e) => handleEmailChange(index, e.target.value)}
									placeholder="Enter user email"
									readOnly={index === 0}
								/>
								{index > 0 && (
									<button
										type="button"
										onClick={() => handleRemoveEmail(index)}
										className="ml-2 text-red-500 hover:underline"
									>
										Remove
									</button>
								)}
							</div>
						))}
						<button
							type="button"
							onClick={handleAddEmail}
							className="mt-2 text-blue-500 hover:underline"
						>
							Add another email
						</button>
					</label>
					{/* Display email field error message if exists */}
					{errors &&
					errors.emails && (
						<div className="text-red-500 text-sm mt-2 max-w-sm">
							{errors.emails}
							<span className="ml-2">
								<div>
									The user(s) must sign up before being added to the roster.
								</div>
							</span>
						</div>
					)}
					<div className="flex justify-end">
						<div className="flex justify-between mt-4">
							<button
								type="button"
								className="mx-2 px-4 py-2 text-gray-700 bg-gray-200 rounded-lg hover:bg-gray-300"
								onClick={handleCloseModal}
							>
								Cancel
							</button>
							<button
								type="button"
								className="mx-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
								onClick={() => handleSaveRoster(false)}
							>
								Save
							</button>
							{!isRosterProfileUpdate &&
							!isDraftModeEnabled && (
								<button
									type="button"
									className="mx-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
									onClick={() => handleSaveRoster(true)}
								>
									Save and view roster
								</button>
							)}
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default RosterFormModal;
