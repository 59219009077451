import React from 'react';

export default function PageTitle(props) {
	// console.log('PageTitle', props);
	return (
		<h1
			className={`text-${props.alignment} + ' text-xl sm:text-2xl md:text-4xl font-semibold mb-3 lg:mb-6`}
		>
			{props.title}
		</h1>
	);
}
