import React, { useEffect, useState, useRef, Fragment } from 'react';

const SocialPostsEmbed = ({ get_social_posts_endpoint }) => {
	const [
		socialPosts,
		setSocialPosts
	] = useState([]);
	const [
		currentIndex,
		setCurrentIndex
	] = useState(0);
	const [
		isUserInteracting,
		setIsUserInteracting
	] = useState(false);
	const rotationTimerRef = useRef(null);
	const containerRef = useRef(null);

	useEffect(
		() => {
			const fetchSocialPosts = async () => {
				try {
					const response = await fetch(get_social_posts_endpoint);
					if (!response.ok) {
						throw new Error('Failed to fetch social posts');
					}
					const data = await response.json();
					setSocialPosts(data.data);
				} catch (error) {
					console.error('Error fetching social posts:', error);
				}
			};

			fetchSocialPosts();

			const twitterScript = document.createElement('script');
			twitterScript.src = 'https://platform.twitter.com/widgets.js';
			twitterScript.async = true;

			const instagramScript = document.createElement('script');
			instagramScript.src = 'https://www.instagram.com/embed.js';
			instagramScript.async = true;

			document.body.appendChild(twitterScript);
			document.body.appendChild(instagramScript);

			return () => {
				document.body.removeChild(twitterScript);
				document.body.removeChild(instagramScript);
			};
		},
		[
			get_social_posts_endpoint
		]
	);

	useEffect(
		() => {
			// Trigger Twitter embed re-render
			if (window.twttr && window.twttr.widgets) {
				window.twttr.widgets.load();
			}

			// Trigger Instagram embed re-render
			if (window.instgrm && window.instgrm.Embeds) {
				window.instgrm.Embeds.process();
			}
		},
		[
			currentIndex
		]
	);

	useEffect(
		() => {
			if (socialPosts.length > 1 && !isUserInteracting) {
				rotationTimerRef.current = setInterval(() => {
					setCurrentIndex(
						(prevIndex) =>
							prevIndex < socialPosts.length - 1 ? prevIndex + 1 : 0
					);
				}, 8000);
			}

			return () => {
				if (rotationTimerRef.current) {
					clearInterval(rotationTimerRef.current);
				}
			};
		},
		[
			socialPosts,
			isUserInteracting
		]
	);

	const handlePrev = () => {
		setCurrentIndex(
			(prevIndex) => (prevIndex > 0 ? prevIndex - 1 : socialPosts.length - 1)
		);

		setIsUserInteracting(true);
		clearInterval(rotationTimerRef.current);
	};

	const handleNext = () => {
		setCurrentIndex(
			(prevIndex) => (prevIndex < socialPosts.length - 1 ? prevIndex + 1 : 0)
		);

		setIsUserInteracting(true);
		clearInterval(rotationTimerRef.current);
	};

	const renderPost = (post) => {
		return (
			<div
				key={post.id}
				dangerouslySetInnerHTML={{ __html: post.content }}
				className="tweet-content flex justify-center items-center w-full"
				onClick={() => setIsUserInteracting(true)}
			/>
		);
	};

	const handleMouseOver = () => {
		// Stop rotation when hovering over the container
		setIsUserInteracting(true);
		if (rotationTimerRef.current) {
			clearInterval(rotationTimerRef.current);
		}
	};

	return (
		<div
			className="bg-white border border-gray-200 shadow-lg rounded-xl"
			ref={containerRef}
			onMouseOver={handleMouseOver}
		>
			{/* Header Section */}
			<div className="mx-4 py-4 border-b border-gray-200">
				<h2 className="text-lg font-bold text-gray-800 flex items-center">
					<i className="fa-solid fa-hashtag text-lg mr-3" />
					Social Feed
				</h2>
			</div>

			{/* Social Posts Section */}
			<div className="p-4 flex justify-center">
				<div className="w-full max-w-3xl relative flex justify-center items-center min-h-[300px]">
					{socialPosts.length > 0 ? (
						<Fragment>
							{/* Render the current post */}
							{renderPost(socialPosts[currentIndex])}

							{/* Navigation Buttons */}
							{socialPosts.length > 1 && (
								<Fragment>
									<button
										onClick={handlePrev}
										className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-gray-200 p-3 rounded-md z-10 border border-gray-300"
										style={{ left: '-20px' }}
										aria-label="Previous Post"
									>
										{'<'}
									</button>
									<button
										onClick={handleNext}
										className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-gray-200 p-3 rounded-md z-10 border border-gray-300"
										style={{ right: '-20px' }}
										aria-label="Next Post"
									>
										{'>'}
									</button>
								</Fragment>
							)}
						</Fragment>
					) : (
						<p className="text-gray-500 text-center">
							No social posts available
						</p>
					)}
				</div>
			</div>
		</div>
	);
};

export default SocialPostsEmbed;
