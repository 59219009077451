import React from 'react';

const PaginationTrackerMobile = ({ currentPage, setPage, totalPages }) => {
	const handlePrevious = () => {
		if (currentPage > 1) setPage(currentPage - 1);
	};

	const handleNext = () => {
		if (currentPage < totalPages) setPage(currentPage + 1);
	};

	const handleFirst = () => {
		setPage(1);
	};

	const handleLast = () => {
		setPage(totalPages);
	};

	return (
		<div className="flex items-center gap-4 text-sm">
			{/* First Page Button */}
			<button
				onClick={handleFirst}
				disabled={currentPage === 1}
				className={`h-10 w-10 flex items-center justify-center rounded-full ${currentPage ===
				1
					? 'bg-gray-200 text-gray-500 cursor-not-allowed'
					: 'text-blue-500 hover:bg-gray-200'}`}
			>
				<i className="fas fa-angles-left" />
			</button>

			{/* Previous Page Button */}
			<button
				onClick={handlePrevious}
				disabled={currentPage === 1}
				className={`h-10 w-10 flex items-center justify-center rounded-full ${currentPage ===
				1
					? 'bg-gray-200 text-gray-500 cursor-not-allowed'
					: 'text-blue-500 hover:bg-gray-200'}`}
			>
				<i className="fas fa-chevron-left" />
			</button>

			{/* Current Page Indicator */}
			<span className="text-gray-700">
				{currentPage} of {totalPages}
			</span>

			{/* Next Page Button */}
			<button
				onClick={handleNext}
				disabled={currentPage === totalPages}
				className={`h-10 w-10 flex items-center justify-center rounded-full ${currentPage ===
				totalPages
					? 'bg-gray-200 text-gray-500 cursor-not-allowed'
					: 'text-blue-500 hover:bg-gray-200'}`}
			>
				<i className="fas fa-chevron-right" />
			</button>

			{/* Last Page Button */}
			<button
				onClick={handleLast}
				disabled={currentPage === totalPages}
				className={`h-10 w-10 flex items-center justify-center rounded-full ${currentPage ===
				totalPages
					? 'bg-gray-200 text-gray-500 cursor-not-allowed'
					: 'text-blue-500 hover:bg-gray-200'}`}
			>
				<i className="fas fa-angles-right" />
			</button>
		</div>
	);
};

export default PaginationTrackerMobile;
