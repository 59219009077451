import React, { useState, useEffect } from 'react';

export default function UserTrophiesCard({ userId, get_user_endpoint }) {
	const [
		user,
		setUser
	] = useState({});

	useEffect(
		() => {
			if (get_user_endpoint && userId) {
				const endpoint = get_user_endpoint.replace('id', userId);
				fetch(endpoint).then((response) => response.json()).then((result) => {
					setUser(result.data || {});
				});
			}
		},
		[
			userId,
			get_user_endpoint
		]
	);

	if (!user) {
		return null;
	}

	return (
		<div className="bg-white w-full min-w-96 text-gray-800">
			<h2 className="text-lg lg:text-xl font-semibold mb-4 text-gray-700">
				{user.first_name}'s Championship Trophies
			</h2>
			<div className="col-span-12 lg:col-span-10">
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
					{user &&
					user.trophy_case &&
					user.trophy_case.length === 0 && (
						<div className="col-span-1 md:col-span-2 lg:col-span-3 bg-white p-4 text-center text-gray-800">
							No trophies... yet. Keep working! 💪
						</div>
					)}

					{user &&
						user.trophy_case &&
						user.trophy_case.map((trophy, index) => (
							<div
								key={index}
								className="border border-gray-200 px-4 py-6 rounded-md flex flex-col items-center justify-start bg-white shadow-md"
							>
								<div className="text-8xl mb-2 mt-1">🏆</div>
								<div className="text-center mb-2">
									<a
										href={`/rosters/${trophy.roster_id}`}
										className="text-blue-500 hover:underline"
									>
										{trophy.roster_team_name}
									</a>
								</div>
								<div className="text-center font-semibold text-gray-800 mb-2">
									{trophy.roster_league_name}
								</div>
								<div className="text-center text-sm text-gray-600">
									{trophy.championship_years.join(', ')}
								</div>
							</div>
						))}
				</div>
			</div>
		</div>
	);
}
