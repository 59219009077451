import React from 'react';
import ReactApexChart from 'react-apexcharts';

const TimeOfPossessionChart = ({ teamData, leagueData, teamName }) => {
	// Extract the last word from the team name
	const teamNameLastWord = teamName.split(' ').pop();

	// Helper function to convert time string (e.g., "32:33") to total seconds
	const timeStringToSeconds = (timeString) => {
		if (!timeString) return 0;
		const [
			minutes,
			seconds
		] = timeString.split(':').map(Number);
		return minutes * 60 + seconds;
	};

	// Function to convert seconds back to time string (mm:ss)
	const secondsToTimeString = (seconds) => {
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds % 60;
		return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
	};

	// Total game time in seconds (60:00)
	const totalGameTimeSeconds = 60 * 60; // 60 minutes * 60 seconds

	// Team's average time of possession in seconds
	const teamTimeOfPossessionSeconds =
		teamData && teamData.avg_time_of_possession
			? timeStringToSeconds(teamData.avg_time_of_possession)
			: 0;

	// League's average time of possession in seconds
	const leagueTimeOfPossessionSeconds =
		leagueData && leagueData.avg_time_of_possession
			? timeStringToSeconds(leagueData.avg_time_of_possession)
			: 0;

	// Convert time in seconds back to time string
	const teamTimeOfPossession = secondsToTimeString(teamTimeOfPossessionSeconds);
	const leagueTimeOfPossession = secondsToTimeString(
		leagueTimeOfPossessionSeconds
	);

	// Calculate the percentage of the total game time for team and league
	const teamPercentage =
		teamTimeOfPossessionSeconds / totalGameTimeSeconds * 100;
	const leaguePercentage =
		leagueTimeOfPossessionSeconds / totalGameTimeSeconds * 100;

	// Chart configuration
	const chartOptions = {
		series: [
			teamPercentage,
			leaguePercentage
		], // Percentage of time of possession for team and league
		options: {
			chart: {
				height: 390,
				type: 'radialBar'
			},
			title: {
				text: 'Time of Possession', // Chart title
				align: 'center',
				style: {
					fontSize: '14px',
					color: '#333' // Title text color
				}
			},
			plotOptions: {
				radialBar: {
					offsetY: 0,
					startAngle: 0,
					endAngle: 270,
					hollow: {
						margin: 5,
						size: '30%',
						background: 'transparent'
					},
					dataLabels: {
						name: {
							show: true,
							fontSize: '16px',
							fontWeight: 'bold',
							color: '#000',
							formatter: function(val) {
								// Show the labels as Team and League
								return val === teamPercentage ? 'Team' : 'League';
							}
						},
						value: {
							show: true,
							fontSize: '24px',
							fontWeight: 'bold',
							color: '#000',
							formatter: function(val) {
								// Return the time value (team or league)
								return val === teamPercentage
									? teamTimeOfPossession // Show team's time
									: leagueTimeOfPossession; // Show league's time
							}
						}
					},
					barLabels: {
						enabled: true,
						useSeriesColors: true,
						offsetX: 10, // Adjust this for better alignment
						fontSize: '16px',
						formatter: function(seriesName, opts) {
							// Display the time in mm:ss next to the label
							const time =
								opts.seriesIndex === 0
									? teamTimeOfPossession
									: leagueTimeOfPossession;
							return `${seriesName}: ${time}`;
						}
					}
				}
			},
			colors: [
				'#2980B9',
				'#E67E22'
			], // Team color (purple) and light gray for the league
			labels: [
				`${teamNameLastWord}`, // Team name
				'League Avg'
			],
			responsive: [
				{
					breakpoint: 480,
					options: {
						legend: {
							show: false
						}
					}
				}
			],
			legend: {
				position: 'top',
				horizontalAlign: 'center'
			}
		}
	};

	return (
		<div className="bg-white p-4 rounded-lg shadow-lg border border-gray-300">
			<ReactApexChart
				options={chartOptions.options}
				series={chartOptions.series}
				type="radialBar"
				height={250}
			/>
		</div>
	);
};

export default TimeOfPossessionChart;
