import React from 'react';
import { Link } from 'react-router-dom';
import AdvancedDataTable from '../../../../DataTables/AdvancedDataTable';
import MobileDataTableCard from '../../../../DataTables/MobileDataTableCard';
import getFormattedNumberWithComma from '../../../../../utils/getFormattedNumberWithComma';

const WideReceiversProBasicStatsTable = ({
	players,
	sortBy,
	sortOrder,
	handleSortOrderChange
}) => {
	return (
		<div>
			<div className="contents lg:hidden">
				<MobileDataTableCard
					data={players}
					style="text-gray-800 border-b border-gray-300 font-semibold"
					rowRenderer={(row) => {
						const labels = [
							{
								displayName: 'Player',
								apiName: 'player',
								alignment: 'left'
							},
							{
								displayName: 'Team',
								apiName: 'team',
								alignment: 'left'
							},
							{
								displayName: 'Games Played',
								apiName: 'pro_games_played',
								alignment: 'left'
							},
							{
								displayName: 'Receptions',
								apiName: 'pro_receptions',
								alignment: 'left'
							},
							{
								displayName: 'Receiving Yards',
								apiName: 'pro_receiving_yards',
								alignment: 'left'
							},
							{
								displayName: 'Receiving TDs',
								apiName: 'pro_receiving_touchdowns',
								alignment: 'left'
							}
						];
						return (
							<div
								key={`${row.first_name} ${row.last_name} - QuarterbacksProBasicStatsTable - MobileDataTableCard`}
								className="p-5 bg-white border-t text-gray-800"
							>
								<div>
									<Link
										to={`/players/${row.id}?position=${row.position}`}
										className="text-blue-500 hover:text-blue-700"
									>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<div
												style={{
													width: '50px',
													height: '50px',
													overflow: 'hidden',
													marginRight: '8px'
												}}
											>
												<img
													src={row.image_url + '?w=50&h=50'}
													alt={`${row.first_name} ${row.last_name}`}
													style={{
														width: '100%',
														height: '100%',
														objectFit: 'cover',
														border: '1px solid #ddd',
														borderRadius: '50%'
													}}
												/>
											</div>
											{`${row.first_name} ${row.last_name}`}
										</div>
									</Link>
								</div>
								<div className="mt-4 font-semibold">
									{labels[1].displayName}
								</div>
								<div> {row.team.name.split(' ').slice(-1)[0]}</div>
								<div className="mt-4 font-semibold">
									{labels[2].displayName}
								</div>
								<div>{row.pro_games_played}</div>
								<div className="mt-4 font-semibold">
									{labels[3].displayName}
								</div>
								<div>{getFormattedNumberWithComma(row.pro_receptions)}</div>
								<div className="mt-4 font-semibold">
									{labels[4].displayName}
								</div>
								<div>
									{getFormattedNumberWithComma(row.pro_receiving_yards)}
								</div>
								<div className="mt-4 font-semibold">
									{labels[5].displayName}
								</div>
								<div>{row.pro_receiving_touchdowns}</div>
							</div>
						);
					}}
				/>
			</div>
			<div className="hidden lg:contents">
				<AdvancedDataTable
					data={players}
					style="text-gray-800 border-b border-gray-300 font-semibold"
					sortOrder={sortOrder}
					sortColumn={sortBy ? sortBy : ''}
					onChangeSortOrder={handleSortOrderChange}
					sortingEnabled={true}
					columns={[
						{
							displayName: 'Player',
							apiName: 'player',
							alignment: 'left',
							minWidth: '240px'
						},
						{
							displayName: 'Games Played',
							apiName: 'pro_games_played',
							alignment: 'left'
						},
						{
							displayName: 'Receptions',
							apiName: 'pro_receptions',
							alignment: 'left'
						},
						{
							displayName: 'Receiving Yards',
							apiName: 'pro_receiving_yards',
							alignment: 'left'
						},
						{
							displayName: 'Receiving TDs',
							apiName: 'pro_receiving_touchdowns',
							alignment: 'left'
						}
					]}
					rowRenderer={(row) => {
						return (
							<tr
								className="bg-white pl-8 py-3 text-left sticky left-0 bg-white z-10 w-60 border-t border-gray-200"
								key={`${row.first_name} ${row.last_name} - QuarterbacksProBasicStatsTable - AdvancedDataTable`}
							>
								<td className="pl-8 py-3 text-left sticky left-0 bg-white z-10 w-60 border-t border-gray-200">
									<Link
										to={`/players/${row.id}?position=${row.position}`}
										className="text-blue-500 hover:text-blue-700"
									>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<div
												style={{
													width: '50px',
													height: '50px',
													overflow: 'hidden',
													marginRight: '8px'
												}}
											>
												<img
													src={row.image_url + '?w=50&h=50'}
													alt={`${row.first_name} ${row.last_name}`}
													style={{
														width: '100%',
														height: '100%',
														objectFit: 'cover',
														border: '1px solid #ddd',
														borderRadius: '50%'
													}}
												/>
											</div>
											{`${row.first_name} ${row.last_name}`}
										</div>
									</Link>
								</td>
								<td className="pl-8 py-3 text-left">{row.pro_games_played}</td>
								<td className="pl-8 py-3 text-left">
									{getFormattedNumberWithComma(row.pro_receptions)}
								</td>
								<td className="pl-8 py-3 text-left">
									{getFormattedNumberWithComma(row.pro_receiving_yards)}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.pro_receiving_touchdowns}
								</td>
							</tr>
						);
					}}
				/>
			</div>
		</div>
	);
};

export default WideReceiversProBasicStatsTable;
