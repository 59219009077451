import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import AdvancedDataTable from '../../DataTables/AdvancedDataTable';
import MobileDataTableCard from '../../DataTables/MobileDataTableCard';

const TeamsProfileStatsTable = ({
	teams,
	sortBy,
	sortOrder,
	handleSortOrderChange
}) => {
	{
		/*
	const [
		temperatures,
		setTemperatures
	] = useState({});

 const fetchTemperature = (location, teamId) => {
		let city = location.split(',')[0].trim();
		if (city === 'Tampa Bay') {
			city = 'Tampa';
		}

		fetch(
			`https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=336d6d9ed7689fcfacbc7a9cd10f4250`
		)
			.then((response) => response.json())
			.then((data) => {
				if (
					data.main &&
					data.main.temp &&
					data.weather &&
					data.weather.length > 0
				) {
					// Convert temperature to Fahrenheit
					const tempInFahrenheit = Math.round(
						(data.main.temp - 273.15) * 9 / 5 + 32
					);
					// Get weather description
					const weatherDescription = data.weather[0].main;
					setTemperatures((prevTemperatures) => ({
						...prevTemperatures,
						[teamId]: {
							temp: tempInFahrenheit,
							weather: weatherDescription
						}
					}));
				}
			})
			.catch((error) => {
				console.error('Error fetching temperature:', error);
			});
	}; 

	useEffect(
		() => {
			teams.forEach((team) => {
				fetchTemperature(team.location, team.id);
			});
		},
		[
			teams
		]
	);
*/
	}

	return (
		<div>
			<div className="contents lg:hidden">
				<MobileDataTableCard
					data={teams}
					style="text-gray-800 border-b border-gray-300 font-semibold"
					rowRenderer={(row) => {
						const labels = [
							{
								displayName: 'Team',
								apiName: 'name',
								alignment: 'left'
							},
							{
								/* {
								displayName: 'Forcast',
								apiName: 'forcast',
								alignment: 'left'
							},
							{
								displayName: 'Conference',
								apiName: 'conference',
								alignment: 'left'
							},
							{
								displayName: 'Division',
								apiName: 'division',
								alignment: 'left'
							},
							{
								displayName: 'Head Coach',
								apiName: 'head_coach',
								alignment: 'left'
							},
							{
								displayName: 'Offensive Coordinator',
								apiName: 'offensive_coordinator',
								alignment: 'left'
							} */
							}
						];
						return (
							<div
								key={`${row.name} - TeamsProfileStatsTable - MobileDataTableCard`}
								className="p-5 bg-white border-t text-gray-800"
							>
								<div>
									<Link
										to={`/teams/${row.id}`}
										className="text-blue-500 hover:text-blue-700"
									>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<div
												style={{
													width: '50px',
													height: '50px',
													overflow: 'hidden',
													marginRight: '8px'
												}}
											>
												<img
													src={row.image_url + '?w=50&h=50'}
													alt={`${row.name}`}
													style={{
														width: '100%',
														height: '100%',
														objectFit: 'cover',
														border: '1px solid #ddd',
														borderRadius: '50%'
													}}
												/>
											</div>
											{`${row.name}`}
										</div>
									</Link>
								</div>
								{/* <div className="mt-4 font-semibold">
									{labels[1].displayName}
								</div>
								<div>
									{temperatures[row.id] ? (
										`${temperatures[row.id].temp}°F, ${temperatures[row.id]
											.weather}`
									) : (
										'Loading...'
									)}
								</div>
								<div className="mt-4 font-semibold">
									{labels[2].displayName}
								</div>
								<div>{row.conference}</div>
								<div className="mt-4 font-semibold">
									{labels[3].displayName}
								</div>
								<div>{row.division}</div>
								<div className="mt-4 font-semibold">
									{labels[4].displayName}
								</div>
								<div>{row.head_coach}</div>
								<div className="mt-4 font-semibold">
									{labels[5].displayName}
								</div>
								<div>{row.offensive_coordinator}</div> */}
							</div>
						);
					}}
				/>
			</div>
			<div className="hidden lg:contents">
				<AdvancedDataTable
					data={teams}
					style="text-gray-800 border-b border-gray-300 font-semibold"
					sortOrder={sortOrder}
					sortColumn={sortBy ? sortBy : ''}
					onChangeSortOrder={handleSortOrderChange}
					sortingEnabled={true}
					columns={[
						{
							displayName: 'Team',
							apiName: 'name',
							alignment: 'left',
							minWidth: '270px'
						},
						/*
							 {
							displayName: 'Forecast',
							apiName: 'forecast',
							alignment: 'left'
						}, */
						{
							displayName: 'Conference',
							apiName: 'conference',
							alignment: 'left'
						},
						{
							displayName: 'Division',
							apiName: 'division',
							alignment: 'left'
						},
						{
							displayName: 'Head Coach',
							apiName: 'head_coach',
							alignment: 'left'
						},
						{
							displayName: 'Offensive Coordinator',
							apiName: 'offensive_coordinator',
							alignment: 'left'
						}
					]}
					rowRenderer={(row) => {
						return (
							<tr
								className={`pl-8 py-3 text-left sticky left-0 bg-white z-10 w-60 border-t border-gray-200`}
								key={`${row.name} - TeamProfileStatsTable - AdvancedDataTable`}
							>
								<td className="pl-8 py-3 text-left">
									<Link
										to={`/teams/${row.id}`}
										className="text-blue-500 hover:underline hover:cursor-pointer"
									>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<div
												style={{
													width: '50px',
													height: '50px',
													overflow: 'hidden',
													marginRight: '8px',
													border: '1px solid #ddd',
													borderRadius: '50%',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center'
												}}
											>
												<img
													src={row.image_url + '?w=50&h=50'}
													alt={`${row.name}`}
													style={{
														width: 'calc(100% - 10px)',
														height: 'calc(100% - 10px)',
														objectFit: 'cover'
													}}
												/>
											</div>
											{`${row.name}`}
										</div>
									</Link>
								</td>
								{/* <td className="pl-8 py-3 text-left">
									{temperatures[row.id] ? (
										`${temperatures[row.id].temp}°F, ${temperatures[row.id]
											.weather}`
									) : (
										'Loading...'
									)}
								</td> */}
								<td className="pl-8 py-3 text-left">{row.conference}</td>
								<td className="pl-8 py-3 text-left">{row.division}</td>
								<td className="pl-8 py-3 text-left">{row.head_coach}</td>
								<td className="pl-8 py-3 text-left">
									{row.offensive_coordinator}
								</td>
							</tr>
						);
					}}
				/>
			</div>
		</div>
	);
};

export default TeamsProfileStatsTable;
