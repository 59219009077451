import React from 'react';
import ReactApexChart from 'react-apexcharts';

const OffensivePlayBalanceChart = ({ teamData, leagueData, teamName }) => {
	console.log('teamData:', teamData);
	console.log('leagueData:', leagueData);
	console.log('teamName:', teamName);

	// Extract the last word from the team name
	const teamNameLastWord = teamName.split(' ').pop();

	// Ensure data exists and provide fallbacks, converting values to numbers
	const teamPassAttempts =
		teamData && teamData.avg_pass_attempts
			? parseFloat(teamData.avg_pass_attempts)
			: 0;
	const teamRushAttempts =
		teamData && teamData.avg_rush_attempts
			? parseFloat(teamData.avg_rush_attempts)
			: 0;
	const leaguePassAttempts =
		leagueData && leagueData.avg_pass_attempts
			? parseFloat(leagueData.avg_pass_attempts)
			: 0;
	const leagueRushAttempts =
		leagueData && leagueData.avg_rush_attempts
			? parseFloat(leagueData.avg_rush_attempts)
			: 0;

	const teamTotalPlays = teamPassAttempts + teamRushAttempts;
	const leagueTotalPlays = leaguePassAttempts + leagueRushAttempts;

	// Avoid division by zero
	const teamPassingPct =
		teamTotalPlays > 0 ? teamPassAttempts / teamTotalPlays * 100 : 0;
	const teamRushingPct =
		teamTotalPlays > 0 ? teamRushAttempts / teamTotalPlays * 100 : 0;
	const leaguePassingPct =
		leagueTotalPlays > 0 ? leaguePassAttempts / leagueTotalPlays * 100 : 0;
	const leagueRushingPct =
		leagueTotalPlays > 0 ? leagueRushAttempts / leagueTotalPlays * 100 : 0;

	// Chart configuration
	const chartOptions = {
		series: [
			{
				name: 'Passing Plays',
				data: [
					teamPassingPct,
					leaguePassingPct
				]
			},
			{
				name: 'Rushing Plays',
				data: [
					teamRushingPct,
					leagueRushingPct
				]
			}
		],
		options: {
			chart: {
				type: 'bar',
				stacked: true,
				stackType: '100%',
				height: 250
			},
			plotOptions: {
				bar: {
					horizontal: true,
					barHeight: '55%'
				}
			},
			colors: [
				'#9647B8',
				'#15997E'
			],
			stroke: {
				width: 1,
				colors: [
					'#fff'
				]
			},
			title: {
				text: 'Play Balance',
				align: 'center',
				style: { fontSize: '14px' }
			},
			xaxis: {
				title: {
					text: '% of Plays'
				},
				categories: [
					`${teamNameLastWord}`,
					'League Avg'
				],
				labels: {
					formatter: (val) => `${Math.round(val)}` // No decimal, rounding to nearest whole number
				}
			},
			tooltip: {
				y: {
					formatter: (val) => `${Math.round(val)}%` // No decimal in the tooltip as well
				}
			},
			fill: {
				opacity: 1
			},
			legend: {
				position: 'top',
				horizontalAlign: 'center'
			}
		}
	};

	return (
		<div className="bg-white p-4 rounded-lg shadow-lg border border-gray-300">
			<ReactApexChart
				options={chartOptions.options}
				series={chartOptions.series}
				type="bar"
				height={250}
			/>
		</div>
	);
};

export default OffensivePlayBalanceChart;
