import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using React Router for navigation

const ArticleCard = ({ article }) => {
	return (
		<div
			key={article.id}
			className="rounded-lg border border-gray-300 bg-white shadow-lg hover:shadow-xl transition-shadow p-6 flex flex-col"
		>
			<div>
				{/* Article Categories */}
				{article.category_1 && (
					<span className="text-xs font-semibold text-blue-600 bg-blue-100 px-2 py-1 rounded-full inline-block mb-4">
						{article.category_1}
					</span>
				)}
				{article.category_2 && (
					<span className="text-xs font-semibold text-blue-600 bg-blue-100 px-2 py-1 rounded-full inline-block mb-4 ml-2">
						{article.category_2}
					</span>
				)}
				{article.category_3 && (
					<span className="text-xs font-semibold text-blue-600 bg-blue-100 px-2 py-1 rounded-full inline-block mb-4 ml-2">
						{article.category_3}
					</span>
				)}
				{article.category_4 && (
					<span className="text-xs font-semibold text-blue-600 bg-blue-100 px-2 py-1 rounded-full inline-block mb-4 ml-2">
						{article.category_4}
					</span>
				)}
				{article.category_5 && (
					<span className="text-xs font-semibold text-blue-600 bg-blue-100 px-2 py-1 rounded-full inline-block mb-4 ml-2">
						{article.category_5}
					</span>
				)}

				{/* Title */}
				<Link
					to={`${article.article_type === 'News'
						? article.url
						: `/help-center/${article.id}`}`}
					target={article.article_type === 'News' ? '_blank' : '_self'}
					className="block"
				>
					<h3 className="text-lg font-semibold text-gray-800 hover:text-gray-800 hover:underline pb-1">
						{article.title}
					</h3>
				</Link>
				{/* Author */}
				{article.author && (
					<p className="text-sm text-gray-500 pb-3">By {article.author}</p>
				)}
				{/* Published Date */}
				{/* {article.published_date && (
          <p className="text-xs text-gray-400 pb-3">
            {new Date(article.published_date).toLocaleDateString()}
          </p>
        )} */}
				{/* Synopsis */}
				<p className="text-gray-700 text-sm line-clamp-3">{article.synopsis}</p>
				{/* Publisher */}
				{article.publisher && (
					<div className="text-sm text-gray-500 py-3 flex items-center">
						<span className="font-medium mr-1">Published by</span>
						{article.publisher === 'Yahoo! Sports' ? (
							<span>{article.publisher}</span>
						) : article.publisher === 'ESPN' ? (
							<span>{article.publisher}</span>
						) : (
							<span>{article.publisher}</span>
						)}
					</div>
				)}
			</div>

			{/* Action Section */}
			<div className="mt-auto flex justify-end">
				<Link
					to={`${article.article_type === 'News'
						? article.url
						: `/help-center/${article.id}`}`}
					target={article.article_type === 'News' ? '_blank' : '_self'}
					className="text-blue-500 font-medium text-sm hover:underline"
				>
					Read more
					<i className="fa-solid fa-arrow-up-right-from-square ml-2" />
				</Link>
			</div>
		</div>
	);
};

export default ArticleCard;
