import React from 'react';

const PerPageDropdown = ({ perPage, setPerPage }) => {
	const options = [
		25,
		50,
		75,
		100,
		250
	];

	return (
		<div className="flex items-center space-x-2">
			<label
				htmlFor="perPageDropdown"
				className="text-gray-700 font-medium hidden xl:block"
			>
				Rows per page
			</label>
			<div className="relative inline-block">
				<select
					id="perPageDropdown"
					value={perPage}
					onChange={(e) => setPerPage(parseInt(e.target.value, 10))}
					className="block appearance-none bg-white border border-gray-300 text-gray-900 py-2 px-4 pr-10 rounded leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
				>
					{options.map((option) => (
						<option key={option} value={option}>
							{option}
						</option>
					))}
				</select>
				<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
					<i className="fas fa-chevron-down text-gray-500" />
				</div>
			</div>
		</div>
	);
};

export default PerPageDropdown;
