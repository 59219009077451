import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import StatusWidget from '../../StatusWidget/StatusWidget';
import CircularBarChart from '../../Charts/CircularBarChart';
import RookieTag from '../../Tags/RookieTag';
import HofTag from '../../Tags/HofTag';
import Week15OpponentsModal from '../../Player/Week15OpponentsModal/Week15OpponentsModal';
import Week16OpponentsModal from '../../Player/Week16OpponentsModal/Week16OpponentsModal';
import Week17OpponentsModal from '../../Player/Week17OpponentsModal/Week17OpponentsModal';
import ContractYearTag from '../../Tags/ContractYearTag';
import getPositionAbbreviation from '../../../utils/getPositionAbbreviation';
import getPlayerTeamCityAbbreviation from '../../../utils/getPlayerTeamCityAbbreviation';
import convertInchesToFeetAndInches from '../../../utils/convertInchesToFeetAndInches';

const RosterPlayerCard = ({
	player,
	loading,
	gradeRanks,
	roster_url,
	handleRosterPlayerClick,
	handleTeamClick,
	handleOpenRosterPlayerNote,
	handleRankClick,
	visibleRankModal,
	rankModalRef,
	currentPlayerRef,
	handlePlayerClick,
	confirmDeleteRosterPlayer
}) => {
	const modalRef = useRef(null);

	const [
		isPlayerWeek15OpponentsModalOpen,
		setIsPlayerWeek15OpponentsModalOpen
	] = useState(false);
	const [
		isPlayerWeek16OpponentsModalOpen,
		setIsPlayerWeek16OpponentsModalOpen
	] = useState(false);
	const [
		isPlayerWeek17OpponentsModalOpen,
		setIsPlayerWeek17OpponentsModalOpen
	] = useState(false);
	const [
		isExpanded,
		setIsExpanded
	] = useState(false);

	const toggleExpanded = () => {
		setIsExpanded(!isExpanded);
	};

	const handleOpenPlayerWeek15OpponentsModal = () => {
		setIsPlayerWeek15OpponentsModalOpen(true);
	};

	const handleOpenPlayerWeek16OpponentsModal = () => {
		setIsPlayerWeek16OpponentsModalOpen(true);
	};

	const handleOpenPlayerWeek17OpponentsModal = () => {
		setIsPlayerWeek17OpponentsModalOpen(true);
	};

	const closeWeek15OpponentsModal = () => {
		setIsPlayerWeek15OpponentsModalOpen(false);
	};

	const closeWeek16OpponentsModal = () => {
		setIsPlayerWeek16OpponentsModalOpen(false);
	};

	const closeWeek17OpponentsModal = () => {
		setIsPlayerWeek17OpponentsModalOpen(false);
	};

	const closeWeek15OpponentsModalOnClickOutside = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			setIsPlayerWeek15OpponentsModalOpen(false);
		}
	};

	const closeWeek16OpponentsModalOnClickOutside = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			setIsPlayerWeek16OpponentsModalOpen(false);
		}
	};

	const closeWeek17OpponentsModalOnClickOutside = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			setIsPlayerWeek17OpponentsModalOpen(false);
		}
	};

	useEffect(
		() => {
			if (isPlayerWeek15OpponentsModalOpen) {
				document.addEventListener(
					'mousedown',
					closeWeek15OpponentsModalOnClickOutside
				);
			} else {
				document.removeEventListener(
					'mousedown',
					closeWeek15OpponentsModalOnClickOutside
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					closeWeek15OpponentsModalOnClickOutside
				);
			};
		},
		[
			isPlayerWeek15OpponentsModalOpen
		]
	);

	useEffect(
		() => {
			if (isPlayerWeek16OpponentsModalOpen) {
				document.addEventListener(
					'mousedown',
					closeWeek16OpponentsModalOnClickOutside
				);
			} else {
				document.removeEventListener(
					'mousedown',
					closeWeek16OpponentsModalOnClickOutside
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					closeWeek16OpponentsModalOnClickOutside
				);
			};
		},
		[
			isPlayerWeek16OpponentsModalOpen
		]
	);

	useEffect(
		() => {
			if (isPlayerWeek17OpponentsModalOpen) {
				document.addEventListener(
					'mousedown',
					closeWeek17OpponentsModalOnClickOutside
				);
			} else {
				document.removeEventListener(
					'mousedown',
					closeWeek17OpponentsModalOnClickOutside
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					closeWeek17OpponentsModalOnClickOutside
				);
			};
		},
		[
			isPlayerWeek17OpponentsModalOpen
		]
	);

	return (
		<div className="relative flex flex-col lg:flex-row justify-between items-center mb-2 border-gray-200 border pt-5 rounded-xl my-6 shadow-sm bg-white">
			{/* Close Button in Top-Right Corner, only show if roster isn't auto-synched */}
			{!roster_url && (
				<button
					className="absolute top-3 right-3 p-2 rounded-full hover:bg-gray-200 transition duration-300"
					onClick={() => confirmDeleteRosterPlayer(player)}
				>
					<svg
						className="h-5 w-5"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M6 18L18 6M6 6l12 12"
						/>
					</svg>
				</button>
			)}
			<div>
				<div className="flex flex-col md:flex-row">
					{/* First Div */}
					<div className="flex items-center md:w-4/12 px-6 lg:px-10">
						<div className="relative mr-4 flex-shrink-0">
							<img
								src={`${player.image_url}?w=75&h=75`}
								alt={`${player.first_name} ${player.last_name}`}
								style={{
									width: '75px',
									height: '75px',
									objectFit: 'cover',
									border: '1px solid #ddd',
									borderRadius: '50%',
									backgroundColor: 'white'
								}}
							/>
							<div className="absolute top-2 left-12 transform translate-x-1/2 -translate-y-1/2">
								<StatusWidget
									status={player.status}
									description={player.injury_description}
									hideLabel={true}
									is75pxThumbnail={true}
								/>
							</div>
						</div>
						<div>
							<div
								className="text-2xl text-gray-700 hover:underline cursor-pointer"
								onClick={() => handleRosterPlayerClick(player)}
							>
								{player.first_name} {player.last_name}
							</div>
							<div>
								<div
									className="text-lg text-blue-500 hover:underline cursor-pointer"
									onClick={() => handleTeamClick(player.team)}
								>
									{player.team.name}
								</div>
							</div>
							<i
								className="fa-solid fa-note-sticky text-gray-500 hover:text-blue-500 rounded mr-3 cursor-pointer"
								alt="Manager Player Notes"
								onClick={() => handleOpenRosterPlayerNote(player)}
							/>
						</div>
					</div>

					{/* Second Div */}
					<div className="flex flex-wrap items-start text-base text-gray-500 mt-6 md:mt-0 md:w-8/12 px-6 lg:px-10">
						{/* Additional details */}
						<div className="flex items-center mr-6 mb-2">
							<span className="font-bold uppercase">HT/WT</span>
							<span className="ml-2">
								{convertInchesToFeetAndInches(player.height)}, {player.weight}{' '}
								lbs
							</span>
						</div>
						<div className="flex items-center mr-6 mb-2">
							<span className="font-bold uppercase">Age</span>
							<span className="ml-2">{player.age}</span>
						</div>
						{player.college && (
							<div className="flex items-center mr-6 mb-2">
								<span className="font-bold uppercase">College</span>
								<span className="ml-2">{player.college}</span>
							</div>
						)}
						<div className="flex items-center mr-6 mb-2">
							<span className="font-bold uppercase">Draft Pick</span>
							<span className="ml-2">
								{player.draft_pick ? (
									`${player.draft_pick === '1.1'
										? '1.10'
										: player.draft_pick} (${player.draft_year})`
								) : (
									'Undrafted'
								)}
							</span>
						</div>
						{player.draft_year && (
							<div className="flex items-center mr-6 mb-2">
								<span className="font-bold uppercase">EXP</span>
								<span className="ml-2 flex items-center">
									{`${new Date().getFullYear() - player.draft_year} yrs`}
									{(new Date().getFullYear() - player.draft_year === 0 ||
										player.is_rookie) && <RookieTag />}
									{player.is_hof && <HofTag />}
									{player.is_contract_year && <ContractYearTag />}
								</span>
							</div>
						)}
						{player.contract_details && (
							<div className="flex items-center mr-6 mb-2">
								<span className="font-bold uppercase">Contract</span>
								<Link
									to={`${player.contract_url}`}
									target="_blank"
									rel="noopener noreferrer"
									className="text-blue-500 hover:underline"
								>
									<span className="ml-2">
										{`${player.contract_details} (${player.contract_end_year} ${player.id ===
										59
											? 'RFA'
											: 'UFA'})`}
										<span className="hidden md:contents">
											<i className="fa-solid fa-arrow-up-right-from-square ml-2" />
										</span>
									</span>
								</Link>
							</div>
						)}
						{player &&
						player.week_15_opponent &&
						player.week_15_opponent.opponent_team &&
						player.week_15_opponent.opponent_team.location &&
						player.week_15_opponent.opponent_team.name &&
						player.week_16_opponent &&
						player.week_16_opponent.opponent_team &&
						player.week_16_opponent.opponent_team.location &&
						player.week_16_opponent.opponent_team.name &&
						player.week_17_opponent &&
						player.week_17_opponent.opponent_team &&
						player.week_17_opponent.opponent_team.location &&
						player.week_17_opponent.opponent_team.name && (
							<div className="flex items-center mr-6 mb-2">
								<span className="font-bold uppercase">WKS 15, 16, 17</span>
								<span
									className="text-blue-500 ml-2 cursor-pointer hover:underline"
									onClick={() => handleOpenPlayerWeek15OpponentsModal()}
								>
									{getPlayerTeamCityAbbreviation(
										player.week_15_opponent.opponent_team.location,
										player.week_15_opponent.opponent_team.name
									)}
								</span>,
								<span
									className="text-blue-500 ml-2 cursor-pointer hover:underline"
									onClick={() => handleOpenPlayerWeek16OpponentsModal()}
								>
									{getPlayerTeamCityAbbreviation(
										player.week_16_opponent.opponent_team.location,
										player.week_16_opponent.opponent_team.name
									)}
								</span>,
								<span
									className="text-blue-500 ml-2 cursor-pointer hover:underline"
									onClick={() => handleOpenPlayerWeek17OpponentsModal()}
								>
									{getPlayerTeamCityAbbreviation(
										player.week_17_opponent.opponent_team.location,
										player.week_17_opponent.opponent_team.name
									)}
								</span>
							</div>
						)}
						{player.bye_week && (
							<div className="flex items-center mr-6 mb-2">
								<span className="font-bold uppercase">Bye Week</span>
								<span className="ml-2">{player.bye_week}</span>
							</div>
						)}
					</div>
				</div>

				{/* Show More Button */}
				<div
					className="text-center cursor-pointer hover:underline px-4 pb-2"
					onClick={toggleExpanded}
				>
					{isExpanded ? (
						<i className="fa-solid fa-chevron-up rounded-full hover:bg-gray-200 transition-all duration-300 ease-in-out p-2" />
					) : (
						<i className="fa-solid fa-chevron-down rounded-full hover:bg-gray-200 transition-all duration-300 ease-in-out p-2" />
					)}
				</div>

				{/* Conditional rendering of the additional section */}
				{isExpanded && (
					<div className="flex flex-wrap justify-center items-center w-full md:flex-row space-y-6 lg:space-y-0 md:space-x-4 2xl:space-x-20 mt-4 lg:mt-8">
						<CircularBarChart
							loading={loading}
							title="Overall Score™"
							chartSize="sm"
							value={player.fantasy_fitness_grade || 0}
							maxPossible={10}
							infoIconText="This is the player's Fantasy Fitness Score™."
							rank={player.fantasy_fitness_grade_position_rank || 999}
							abbr_position={getPositionAbbreviation(player.position || '')}
							gradeRanks={gradeRanks.fantasy_fitness_grades || []}
							handleRankClick={() =>
								handleRankClick(
									`fantasy_fitness_score_${player.id}_${player.last_name}`,
									player.position
								)}
							isRankVisible={
								visibleRankModal ===
								`fantasy_fitness_score_${player.id}_${player.last_name}`
							}
							rankModalRef={rankModalRef}
							currentPlayerRef={currentPlayerRef}
							handlePlayerClick={handlePlayerClick}
							currentPlayerId={player.id || 0}
							hasLabel={true}
							hasRankModalEnabled={true}
						/>
						<CircularBarChart
							loading={loading}
							title="Talent Score™"
							chartSize="xs"
							value={player.talent_grade || 0}
							maxPossible={10}
							infoIconText="This is the player's Fantasy Fitness Talent Score™."
							rank={player.talent_grade_position_rank || 999}
							abbr_position={getPositionAbbreviation(player.position || '')}
							gradeRanks={gradeRanks.talent_grades || []}
							handleRankClick={() =>
								handleRankClick(
									`fantasy_fitness_talent_score_${player.id}_${player.last_name}`,
									player.position
								)}
							isRankVisible={
								visibleRankModal ===
								`fantasy_fitness_talent_score_${player.id}_${player.last_name}`
							}
							rankModalRef={rankModalRef}
							currentPlayerRef={currentPlayerRef}
							handlePlayerClick={handlePlayerClick}
							currentPlayerId={player.id || 0}
							hasLabel={true}
							hasRankModalEnabled={true}
						/>
						<CircularBarChart
							loading={loading}
							title="Team Score™"
							chartSize="xs"
							value={player.team_grade || 0}
							maxPossible={10}
							infoIconText="This is the player's Fantasy Fitness Team Score™."
							rank={player.team_grade_position_rank || 999}
							abbr_position={getPositionAbbreviation(player.position || '')}
							gradeRanks={gradeRanks.team_grades || []}
							handleRankClick={() =>
								handleRankClick(
									`fantasy_fitness_team_score_${player.id}_${player.last_name}`,
									player.position
								)}
							isRankVisible={
								visibleRankModal ===
								`fantasy_fitness_team_score_${player.id}_${player.last_name}`
							}
							rankModalRef={rankModalRef}
							currentPlayerRef={currentPlayerRef}
							handlePlayerClick={handlePlayerClick}
							currentPlayerId={player.id || 0}
							hasLabel={true}
							hasRankModalEnabled={true}
						/>
					</div>
				)}

				{/* Week 15 Opponents Modal */}
				{isPlayerWeek15OpponentsModalOpen && (
					<Week15OpponentsModal
						player={player}
						isPlayerWeek15OpponentsModalOpen={isPlayerWeek15OpponentsModalOpen}
						closeWeek15OpponentsModal={closeWeek15OpponentsModal}
						modalRef={modalRef}
					/>
				)}

				{/* Week 16 Opponents Modal */}
				{isPlayerWeek16OpponentsModalOpen && (
					<Week16OpponentsModal
						player={player}
						isPlayerWeek16OpponentsModalOpen={isPlayerWeek16OpponentsModalOpen}
						closeWeek16OpponentsModal={closeWeek16OpponentsModal}
						modalRef={modalRef}
					/>
				)}

				{/* Week 17 Opponents Modal */}
				{isPlayerWeek17OpponentsModalOpen && (
					<Week17OpponentsModal
						player={player}
						isPlayerWeek17OpponentsModalOpen={isPlayerWeek17OpponentsModalOpen}
						closeWeek17OpponentsModal={closeWeek17OpponentsModal}
						modalRef={modalRef}
					/>
				)}
			</div>
		</div>
	);
};

export default RosterPlayerCard;
