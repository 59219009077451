import React from 'react';
import ReactApexChart from 'react-apexcharts';

const PaceOfPlayChart = ({ teamData, leagueData, teamName }) => {
	// Extract the last word from the team name
	const teamNameLastWord = teamName.split(' ').pop();

	// Helper function to convert time string (e.g., "32:33") to total minutes
	const timeStringToMinutes = (timeString) => {
		if (!timeString) return 0;
		const [
			minutes,
			seconds
		] = timeString.split(':').map(Number);
		return minutes + seconds / 60;
	};

	// Calculate plays per minute of possession
	const calculatePlaysPerMinute = (offensivePlays, timeOfPossession) => {
		if (!timeOfPossession || !offensivePlays || timeOfPossession === 0) {
			return 0;
		}
		const timeInMinutes = timeStringToMinutes(timeOfPossession);
		return (offensivePlays / timeInMinutes).toFixed(2); // Plays per minute
	};

	const teamPlaysPerMinute = calculatePlaysPerMinute(
		teamData && teamData.avg_offensive_plays,
		teamData && teamData.avg_time_of_possession
	);
	const leaguePlaysPerMinute = calculatePlaysPerMinute(
		leagueData && leagueData.avg_offensive_plays,
		leagueData && leagueData.avg_time_of_possession
	);

	// Chart configuration
	const chartOptions = {
		series: [
			{
				name: `${teamNameLastWord}`,
				data: [
					parseFloat(teamPlaysPerMinute)
				] // Team's plays per minute
			},
			{
				name: 'League Avg',
				data: [
					parseFloat(leaguePlaysPerMinute)
				] // League's average plays per minute
			}
		],
		options: {
			chart: {
				type: 'bar',
				height: 350
			},
			plotOptions: {
				bar: {
					columnWidth: '55%',
					distributed: false // Set to false to group bars by category
				}
			},
			colors: [
				'#2980B9',
				'#E67E22'
			],
			stroke: {
				width: 1,
				colors: [
					'#fff'
				]
			},
			title: {
				text: 'Pace of Play',
				align: 'center',
				style: { fontSize: '14px' }
			},
			xaxis: {
				categories: [
					'Plays per Minute of Possession'
				]
			},
			yaxis: {
				title: {
					text: 'Plays per Minute'
				},
				labels: {
					formatter: (val) => val.toFixed(2) // Show values with 2 decimals
				}
			},
			tooltip: {
				y: {
					formatter: (val) => `${val.toFixed(2)} plays`
				}
			},
			legend: {
				position: 'top',
				horizontalAlign: 'center'
			}
		}
	};

	return (
		<div className="bg-white p-4 rounded-lg shadow-lg border border-gray-300">
			<ReactApexChart
				options={chartOptions.options}
				series={chartOptions.series}
				type="bar"
				height={250}
			/>
		</div>
	);
};

export default PaceOfPlayChart;
