import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TeamPortraitCard from '../TeamPortraitCard/TeamPortraitCard';
import FixedTeamBanner from '../FixedTeamBanner/FixedTeamBanner';
import CoachesCard from '../CoachesCard/CoachesCard';
import TeamPlayersStatsTable from '../TeamPlayersStatsTable/TeamPlayersStatsTable';
import TeamMatchupsTable from '../TeamMatchupsTable/TeamMatchupsTable';
import FilterToggle from '../../Filters/FilterToggle';
import BackToTopButton from '../../Buttons/BackToTopButton/BackToTopButton';
import TeamMetricsCard from '../TeamMetricsCard/TeamMetricsCard';

export default function TeamProfileCard(props) {
	const { id: paramId } = useParams();
	const id = (props.team && props.team.id) || paramId;
	const [
		team,
		setTeam
	] = useState([]);
	const [
		players,
		setPlayers
	] = useState([]);
	const [
		metrics,
		setMetrics
	] = useState([]);
	const [
		matchups,
		setMatchups
	] = useState([]);
	const [
		showBackToTop,
		setShowBackToTop
	] = useState(false);
	const [
		temperature,
		setTemperature
	] = useState(null);
	const [
		isHeatmapEnabled,
		setIsHeatmapEnabled
	] = useState(false);
	const [
		isHeatmapAgeCategoryEnabled,
		setIsHeatmapAgeCategoryEnabled
	] = useState(true);
	const [
		isHeatmap40TimeCategoryEnabled,
		setIsHeatmap40TimeCategoryEnabled
	] = useState(true);
	const [
		isHeatmapFFScoresCategoryEnabled,
		setIsHeatmapFFScoresCategoryEnabled
	] = useState(true);
	const [
		isLoading,
		setIsLoading
	] = useState(false);

	const handleHeatmapToggle = () => {
		setIsHeatmapEnabled((prevHeatmapState) => !prevHeatmapState);
	};

	const handleOptionChange = (key) => {
		switch (key) {
			case 'age':
				setIsHeatmapAgeCategoryEnabled(!isHeatmapAgeCategoryEnabled);
				break;
			case 'time':
				setIsHeatmap40TimeCategoryEnabled(!isHeatmap40TimeCategoryEnabled);
				break;
			case 'scores':
				setIsHeatmapFFScoresCategoryEnabled(!isHeatmapFFScoresCategoryEnabled);
				break;
			default:
				break;
		}
	};

	useEffect(
		() => {
			const allOptionsDisabled =
				!isHeatmapAgeCategoryEnabled &&
				!isHeatmap40TimeCategoryEnabled &&
				!isHeatmapFFScoresCategoryEnabled;
			if (allOptionsDisabled && isHeatmapEnabled) {
				setIsHeatmapEnabled(false);
				setIsHeatmapAgeCategoryEnabled(true);
				setIsHeatmap40TimeCategoryEnabled(true);
				setIsHeatmapFFScoresCategoryEnabled(true);
			}
		},
		[
			isHeatmapAgeCategoryEnabled,
			isHeatmap40TimeCategoryEnabled,
			isHeatmapFFScoresCategoryEnabled
		]
	);

	const heatmapOptions = [
		{ key: 'age', label: 'Age', value: isHeatmapAgeCategoryEnabled },
		{ key: 'time', label: '40 Time', value: isHeatmap40TimeCategoryEnabled },
		{
			key: 'scores',
			label: 'FF Scores',
			value: isHeatmapFFScoresCategoryEnabled
		}
	];

	const fetchTeamData = () => {
		setIsLoading(true);

		fetch(props.get_team_endpoint.replace('id', id))
			.then((response) => response.json())
			.then((result) => {
				setTeam(result.data.team);
				setPlayers(result.data.players);
				setMatchups(result.data.matchups);
				setMetrics(result.data.metrics);
				setIsLoading(false);
			})
			.catch((error) => {
				console.error('Error fetching team data:', error);
				setIsLoading(false);
			});
	};

	useEffect(
		() => {
			window.scrollTo(0, 0);

			fetchTeamData();
		},
		[
			id
		]
	);

	const fetchTemperature = (location) => {
		let city = location.split(',')[0].trim();
		if (city === 'Tampa Bay') {
			city = 'Tampa';
		}
		fetch(
			`https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${'336d6d9ed7689fcfacbc7a9cd10f4250'}`
		)
			.then((response) => response.json())
			.then((data) => {
				if (
					data.main &&
					data.main.temp &&
					data.weather &&
					data.weather.length > 0
				) {
					// Convert temperature to Fahrenheit
					const tempInFahrenheit = Math.round(
						(data.main.temp - 273.15) * 9 / 5 + 32
					);
					// Get weather description
					const weatherDescription = data.weather[0].main;
					setTemperature({
						temp: tempInFahrenheit,
						weather: weatherDescription
					});
				}
			})
			.catch((error) => {
				console.error('Error fetching temperature:', error);
			});
	};

	useEffect(
		() => {
			if (team.location) {
				fetchTemperature(team.location);
			}
		},
		[
			team.location
		]
	);

	const [
		scrolledPastBanner,
		setScrolledPastBanner
	] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			const bannerHeight = document.querySelector('.banner-section')
				.offsetHeight;
			const scrolled = window.scrollY > bannerHeight;
			setScrolledPastBanner(scrolled);
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 420) {
				setShowBackToTop(true);
			} else {
				setShowBackToTop(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	if (!team) {
		return (
			<div>
				<LoadingSpinner />
			</div>
		);
	}

	return (
		<div>
			{/* Team Portrait Card */}
			<div className="banner-section">
				<TeamPortraitCard
					isLoading={isLoading}
					team={team}
					temperature={temperature}
					get_team_endpoint={props.get_team_endpoint}
					get_teams_endpoint={props.get_teams_endpoint}
					create_checkout_session_endpoint={
						props.create_checkout_session_endpoint
					}
					stripe_public_key={props.stripe_public_key}
					stripe_product_essential_id={props.stripe_product_essential_id}
					stripe_product_advanced_id={props.stripe_product_advanced_id}
					stripe_product_ultimate_id={props.stripe_product_ultimate_id}
				/>
			</div>

			{/* Fixed Team Banner  - Displays after scrolling past the Team Portrait Card Section */}
			{scrolledPastBanner && (
				<FixedTeamBanner
					scrolledPastBanner={scrolledPastBanner}
					team={team}
					temperature={temperature}
				/>
			)}

			{/* Team Metrics Card */}
			<TeamMetricsCard team={team} metrics={metrics} />

			{/* Players Card */}
			<div className="text-3xl font-bold mb-3 mt-14">
				<h4>Players</h4>
			</div>
			<div className="relative hidden md:block">
				{/* Filter components */}
				<div
					style={{
						position: 'absolute',
						top: -58,
						right: -12
					}}
				>
					<FilterToggle
						label="Heatmap"
						icon={<i className="fa-solid fa-map" />}
						isChecked={isHeatmapEnabled}
						onToggle={handleHeatmapToggle}
						options={heatmapOptions}
						onOptionChange={handleOptionChange}
					/>
				</div>
			</div>
			<TeamPlayersStatsTable
				players={players}
				isHeatmapEnabled={isHeatmapEnabled}
				isHeatmapAgeCategoryEnabled={isHeatmapAgeCategoryEnabled}
				isHeatmap40TimeCategoryEnabled={isHeatmap40TimeCategoryEnabled}
				isHeatmapFFScoresCategoryEnabled={isHeatmapFFScoresCategoryEnabled}
			/>

			{/* Matchups Card */}
			{matchups &&
			matchups.length > 0 && (
				<div className="text-3xl font-bold mb-3 mt-14">
					<h4>Matchups</h4>
				</div>
			)}
			<TeamMatchupsTable matchups={matchups} team={team} />

			{/* Coaches Card */}
			<div className="text-3xl font-bold mb-3 mt-14">
				<h4>Coaches</h4>
			</div>
			<CoachesCard team={team} />

			{/* Back to Top Widget */}
			{showBackToTop && <BackToTopButton />}
		</div>
	);
}
