import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import PageTitle from '../FormElements/PageTitle';
import GlobalErrorsBox from '../ErrorComponents/GlobalErrorBox/GlobalErrorsBox';
import FormSection from '../FormElements/FormSection';
import FormSubmit from '../FormElements/FormSubmit';
import Label from '../FormElements/Label';
import EmailInput from '../FormElements/EmailInput';
import PasswordInput from '../FormElements/PasswordInput';
import { useAuth } from '../../contexts/AuthContext';
import PasswordResetHelpModal from '../Sessions/PasswordResetHelpModal/PasswordResetHelpModal';

const SessionsSignInForm = () => {
	const { signIn } = useAuth();
	const modalRef = useRef();

	const [
		emailAddress,
		setEmailAddress
	] = useState('');
	const [
		password,
		setPassword
	] = useState('');
	const [
		errors,
		setErrors
	] = useState([]);
	const [
		isPasswordResetHelpModalOpen,
		setIsPasswordResetHelpModalOpen
	] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			const credentials = {
				email: emailAddress.toLowerCase(),
				password: password
			};
			const errorData = await signIn(credentials);
			if (errorData) {
				setErrors(
					errorData.errors || [
						'An error occurred during login.'
					]
				);
			}
		} catch (error) {
			// Handle network errors or other exceptions
			console.error('Error:', error);
			setErrors([
				'An error occurred during login.'
			]);

			window.location.reload();
		}
	};

	const closeModalOnClickOutside = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			setIsPasswordResetHelpModalOpen(false);
		}
	};

	useEffect(
		() => {
			if (isPasswordResetHelpModalOpen) {
				document.addEventListener('mousedown', closeModalOnClickOutside);
			} else {
				document.removeEventListener('mousedown', closeModalOnClickOutside);
			}
			return () => {
				document.removeEventListener('mousedown', closeModalOnClickOutside);
			};
		},
		[
			isPasswordResetHelpModalOpen
		]
	);

	return (
		<div className="lg:w-1/2 py-4 mx-auto">
			<div className="justify-self-center w-full">
				<PageTitle title="Sign in" alignment="center" />
				<GlobalErrorsBox globalErrors={errors} />
				<FormSection>
					<Label htmlFor="emailAddress">Email *</Label>
					<EmailInput
						id="emailAddress"
						name="emailAddress"
						value={emailAddress}
						onChange={(e) => setEmailAddress(e.target.value)}
					/>
					<Label htmlFor="password">Password *</Label>
					<PasswordInput
						id="password"
						name="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
					<div className="grid">
						<div className="mt-4">
							<FormSubmit onSubmit={handleSubmit} label="Sign in" />
						</div>
					</div>
				</FormSection>

				<p className="text-center mt-2">
					Don't have an account?{' '}
					<Link to="/signup" className="text-blue-500 hover:underline">
						Sign up
					</Link>
					.
				</p>
				<p className="text-center mt-2">
					Forgot your password?{' '}
					<Link to="/password-reset" className="text-blue-500 hover:underline">
						Reset it
					</Link>
					.
				</p>
			</div>

			{/* Render the modal */}
			<PasswordResetHelpModal
				isOpen={isPasswordResetHelpModalOpen}
				onClose={() => setIsPasswordResetHelpModalOpen(false)}
				modalRef={modalRef}
			/>
		</div>
	);
};

export default SessionsSignInForm;
