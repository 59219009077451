import React from 'react';
import PageTitle from '../../FormElements/PageTitle';
import RostersIndexCard from '../../Rosters/RostersIndexCard/RostersIndexCard';

export default function RostersIndexPage({
	get_rosters_by_user_endpoint,
	get_roster_by_user_endpoint,
	create_roster_by_user_endpoint,
	update_roster_by_user_endpoint,
	delete_roster_by_user_endpoint,
	get_rosters_players_exposures_by_user_endpoint,
	get_rosters_players_injuries_by_user_endpoint,
	create_checkout_session_endpoint,
	stripe_public_key,
	stripe_product_essential_id,
	stripe_product_advanced_id,
	stripe_product_ultimate_id
}) {
	if (!get_rosters_by_user_endpoint) {
		return (
			<div>
				Error: Missing endpoints required for RostersIndexPage component
			</div>
		);
	}

	return (
		<div className="container p-4 pt-4 lg:pt-14 md:px-20">
			<div className="mt-4">
				<PageTitle title="Rosters" />
				<div className="grid grid-cols-1">
					<RostersIndexCard
						get_rosters_by_user_endpoint={get_rosters_by_user_endpoint}
						get_roster_by_user_endpoint={get_roster_by_user_endpoint}
						create_roster_by_user_endpoint={create_roster_by_user_endpoint}
						update_roster_by_user_endpoint={update_roster_by_user_endpoint}
						delete_roster_by_user_endpoint={delete_roster_by_user_endpoint}
						get_rosters_players_exposures_by_user_endpoint={
							get_rosters_players_exposures_by_user_endpoint
						}
						get_rosters_players_injuries_by_user_endpoint={
							get_rosters_players_injuries_by_user_endpoint
						}
						create_checkout_session_endpoint={create_checkout_session_endpoint}
						stripe_public_key={stripe_public_key}
						stripe_product_essential_id={stripe_product_essential_id}
						stripe_product_advanced_id={stripe_product_advanced_id}
						stripe_product_ultimate_id={stripe_product_ultimate_id}
					/>
				</div>
			</div>
		</div>
	);
}
