import React, { useEffect, useRef } from 'react';
import StatusWidget from '../../StatusWidget/StatusWidget';
import getPositionAbbreviation from '../../../utils/getPositionAbbreviation';

const RosterPlayerNotesModalDesktop = ({ isOpen, onClose, rosterNotes }) => {
	const modalRef = useRef(null);

	const handleClickOutsideModal = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			onClose();
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutsideModal);
		return () => {
			document.removeEventListener('mousedown', handleClickOutsideModal);
		};
	}, []);

	if (!isOpen) return null;

	return (
		<div className="hidden lg:contents">
			<div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
				<div
					className="relative bg-white rounded-xl border border-gray-200 text-gray-800 p-6 w-3/4"
					style={{ maxHeight: '90vh' }}
					ref={modalRef}
				>
					<h2 className="text-xl font-bold mb-4">Roster Player Notes</h2>
					<button
						className="absolute top-3 right-3 p-2 rounded-full hover:bg-gray-200 transition duration-300"
						onClick={onClose}
					>
						<svg
							className="h-5 w-5"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M6 18L18 6M6 6l12 12"
							/>
						</svg>
					</button>
					<div
						className="overflow-y-auto rounded-md border border-gray-200 overflow-hidden"
						style={{ maxHeight: 'calc(90vh - 100px)' }}
					>
						<table className="min-w-full bg-white text-gray-800">
							<thead className="bg-gray-100 sticky top-0 z-10">
								<tr>
									<th className="py-3 px-4 border-b text-left font-semibold">
										Position
									</th>
									<th
										className="py-3 px-4 border-b text-left font-semibold"
										style={{ minWidth: '240px' }}
									>
										Player
									</th>
									<th className="py-3 px-4 border-b text-left font-semibold">
										Notes
									</th>
									<th
										className="py-3 px-4 border-b text-left font-semibold"
										style={{ minWidth: '150px' }}
									>
										Last Updated
									</th>
								</tr>
							</thead>
							{rosterNotes.length === 0 ? (
								<tbody>
									<tr>
										<td colSpan="4" className="py-4 px-4 border-b text-center">
											No roster player notes exist at this time.
										</td>
									</tr>
								</tbody>
							) : (
								<tbody>
									{rosterNotes.map(
										(note, index) =>
											note.note.content && (
												<tr key={index} className="hover:bg-gray-50">
													<td
														className={`py-2 px-4 ${index ===
														rosterNotes.length - 1
															? ''
															: 'border-b'} text-left`}
													>
														{getPositionAbbreviation(note.position)}
													</td>
													<td
														className={`py-2 px-4 ${index ===
														rosterNotes.length - 1
															? ''
															: 'border-b'} text-left`}
													>
														<div className="flex items-center">
															<div
																className="relative mr-2"
																style={{
																	width: '50px',
																	height: '50px',
																	overflow: 'hidden'
																}}
															>
																<img
																	src={`${note.image_url}?w=50&h=50`}
																	alt={`${note.first_name} ${note.last_name}`}
																	style={{
																		width: '50px',
																		height: '50px',
																		minWidth: '50px',
																		minHeight: '50px',
																		objectFit: 'cover',
																		border: '1px solid #ddd',
																		borderRadius: '50%'
																	}}
																/>
																{note.status && (
																	<div className="absolute top-0 right-0">
																		<StatusWidget
																			status={note.status}
																			description={
																				note.injury_description || null
																			}
																			hideLabel={true}
																			is50pXThumbnail={true}
																		/>
																	</div>
																)}
															</div>
															<span className="text-gray-800">{`${note.first_name} ${note.last_name}`}</span>
														</div>
													</td>
													<td
														className={`py-2 px-4 ${index ===
														rosterNotes.length - 1
															? ''
															: 'border-b'} text-left`}
													>
														{note.note.content}
													</td>
													<td
														className={`py-2 px-4 ${index ===
														rosterNotes.length - 1
															? ''
															: 'border-b'} text-left`}
													>
														{new Date(
															note.note.updated_at
														).toLocaleDateString('en-US', {
															year: '2-digit',
															month: '2-digit',
															day: '2-digit'
														})}
													</td>
												</tr>
											)
									)}
								</tbody>
							)}
						</table>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RosterPlayerNotesModalDesktop;
