import React, { forwardRef } from 'react';

const FilterBySearchInput = forwardRef(
	(
		{ value, label, placeholder, onChange, onEnter, isDarkMode, isOnHeader },
		ref
	) => {
		const handleInputChange = (event) => {
			onChange(event);
		};

		const handleKeyDown = (event) => {
			if (event.key === 'Enter') {
				onEnter();
			}
		};

		return (
			<div className="flex items-center my-2 lg:my-0 mr-2 md:mr-4">
				{label && <label className="text-sm sm:text-base mr-2">{label}</label>}
				<input
					className={`p-1.5 md:p-2 border ${isDarkMode
						? 'border-gray-500'
						: 'border-gray-300'} rounded-md text-xs sm:text-base ${isOnHeader
						? ' w-24 sm:w-36 md:w-48 lg:w-52'
						: ' w-32 sm:w-36 md:w-48 lg:w-52'}`}
					type="text"
					placeholder={placeholder}
					value={value}
					onChange={handleInputChange}
					onKeyDown={handleKeyDown}
					ref={ref}
				/>
			</div>
		);
	}
);

export default FilterBySearchInput;
