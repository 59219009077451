import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const TopHeadlinesCard = ({ get_articles_endpoint }) => {
	const [
		topArticles,
		setTopArticles
	] = useState([]);
	const [
		categories,
		setCategories
	] = useState([]);

	useEffect(
		() => {
			let queryParams = '';

			if (categories.length) {
				queryParams += `&categories=${categories.join(',')}`;
			}

			const fetchData = async () => {
				try {
					const url = `${get_articles_endpoint}?article_type=News${queryParams}`;
					const response = await fetch(url);
					if (!response.ok) {
						throw new Error('Failed to fetch articles');
					}
					const result = await response.json();
					setTopArticles(result.data.slice(0, 8));
				} catch (error) {
					console.error(error);
				}
			};

			fetchData();
		},
		[
			get_articles_endpoint,
			categories
		]
	);

	return (
		<div className="bg-white border border-gray-200 shadow-lg rounded-xl">
			<div className="mx-4 py-3 border-b border-gray-200">
				<h2 className="text-lg font-bold text-gray-800">
					<i className="fa-solid fa-newspaper text-lg mr-3" />
					<Link to="/news" className="hover:underline">
						Top Headlines
					</Link>
				</h2>
				{/* Filter by Topic */}
				<div className="mt-3">
					<div className="flex flex-wrap items-center space-x-1 md:space-x-1">
						<div className="flex flex-wrap space-x-1 xl:space-x-0.5">
							{[
								'News',
								'Rankings',
								'Dynasty',
								'Best Ball',
								'DFS'
							].map((category) => (
								<button
									key={category}
									onClick={() => {
										setCategories((prevCategories) => {
											if (prevCategories.includes(category)) {
												// Remove the category if it is already selected
												return prevCategories.filter((t) => t !== category);
											} else {
												// Add the category if it is not already selected
												return [
													...prevCategories,
													category
												];
											}
										});
									}}
									className={`text-xs bg-white px-2 py-1 rounded-full border ${categories.includes(
										category
									)
										? 'bg-blue-500 text-white'
										: 'border-gray-400 text-gray-700'}`}
									style={{
										marginTop: '0.25rem',
										marginBottom: '0.25rem'
									}}
								>
									{category}
								</button>
							))}
						</div>
					</div>
				</div>
			</div>
			{topArticles.length === 0 ? (
				<div className="text-center text-sm text-gray-800 py-3">
					No articles found.
				</div>
			) : (
				<ul className="divide-y divide-gray-200 px-4">
					{topArticles.map((article, index) => (
						<li key={index} className="py-3 cursor-pointer flex items-center">
							<a
								href={article.url}
								className="block text-sm font-medium text-gray-800 truncate flex-1 mr-1 hover:underline"
								title={article.title}
								target="_blank"
								rel="noopener noreferrer"
							>
								{article.title}
							</a>
							<span className="text-xs text-gray-500 text-right whitespace-nowrap">
								{article.publisher}
							</span>
						</li>
					))}
				</ul>
			)}
			<div className="mx-4 py-3 border-t border-gray-200 text-center">
				<Link to="/news" className="text-sm text-blue-500 hover:underline">
					See all news →
				</Link>
			</div>
		</div>
	);
};

export default TopHeadlinesCard;
