import React from 'react';

const CollegeStatsButtons = ({
	selectedCollegeStatsType,
	handleCollegeBasicStatsClick,
	handleCollegeAdvancedStatsClick
}) => {
	return (
		<div className="mb-0 lg:mb-4 text-xs md:text-base flex flex-col sm:flex-row items-start">
			{/* mb-0 because stacked on pro stats buttons on mobile */}
			{/* College Basic Stats Button*/}
			<button
				className={`mb-2 mx-0 mr-2 p-2 border border-gray-300 rounded-md whitespace-nowrap ${selectedCollegeStatsType ===
				'basic'
					? 'bg-gray-800 text-white'
					: 'bg-white text-black'}`}
				onClick={handleCollegeBasicStatsClick}
			>
				College Basic Stats
			</button>
			{/* College Advanced Stats Button */}
			<button
				className={`mx-0 mb-2 sm:mb-0 p-2 mr-2 border border-gray-300 rounded-md whitespace-nowrap ${selectedCollegeStatsType ===
				'advanced'
					? 'bg-gray-800 text-white'
					: 'bg-white text-black'}`}
				onClick={handleCollegeAdvancedStatsClick}
			>
				College Advanced Stats
			</button>
		</div>
	);
};

export default CollegeStatsButtons;
