import React from 'react';
import ReactApexChart from 'react-apexcharts';

const OffensiveProductionChart = ({ teamData, leagueData, teamName }) => {
	console.log('teamData:', teamData);
	console.log('leagueData:', leagueData);
	console.log('teamName:', teamName);

	// Extract the last word from the team name
	const teamNameLastWord = teamName.split(' ').pop();

	// Ensure data exists and provide fallbacks, converting values to numbers
	const teamPassingYards =
		teamData && teamData.avg_passing_yards
			? parseFloat(teamData.avg_passing_yards)
			: 0;
	const teamRushingYards =
		teamData && teamData.avg_rushing_yards
			? parseFloat(teamData.avg_rushing_yards)
			: 0;
	const leaguePassingYards =
		leagueData && leagueData.avg_passing_yards
			? parseFloat(leagueData.avg_passing_yards)
			: 0;
	const leagueRushingYards =
		leagueData && leagueData.avg_rushing_yards
			? parseFloat(leagueData.avg_rushing_yards)
			: 0;

	// Chart configuration
	const chartOptions = {
		series: [
			{
				name: 'Passing Yards',
				data: [
					teamPassingYards,
					leaguePassingYards
				]
			},
			{
				name: 'Rushing Yards',
				data: [
					teamRushingYards,
					leagueRushingYards
				]
			}
		],
		options: {
			chart: {
				type: 'bar',
				stacked: true,
				height: 250,
			},
			plotOptions: {
				bar: {
					horizontal: true,
					barHeight: '55%'
				}
			},
			colors: [
				'#9647B8',
				'#15997E'
			],
			stroke: {
				width: 1,
				colors: [
					'#fff'
				]
			},
			title: {
				text: 'Production',
				align: 'center',
				style: { fontSize: '14px' }
			},
			xaxis: {
				title: {
					text: 'Yards'
				},
				categories: [
					`${teamNameLastWord}`,
					'League Avg'
				],
				labels: {
					formatter: (val) => `${Math.round(val)}` // No decimal, rounding to nearest whole number
				}
			},
			tooltip: {
				y: {
					formatter: (val) => `${Math.round(val)}` // No decimal in the tooltip as well
				}
			},
			fill: {
				opacity: 1
			},
			legend: {
				position: 'top',
				horizontalAlign: 'center'
			}
		}
	};

	return (
		<div className="bg-white p-4 rounded-lg shadow-lg border border-gray-300">
			<ReactApexChart
				options={chartOptions.options}
				series={chartOptions.series}
				type="bar"
				height={250}
			/>
		</div>
	);
};

export default OffensiveProductionChart;
