import React, { Fragment } from 'react';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

const RostersPlayersExposuresTable = ({
	data = {},
	totalRosters,
	format,
	platform,
	scoringType,
	isLoading
}) => {
	if (isLoading) {
		return <LoadingSpinner />;
	}

	if (!data || Object.keys(data).length === 0) {
		return <div>No data available.</div>;
	}

	const formatPositionName = (position) => {
		return position.replace(/_/g, ' ').toUpperCase();
	};

	const renderSubsection = (position, players) => (
		<tbody key={position}>
			{/* Position Header */}
			<tr>
				<td
					colSpan="4"
					className="pl-8 py-3 bg-gray-200 text-left font-bold uppercase"
				>
					{formatPositionName(position)}
				</td>
			</tr>
			{/* Player Rows */}
			{Object.entries(players).map(([ playerName, playerData
			]) => (
				<tr key={playerName} className="hover:bg-gray-50">
					<td className="pl-8 py-3 text-left">
						{/*<img
							src={
								playerData.image_url ? (
									`${playerData.image_url}?w=50&h=50`
								) : (
									'Contact Support'
								)
							}
							alt={playerName ? `${playerName} Player Image` : 'Player Image'}
							style={{
								width: '50px',
								height: '50px',
								objectFit: 'cover',
								border: '1px solid #ddd',
								borderRadius: '50%',
								backgroundColor: 'white'
							}}
						/> */}
						{playerName}
					</td>
					<td className="pl-8 py-3 text-left">{playerData.percentage}%</td>
					<td className="pl-8 py-3 text-left">{playerData.roster_count}</td>
					<td className="pl-8 py-3 text-left">
						{playerData.rosters.map((roster) => roster.team_name).join(', ')}
					</td>
				</tr>
			))}
		</tbody>
	);

	return (
		<Fragment>
			{/* Updated Platform Information Section */}
			<div className="text-center text-base text-gray-500 py-2 flex flex-col sm:flex-row gap-4 sm:gap-8">
				<div className="flex items-center">
					<span className="font-bold uppercase">Format</span>
					<span className="ml-2">{format ? format : 'All'}</span>
				</div>
				<div className="flex items-center">
					<span className="font-bold uppercase">Platform</span>
					<span className="ml-2">{platform ? platform : 'All'}</span>
				</div>
				<div className="flex items-center">
					<span className="font-bold uppercase">Scoring</span>
					<span className="ml-2">{scoringType ? scoringType : 'All'}</span>
				</div>
				<div className="flex items-center">
					<span className="font-bold uppercase">Total Rosters</span>
					<span className="ml-2">
						{totalRosters ? totalRosters : 'Contact Support'}
					</span>
				</div>
			</div>

			{/* Table Section */}
			<div className="mt-4 border border-gray-300 rounded-lg overflow-hidden">
				<div className="overflow-y-auto" style={{ maxHeight: '73vh' }}>
					<table className="w-full">
						{/* Table Headers */}
						<thead>
							<tr className="bg-gray-100 sticky top-0 z-10">
								<th className="pl-8 py-3 border-b border-gray-300 text-left rounded-tl-lg">
									Player
								</th>
								<th className="pl-8 py-3 border-b border-gray-300 text-left">
									Exposure (%)
								</th>
								<th className="pl-8 py-3 border-b border-gray-300 text-left">
									Count
								</th>
								<th className="pl-8 py-3 border-b border-gray-300 text-left rounded-tr-lg">
									Rosters
								</th>
							</tr>
						</thead>
						{/* Table Body */}
						{Object.entries(data).map(([ position, players
						]) => renderSubsection(position, players))}
					</table>
				</div>
			</div>
		</Fragment>
	);
};

export default RostersPlayersExposuresTable;
