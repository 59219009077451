import React, { useState, useEffect } from 'react';
import OffensivePlayBalanceChart from '../TeamMetrics/OffensivePlayBalanceChart/OffensivePlayBalanceChart';
import OffensivePlayVolumeChart from '../TeamMetrics/OffensivePlayVolumeChart/OffensivePlayVolumeChart';
import OffensiveEfficiencyChart from '../TeamMetrics/OffensiveEfficiencyChart/OffensiveEfficiencyChart';
import OffensiveProductionChart from '../TeamMetrics/OffensiveProductionChart/OffensiveProductionChart';
import ScoringVsTurnoversChart from '../TeamMetrics/ScoringVsTurnoversChart/ScoringVsTurnoversChart';
import SuccessRatesChart from '../TeamMetrics/SuccessRatesChart/SuccessRatesChart';
import TimeOfPossessionChart from '../TeamMetrics/TimeOfPossessionChart/TimeOfPossessionChart';
import PaceOfPlayChart from '../TeamMetrics/PaceOfPlayChart/PaceOfPlayChart';
import FirstDownsAndDrivesChart from '../TeamMetrics/FirstDownsAndDrivesChart/FirstDownsAndDrivesChart';

const TeamMetricsCard = ({ team, metrics }) => {
	const [
		isMetricsVisible,
		setIsMetricsVisible
	] = useState(true);
	const [
		currentIndex,
		setCurrentIndex
	] = useState(0);
	const [
		chartsPerPage,
		setChartsPerPage
	] = useState(3);

	const teamData =
		metrics && metrics.avg_recent_team_stats
			? metrics.avg_recent_team_stats
			: {};
	const leagueData =
		metrics && metrics.league_avg_recent_team_stats
			? metrics.league_avg_recent_team_stats
			: {};

	const charts = [
		<OffensivePlayVolumeChart
			teamName={(team && team.name) || 'Unknown Team'}
			teamData={teamData}
			leagueData={leagueData}
		/>,
		<OffensivePlayBalanceChart
			teamName={(team && team.name) || 'Unknown Team'}
			teamData={teamData}
			leagueData={leagueData}
		/>,
		<FirstDownsAndDrivesChart
			teamName={(team && team.name) || 'Unknown Team'}
			teamData={teamData}
			leagueData={leagueData}
		/>,
		<OffensiveProductionChart
			teamName={(team && team.name) || 'Unknown Team'}
			teamData={teamData}
			leagueData={leagueData}
		/>,
		<OffensiveEfficiencyChart
			teamName={(team && team.name) || 'Unknown Team'}
			teamData={teamData}
			leagueData={leagueData}
		/>,
		<ScoringVsTurnoversChart
			teamName={(team && team.name) || 'Unknown Team'}
			teamData={teamData}
			leagueData={leagueData}
		/>,
		<SuccessRatesChart
			teamName={(team && team.name) || 'Unknown Team'}
			teamData={teamData}
			leagueData={leagueData}
		/>,
		<PaceOfPlayChart
			teamName={(team && team.name) || 'Unknown Team'}
			teamData={teamData}
			leagueData={leagueData}
		/>,
		<TimeOfPossessionChart
			teamName={(team && team.name) || 'Unknown Team'}
			teamData={teamData}
			leagueData={leagueData}
		/>
	];

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth >= 1280) {
				// Large screens (xl)
				setChartsPerPage(3);
			} else if (window.innerWidth >= 768) {
				// Medium screens (md)
				setChartsPerPage(2);
			} else {
				// Small screens (sm)
				setChartsPerPage(1);
			}
		};

		handleResize(); // Initialize on mount
		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const toggleMetricsVisibility = () => {
		setIsMetricsVisible((prev) => !prev);
	};

	const handleNext = () => {
		setCurrentIndex((prev) => (prev + chartsPerPage) % charts.length);
	};

	const handlePrev = () => {
		setCurrentIndex(
			(prev) => (prev - chartsPerPage + charts.length) % charts.length
		);
	};

	return (
		<div className="flex flex-col justify-between mt-14">
			<div className="flex flex-row items-center">
				<h2 className="text-3xl text-gray-800 font-bold">Metrics</h2>
				<i
					className={`fas fa-chevron-${isMetricsVisible
						? 'up'
						: 'down'} text-gray-500 cursor-pointer ml-2`}
					onClick={toggleMetricsVisibility}
				/>
			</div>

			{isMetricsVisible && (
				<div className="mt-4 relative">
					{/* Carousel */}
					<div
						className={`grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4`}
					>
						{charts
							.slice(currentIndex, currentIndex + chartsPerPage)
							.map((chart, index) => <div key={index}>{chart}</div>)}
					</div>

					{/* Carousel Controls */}
					<div className="absolute top-1/2 left-0 right-0 flex justify-between">
						<button
							onClick={handlePrev}
							className="absolute top-1/2 transform -translate-y-1/2 bg-gray-200 p-3 rounded-md z-10 border border-gray-300"
							style={{ left: '-15px' }}
						>
							{'<'}
						</button>
						<button
							onClick={handleNext}
							className="absolute top-1/2 transform -translate-y-1/2 bg-gray-200 p-3 rounded-md z-10 border border-gray-300"
							style={{ right: '-15px' }}
						>
							{'>'}
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default TeamMetricsCard;
