import React from 'react';
import { Link } from 'react-router-dom';
import MobileDataTableCard from '../../DataTables/MobileDataTableCard';
import AdvancedDataTable from '../../DataTables/AdvancedDataTable';
import getPositionAbbreviation from '../../../utils/getPositionAbbreviation';
import StatusWidget from '../../StatusWidget/StatusWidget';
import GradesValueToColorHelper from '../../Helpers/GradesValueToColorHelper';
import QbFortyTimeValueToColorHelper from '../../Helpers/QbFortyTimeValueToColorHelper';
import QbAgeValueToColorHelper from '../../Helpers/QbAgeValueToColorHelper';
import RbFortyTimeValueToColorHelper from '../../Helpers/RbFortyTimeValueToColorHelper';
import RbAgeValueToColorHelper from '../../Helpers/RbAgeValueToColorHelper';
import WrFortyTimeValueToColorHelper from '../../Helpers/WrFortyTimeValueToColorHelper';
import WrAgeValueToColorHelper from '../../Helpers/WrAgeValueToColorHelper';
import TeFortyTimeValueToColorHelper from '../../Helpers/TeFortyTimeValueToColorHelper';
import TeAgeValueToColorHelper from '../../Helpers/TeAgeValueToColorHelper';

function TeamPlayersStatsTable({
	players,
	sortOrder,
	sortBy,
	handleSortOrderChange,
	isHeatmapEnabled,
	isHeatmapAgeCategoryEnabled,
	isHeatmap40TimeCategoryEnabled,
	isHeatmapFFScoresCategoryEnabled
}) {
	return (
		<div className="border border-gray-300 rounded-xl shadow-lg bg-white p-6 lg:p-8 ">
			<div>
				<div className="contents lg:hidden">
					<MobileDataTableCard
						data={players}
						style="text-gray-800 border-b border-gray-300 font-semibold"
						rowRenderer={(row) => {
							const labels = [
								{
									displayName: 'Player',
									apiName: 'player',
									alignment: 'left'
								},
								{
									displayName: 'Position',
									apiName: 'position',
									alignment: 'left'
								},
								{
									displayName: 'Age (years)',
									apiName: 'age',
									alignment: 'left'
								},
								{
									displayName: '40 Time (sec)',
									apiName: 'forty_time',
									alignment: 'left'
								},
								{
									displayName: 'Team Score™',
									apiName: 'team_grade',
									alignment: 'left'
								},
								{
									displayName: 'Talent Score™',
									apiName: 'talent_grade',
									alignment: 'left'
								},
								{
									displayName: 'Overall Score™',
									apiName: 'fantasy_fitness_grade',
									alignment: 'left'
								}
							];
							return (
								<div
									key={`${row.first_name} ${row.last_name} - PlayersProfileStatsTable - MobileDataTableCard`}
									className="p-5 bg-white border-t text-gray-800"
								>
									<div>
										<Link
											to={`/players/${row.id}?position=${row.position}`}
											className="text-blue-500 hover:underline"
										>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<div
													style={{
														width: '50px',
														height: '50px',
														overflow: 'hidden',
														marginRight: '8px'
													}}
												>
													<img
														src={row.image_url + '?w=50&h=50'}
														alt={`${row.first_name} ${row.last_name}`}
														style={{
															width: '100%',
															height: '100%',
															objectFit: 'cover',
															border: '1px solid #ddd',
															borderRadius: '50%'
														}}
													/>
												</div>
												{`${row.first_name} ${row.last_name}`}
											</div>
										</Link>
									</div>
									<div className="mt-4 font-semibold">
										{labels[1].displayName}
									</div>
									<div>{getPositionAbbreviation(row.position)}</div>
									<div className="mt-4 font-semibold">
										{labels[2].displayName}
									</div>
									<div>{row.age}</div>
									<div className="mt-4 font-semibold">
										{labels[3].displayName}
									</div>
									<div>{row.forty_time}</div>
									<div className="mt-4 font-semibold">
										{labels[4].displayName}
									</div>
									<div>{row.team_grade}</div>
									<div className="mt-4 font-semibold">
										{labels[5].displayName}
									</div>
									<div>{row.talent_grade}</div>
									<div className="mt-4 font-semibold">
										{labels[6].displayName}
									</div>
									<div>{row.fantasy_fitness_grade}</div>
								</div>
							);
						}}
					/>
				</div>
				<div className="hidden lg:contents">
					<AdvancedDataTable
						data={players}
						style="text-gray-800 border-b border-gray-300 font-semibold"
						sortingEnabled={true}
						sortOrder={sortOrder}
						sortColumn={sortBy ? sortBy : ''}
						onChangeSortOrder={handleSortOrderChange}
						columns={[
							{
								displayName: 'Player',
								apiName: 'player',
								alignment: 'left',
								minWidth: '240px'
							},
							{
								displayName: 'Position',
								apiName: 'position',
								alignment: 'left'
							},
							{
								displayName: 'Age (years)',
								apiName: 'age',
								alignment: 'left'
							},
							{
								displayName: '40 Time (sec)',
								apiName: 'forty_time',
								alignment: 'left'
							},
							{
								displayName: 'Team Score™',
								apiName: 'team_grade',
								alignment: 'left'
							},
							{
								displayName: 'Talent Score™',
								apiName: 'talent_grade',
								alignment: 'left'
							},
							{
								displayName: 'Overall Score™',
								apiName: 'fantasy_fitness_grade',
								alignment: 'left'
							}
						]}
						rowRenderer={(row) => {
							return (
								<tr
									key={`${row.first_name} ${row.last_name} - PlayersProfileStatsTable - AdvancedDataTable`}
									className="pl-8 py-3 bg-white border-t text-gray-800"
								>
									<td className="pl-8 py-3 text-left">
										<Link
											to={`/players/${row.id}?position=${row.position}`}
											className="text-blue-500 hover:underline"
										>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													position: 'relative'
												}}
											>
												<div
													style={{
														width: '50px',
														height: '50px',
														overflow: 'hidden',
														marginRight: '8px'
													}}
												>
													<img
														src={row.image_url + '?w=50&h=50'}
														alt={`${row.first_name} ${row.last_name}`}
														style={{
															width: '50px',
															height: '50px',
															minWidth: '50px',
															minHeight: '50px',
															objectFit: 'cover',
															border: '1px solid #ddd',
															borderRadius: '50%'
														}}
													/>
													{/*}
													<div
														style={{
															position: 'absolute',
															top: '3px',
															left: '37px'
														}}
													>
														<StatusWidget
															status={row.status}
															description={row.injury_description}
															hideLabel={true}
															is50pXThumbnail={true}
														/>
													</div>
													*/}
												</div>
												{`${row.first_name} ${row.last_name}`}
											</div>
										</Link>
									</td>
									<td className="pl-8 py-3 text-left">
										{getPositionAbbreviation(row.position)}
									</td>
									{/* Rendering Age status column */}

									<td>
										<div
											className="pl-8 py-1 text-left relative"
											style={{
												height: '75px',
												padding: '5px',
												boxSizing: 'border-box'
											}}
										>
											{/* Background color */}
											<div
												style={{
													backgroundColor:
														isHeatmapEnabled && isHeatmapAgeCategoryEnabled
															? row.position === 'quarterback'
																? QbAgeValueToColorHelper(row.age)
																: row.position === 'running back'
																	? RbAgeValueToColorHelper(row.age)
																	: row.position === 'wide receiver'
																		? WrAgeValueToColorHelper(row.age)
																		: row.position === 'tight end'
																			? TeAgeValueToColorHelper(row.age)
																			: 'transparent'
															: 'transparent',
													borderRadius: '10px',
													opacity: isHeatmapEnabled ? '0.7' : '1',
													height: '100%',
													width: '100%'
												}}
											/>

											{/* Text centered over the colored area */}
											<span
												className="text-black"
												style={{
													position: 'absolute',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													zIndex: 2
												}}
											>
												{row.age}
											</span>
										</div>
									</td>

									{/* Rendering Forty Time status column */}
									<td>
										<div
											className="pl-8 py-1 text-left relative"
											style={{
												height: '75px',
												padding: '5px',
												boxSizing: 'border-box'
											}}
										>
											{/* Background color */}
											<div
												style={{
													backgroundColor:
														isHeatmapEnabled && isHeatmap40TimeCategoryEnabled
															? row.position === 'quarterback'
																? QbFortyTimeValueToColorHelper(row.forty_time)
																: row.position === 'running back'
																	? RbFortyTimeValueToColorHelper(
																			row.forty_time
																		)
																	: row.position === 'wide receiver'
																		? WrFortyTimeValueToColorHelper(
																				row.forty_time
																			)
																		: row.position === 'tight end'
																			? TeFortyTimeValueToColorHelper(
																					row.forty_time
																				)
																			: 'transparent'
															: 'transparent',
													borderRadius: '10px',
													opacity: isHeatmapEnabled ? '0.7' : '1',
													height: '100%',
													width: '100%'
												}}
											/>

											{/* Text centered over the colored area */}
											<span
												className="text-black"
												style={{
													position: 'absolute',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													zIndex: 2
												}}
											>
												{row.forty_time}
											</span>
										</div>
									</td>

									<td>
										<div
											className="pl-8 py-1 text-left relative"
											style={{
												height: '75px',
												padding: '5px',
												boxSizing: 'border-box'
											}}
										>
											{/* Background color */}
											<div
												style={{
													backgroundColor:
														isHeatmapEnabled && isHeatmapFFScoresCategoryEnabled
															? GradesValueToColorHelper(row.team_grade)
															: 'transparent',
													borderRadius: '10px',
													opacity: isHeatmapEnabled ? '0.7' : '1',
													height: '100%',
													width: '100%'
												}}
											/>

											{/* Text centered over the colored area */}
											<span
												className="text-black"
												style={{
													position: 'absolute',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													zIndex: 2
												}}
											>
												{row.team_grade}
											</span>
										</div>
									</td>
									<td>
										<div
											className="pl-8 py-1 text-left relative"
											style={{
												height: '75px',
												padding: '5px',
												boxSizing: 'border-box'
											}}
										>
											{/* Background color */}
											<div
												style={{
													backgroundColor:
														isHeatmapEnabled && isHeatmapFFScoresCategoryEnabled
															? GradesValueToColorHelper(row.talent_grade)
															: 'transparent',
													borderRadius: '10px',
													opacity: isHeatmapEnabled ? '0.7' : '1',
													height: '100%',
													width: '100%'
												}}
											/>

											{/* Text centered over the colored area */}
											<span
												className="text-black"
												style={{
													position: 'absolute',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													zIndex: 2
												}}
											>
												{row.talent_grade}
											</span>
										</div>
									</td>
									<td>
										<div
											className="pl-8 py-1 text-left relative"
											style={{
												height: '75px',
												padding: '5px',
												boxSizing: 'border-box'
											}}
										>
											{/* Background color */}
											<div
												style={{
													backgroundColor:
														isHeatmapEnabled && isHeatmapFFScoresCategoryEnabled
															? GradesValueToColorHelper(
																	row.fantasy_fitness_grade
																)
															: 'transparent',
													borderRadius: '10px',
													opacity: isHeatmapEnabled ? '0.7' : '1',
													height: '100%',
													width: '100%'
												}}
											/>

											{/* Text centered over the colored area */}
											<span
												className="text-black"
												style={{
													position: 'absolute',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													zIndex: 2
												}}
											>
												{row.fantasy_fitness_grade}
											</span>
										</div>
									</td>
								</tr>
							);
						}}
					/>
				</div>
			</div>
		</div>
	);
}

export default TeamPlayersStatsTable;
