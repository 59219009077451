import React, { Fragment } from 'react';
import Badges from '../../../../assets/images/badges.jpeg';

export default function UserBadgesCard({ user }) {
	return (
		<div className="bg-white w-full min-w-96 text-gray-800">
			<h2 className="text-lg lg:text-xl font-semibold mb-2">
				{user.first_name}'s Badges
			</h2>
			<div className="col-span-12 lg:col-span-10">
				<Fragment>
					{/* Badge Details Section */}
					<div className="flex justify-center items-center p-4">
						<img
							src={Badges}
							alt="Badges"
							className="hidden md:inline-block"
							style={{
								height: '260px',
								width: '420px'
							}}
						/>
						<img
							src={Badges}
							alt="Badges"
							className="hidden md:inline-block"
							style={{
								height: '260px',
								width: '420px'
							}}
						/>
					</div>
				</Fragment>
			</div>
		</div>
	);
}
