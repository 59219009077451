import React from 'react';
import ReactApexChart from 'react-apexcharts';

const FirstDownsAndDrivesChart = ({ teamData, leagueData, teamName }) => {
	// Extract the last word from the team name
	const teamNameLastWord = teamName.split(' ').pop();

	// Ensure data exists and provide fallbacks, converting values to numbers
	const calculateValue = (value) =>
		!value || isNaN(value) ? 0 : parseFloat(value);

	const teamAvgDrives = calculateValue(teamData && teamData.avg_drives);
	const leagueAvgDrives = calculateValue(leagueData && leagueData.avg_drives);
	const teamAvgFirstDowns = calculateValue(
		teamData && teamData.avg_first_downs
	);
	const leagueAvgFirstDowns = calculateValue(
		leagueData && leagueData.avg_first_downs
	);

	// Chart configuration
	const chartOptions = {
		series: [
			{
				name: `${teamNameLastWord}`,
				data: [
					teamAvgDrives,
					teamAvgFirstDowns
				]
			},
			{
				name: 'League Avg',
				data: [
					leagueAvgDrives,
					leagueAvgFirstDowns
				]
			}
		],
		options: {
			chart: {
				type: 'bar',
				height: 350
			},
			plotOptions: {
				bar: {
					columnWidth: '55%',
					distributed: false // Set to false to group bars by category
				}
			},
			colors: [
				'#2980B9',
				'#E67E22'
			], // Team: Purple, League: Green
			stroke: {
				width: 1,
				colors: [
					'#fff'
				]
			},
			title: {
				text: 'Drives & First Downs',
				align: 'center',
				style: { fontSize: '14px' }
			},
			xaxis: {
				categories: [
					'Avg Drives',
					'Avg First Downs'
				]
			},
			yaxis: {
				title: {
					text: 'Count'
				},
				labels: {
					formatter: (val) => Math.round(val) // Round values to integers
				}
			},
			tooltip: {
				y: {
					formatter: (val) => `${Math.round(val)}`
				}
			},
			legend: {
				position: 'top',
				horizontalAlign: 'center'
			}
		}
	};

	return (
		<div className="bg-white p-4 rounded-lg shadow-lg border border-gray-300">
			<ReactApexChart
				options={chartOptions.options}
				series={chartOptions.series}
				type="bar"
				height={250}
			/>
		</div>
	);
};

export default FirstDownsAndDrivesChart;
