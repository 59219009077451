import React, { useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import PageTitle from '../../FormElements/PageTitle';
import UserBadgesCard from '../../User/UserBadgesCard/UserBadgesCard';
import UserTrophiesCard from '../../User/UserTrophiesCard/UserTrophiesCard';
import UserRostersAlertsCard from '../../User/UserRostersAlertsCard/UserRostersAlertsCard';
import Modal from '../../Modal/Modal';
import ScoutOffice from '../../../../assets/images/scout_office.jpeg';
import MidGmOffice from '../../../../assets/images/mid_gm_office.jpeg';
import BestGmOffice from '../../../../assets/images/best_gm_office.jpeg';

function FrontOfficePage({ get_user_endpoint }) {
	const { user } = useAuth();
	const [
		isBadgesModalOpen,
		setIsBadgesModalOpen
	] = useState(false);
	const [
		isTrophiesModalOpen,
		setIsTrophiesModalOpen
	] = useState(false);
	const [
		isRostersAlertsModalOpen,
		setIsRostersAlertsModalOpen
	] = useState(false);
	const [
		currentIndex,
		setCurrentIndex
	] = useState(0);

	const images = [
		ScoutOffice,
		MidGmOffice,
		BestGmOffice
	];

	const handleBadgesModalOpen = () => setIsBadgesModalOpen(true);
	const handleBadgesModalClose = () => setIsBadgesModalOpen(false);
	const handleTrophiesModalOpen = () => setIsTrophiesModalOpen(true);
	const handleTrophiesModalClose = () => setIsTrophiesModalOpen(false);
	const handleRostersAlertsModalOpen = () => setIsRostersAlertsModalOpen(true);
	const handleRostersAlertsModalClose = () =>
		setIsRostersAlertsModalOpen(false);
	const handleClickSpotlight = () => {
		window.location.href = '/dashboard';
	};

	function getImageHeight() {
		if (window.innerWidth < 640) {
			return 420; // Small screens
		} else if (window.innerWidth > 640 && window.innerWidth <= 1024) {
			return 470; // iPad screens
		} else if (window.innerWidth > 1024 && window.innerWidth <= 1280) {
			return 540; // Laptop screens
		} else if (window.innerWidth > 1280 && window.innerWidth <= 1536) {
			return 580; // Larger screens
		} else {
			return 625; // Largest screens
		}
	}

	const nextImage = () => {
		setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
	};

	const prevImage = () => {
		setCurrentIndex(
			(prevIndex) => (prevIndex - 1 + images.length) % images.length
		);
	};

	return (
		<div className="container p-4 pt-4 lg:pt-14 md:px-20">
			<div className="mt-4">
				<PageTitle title="Front Office: Work. Rise. Dominate." />
			</div>
			<div className="relative">
				<img
					src={images[currentIndex]}
					alt="GM Office"
					className="inline-block relative rounded-lg shadow-lg object-cover"
					style={{ height: getImageHeight(), width: '100%' }}
				/>

				{/* Lock Overlay for Locked Images */}
				{currentIndex === 1 && (
					<div className="absolute inset-0 bg-black bg-opacity-60 flex items-center justify-center rounded-lg z-20">
						<div className="text-center text-white">
							<p className="text-xl font-bold p-6">
								Complete 5 courses to unlock the Assistant GM Office
							</p>
							<a
								href="/courses"
								className="text-blue-500 hover:underline font-semibold"
							>
								Go to courses
							</a>
						</div>
					</div>
				)}
				{currentIndex === 2 && (
					<div className="absolute inset-0 bg-black bg-opacity-60 flex items-center justify-center rounded-lg z-20">
						<div className="text-center text-white">
							<p className="text-xl font-bold p-6">
								Complete 9 courses to unlock the Executive GM Office
							</p>
							<a
								href="/courses"
								className="text-blue-500 hover:underline font-semibold"
							>
								Go to courses
							</a>
						</div>
					</div>
				)}

				{/* Icon Bar Inside Image */}
				<div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-6 p-3 rounded-full z-10">
					<div
						className="w-12 h-12 flex items-center justify-center bg-blue-500 border border-gray-200 text-white text-2xl rounded-full cursor-pointer hover:scale-105 transition-transform transform hover:scale-105 backdrop-blur-md"
						onClick={handleRostersAlertsModalOpen}
					>
						📢
					</div>
					<div
						className="w-12 h-12 flex items-center justify-center bg-blue-500 border border-gray-200 text-white text-2xl rounded-full cursor-pointer hover:scale-105 transition-transform transform hover:scale-105 backdrop-blur-md"
						onClick={handleClickSpotlight}
					>
						🖥️
					</div>
					<div
						className="w-12 h-12 flex items-center justify-center bg-blue-500 border border-gray-200 text-white text-2xl rounded-full cursor-pointer hover:scale-105 transition-transform transform hover:scale-105 backdrop-blur-md"
						onClick={handleBadgesModalOpen}
					>
						🔰
					</div>
					<div
						className="w-12 h-12 flex items-center justify-center bg-blue-500 border border-gray-200 text-white text-2xl rounded-full cursor-pointer hover:scale-105 transition-transform transform hover:scale-105 backdrop-blur-md"
						onClick={handleTrophiesModalOpen}
					>
						🏆
					</div>
				</div>

				{/* Left and Right Arrow Buttons */}
				<button
					onClick={prevImage}
					className="absolute top-1/2 transform -translate-y-1/2 bg-gray-200 p-3 rounded-md z-30 border border-gray-300"
					style={{ left: '-15px' }}
				>
					{'<'}
				</button>
				<button
					onClick={nextImage}
					className="absolute top-1/2 transform -translate-y-1/2 bg-gray-200 p-3 rounded-md z-30 border border-gray-300"
					style={{ right: '-15px' }}
				>
					{'>'}
				</button>
			</div>

			<div>
				{/* Modals */}
				{isBadgesModalOpen && (
					<Modal onClose={handleBadgesModalClose}>
						<UserBadgesCard user={user} />
					</Modal>
				)}
				{isTrophiesModalOpen && (
					<Modal onClose={handleTrophiesModalClose}>
						<UserTrophiesCard
							userId={user.id}
							get_user_endpoint={get_user_endpoint}
						/>
					</Modal>
				)}
				{isRostersAlertsModalOpen && (
					<Modal onClose={handleRostersAlertsModalClose}>
						<UserRostersAlertsCard />
					</Modal>
				)}
			</div>
		</div>
	);
}

export default FrontOfficePage;
