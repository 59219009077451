import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const TeamMatchupsTable = ({ matchups }) => {
	const [
		selectedResults,
		setSelectedResults
	] = useState(matchups.results);

	const formatDate = (dateString) => {
		if (!dateString) return 'TBD';
		const date = new Date(dateString);
		return date.toLocaleDateString('en-US', {
			year: 'numeric',
			month: 'long',
			day: 'numeric'
		});
	};

	if (!matchups || matchups.length === 0) {
		return null;
	}

	return (
		<div className="mt-6">
			<div className="p-8 bg-white rounded-xl border border-gray-300 shadow-lg">
				<div className="overflow-x-auto rounded-md border border-gray-300">
					<table className="min-w-full bg-white">
						<thead className="bg-gray-100 text-black p-4">
							<tr>
								<th className="px-8 py-4 text-left">Week</th>
								<th className="px-8 py-4 text-left">Opponent</th>
								<th className="px-8 py-4 text-left">Game Date</th>
								<th className="px-8 py-4 text-left">Location</th>
								<th className="px-8 py-4 text-left">Results</th>
							</tr>
						</thead>
						<tbody>
							{matchups.map((match) => (
								<tr key={match.id} className="border-t border-gray-300">
									<td className="px-8 py-4 text-gray-800">{match.week}</td>
									<td className="px-8 py-4 text-gray-800">
										<Link
											to={`/teams/${match.opponent_id}`}
											className="text-blue-500 hover:text-blue-600 hover:underline"
										>
											{match.opponent_name}
										</Link>
									</td>
									<td className="px-8 py-4 text-gray-800">
										{formatDate(match.game_date)}
									</td>
									<td className="px-8 py-4 text-gray-800">
										{match.is_home_game ? 'Home' : 'Away'}
									</td>
									<td className="px-8 py-4 text-gray-800">
										{match.results ? (
											<button
												onClick={() => setSelectedResults(match.results)}
												className="text-blue-500 hover:text-blue-600 hover:underline"
											>
												See results
											</button>
										) : (
											'N/A'
										)}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			{selectedResults && (
				<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center ">
					<div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full z-50 max-h-96 overflow-y-auto">
						<h2 className="text-xl font-semibold mb-4">Match Results</h2>
						<pre className="text-sm bg-gray-100 p-4 rounded-md overflow-auto">
							{JSON.stringify(selectedResults, null, 2)}
						</pre>
						<button
							onClick={() => setSelectedResults(null)}
							className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
						>
							Close
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default TeamMatchupsTable;
