import React from 'react';

const FilterByIsRookie = ({ filter, onChange }) => {
	const handleCheckboxChange = (event) => {
		onChange('isRookie', event.target.checked);
	};

	return (
		<div className="flex items-center mr-4 my-3 md:my-4">
			<span className="mr-2 text-sm md:text-base">Rookies</span>
			<input
				type="checkbox"
				checked={filter.isRookie || false}
				onChange={handleCheckboxChange}
				className="hidden"
				id="isRookieCheckbox"
			/>
			<label
				htmlFor="isRookieCheckbox"
				className="cursor-pointer select-none relative"
			>
				<div
					className={`w-8 h-4 md:w-12 md:h-6 rounded-full shadow-inner ${filter.isRookie
						? 'bg-gray-800'
						: 'bg-gray-300'}`}
				/>
				<div
					className={`dot absolute top-0 left-0 w-4 h-4 md:w-6 md:h-6 bg-white rounded-full shadow transition transform ${filter.isRookie
						? 'translate-x-full border border-gray-500'
						: 'border border-gray-300'}`}
				/>
			</label>
		</div>
	);
};

export default FilterByIsRookie;
