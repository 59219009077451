import React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Article from '../../Article/ArticleCard/ArticleCard';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

export default function HelpCenterIndexCard({ get_articles_endpoint }) {
	const [
		articles,
		setArticles
	] = useState([]);
	const [
		loading,
		setLoading
	] = useState(true);
	const [
		error,
		setError
	] = useState(null);

	useEffect(
		() => {
			const fetchData = async () => {
				try {
					const url = `${get_articles_endpoint}?article_type=Help Desk`;

					const response = await fetch(url);
					if (!response.ok) {
						throw new Error('Failed to fetch articles');
					}
					const result = await response.json();
					setArticles(result.data || []);
				} catch (error) {
					setError(
						error.message || 'An error occurred while fetching articles.'
					);
				} finally {
					setLoading(false);
				}
			};

			fetchData();
		},
		[
			get_articles_endpoint
		]
	);

	return (
		<div>
			<h1 className="text-5xl mb-4 text-center">Hi, how can we help?</h1>
			{loading && <LoadingSpinner />}
			{error && <div>Error: {error}</div>}
			{!loading &&
			!error && (
				<div className="py-6 md:py-14">
					<div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
						{articles.map((article) => (
							<Article key={article.id} article={article} />
						))}
					</div>
				</div>
			)}
		</div>
	);
}

HelpCenterIndexCard.propTypes = {
	get_articles_endpoint: PropTypes.string.isRequired
};
