import React from 'react';
import ReactApexChart from 'react-apexcharts'; // Ensure this is installed

const Week17GameStacksCard = ({
	gamesStacksCount,
	players,
	isDraftModeEnabled
}) => {
	const getTeamName = (team) => {
		const parts = team.split(' ');
		return parts[parts.length - 1].toLowerCase(); // Get last part and normalize to lowercase
	};

	const getNormalizedMatchup = (team1, team2) => {
		const team1Name = getTeamName(team1);
		const team2Name = getTeamName(team2);
		// Sort teams alphabetically to ensure consistency
		return [
			team1Name,
			team2Name
		]
			.sort()
			.join('/');
	};

	// Group players by matchup
	const playersByMatchup = players.reduce((acc, player) => {
		let matchup = '';

		if (player.week_17_opponent  && player.week_17_opponent.opponent_team) {
			const playerTeam = getTeamName(player.team.name);
			const opponentTeam = getTeamName(
				player &&
					player.week_17_opponent &&
					player.week_17_opponent.opponent_team &&
					player.week_17_opponent.opponent_team.name
			);

			if (playerTeam && opponentTeam) {
				// Normalize the matchup by sorting the teams alphabetically
				matchup = getNormalizedMatchup(playerTeam, opponentTeam);
			}
		}

		if (matchup) {
			if (!acc[matchup]) acc[matchup] = [];
			acc[matchup].push(`${player.first_name} ${player.last_name}`);
		}

		return acc;
	}, {});

	// Log the final playersByMatchup object for debugging
	// console.log('Players grouped by matchup: ', playersByMatchup);

	// Prepare data for the treemap chart
	const treemapData = Object.entries(gamesStacksCount).map(([ matchup, count
	]) => ({
		x: matchup,
		y: count,
		matchup: matchup // Store the matchup in the data for tooltip access
	}));

	const chartData = {
		series: [
			{
				data: treemapData
			}
		],
		options: {
			chart: {
				type: 'treemap',
				toolbar: {
					show: false
				},
				height: 208
			},
			legend: {
				show: false
			},
			tooltip: !isDraftModeEnabled
				? {
						custom: ({ seriesIndex, dataPointIndex, w }) => {
							const matchup = w.config.series[seriesIndex].data[
								dataPointIndex
							].matchup.toLowerCase();
							const playerList = playersByMatchup[matchup] || [];

							return `
		      <div class="px-2 py-1 bg-white shadow-lg border border-gray-300 rounded text-black">
            <strong>${matchup}</strong>
            <ul>
              ${playerList
								.map((player) => `<li style="color: black;">${player}</li>`)
								.join('')}
            </ul>
          </div>
        `;
						}
					}
				: {} // Default behavior if !isDraftModeEnabled is true
		}
	};

	return (
		<div className="relative bg-white p-4 rounded-lg shadow-lg border border-gray-300 cursor-pointer">
			{/* Title */}
			<h3 className="text-sm font-medium text-gray-800 text-center">
				Week 17 Game Stacks
			</h3>
			{/* Card Content */}
			<div className="flex justify-center items-center">
				<div className="flex flex-col items-center">
					<ReactApexChart
						options={chartData.options}
						series={chartData.series}
						type="treemap"
						height={208}
					/>
				</div>
			</div>
		</div>
	);
};

export default Week17GameStacksCard;
