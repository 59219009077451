import React from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import UserDetailsCard from '../../User/UserDetailsCard/UserDetailsCard';

function UserProfilePage({
	get_user_endpoint,
	update_user_endpoint,
	update_user_front_office_mode_endpoint,
	get_subscriptions_by_user_endpoint,
	create_billing_portal_session_endpoint
}) {
	const { user } = useAuth();

	return (
		<div className="container p-4 pt-4 lg:pt-14 md:px-20">
			<UserDetailsCard
				userId={user.id}
				get_user_endpoint={get_user_endpoint}
				update_user_endpoint={update_user_endpoint}
				update_user_front_office_mode_endpoint={
					update_user_front_office_mode_endpoint
				}
				get_subscriptions_by_user_endpoint={get_subscriptions_by_user_endpoint}
				create_billing_portal_session_endpoint={
					create_billing_portal_session_endpoint
				}
			/>
		</div>
	);
}

export default UserProfilePage;
