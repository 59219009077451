import React from 'react';

const SortByCategoryDropdown = ({
	sortBy,
	handleSortChange,
	handleToggleSortOrder,
	sortOrder,
	isonProspectPage
}) => {
	return (
		<div className="contents lg:hidden">
			<div className="flex items-center mb-4 my-2 lg:my-0 mr-2 md:mr-4">
				<label className="text-sm sm:text-base mr-2">Sort By</label>
				<select
					name="sortBy"
					value={sortBy}
					onChange={handleSortChange}
					className="p-1.5 sm:p-2 border border-gray-300 rounded-md text-xs sm:text-base"
				>
					{/* Options for sort criteria */}
					<option value="height">Height</option>
					<option value="weight">Weight</option>
					<option value="age">Age</option>
					<option value="forty_time">Forty Time</option>
					{!isonProspectPage && <option value="team_grade">Team Score</option>}
					{!isonProspectPage && (
						<option value="talent_grade">Talent Score</option>
					)}
					{!isonProspectPage && (
						<option value="fantasy_fitness_grade">Fantasy Fitness Score</option>
					)}
					{isonProspectPage && (
						<option value="prospect_grade">Prospect Score</option>
					)}
				</select>
				<button
					onClick={handleToggleSortOrder}
					className="p-1 sm:p-2 text-xs sm:text-base"
				>
					<span className="ml-3 mr-1">
						{sortOrder === 'asc' ? 'Ascending' : 'Descending'}
					</span>
					{sortOrder === 'asc' ? '↑' : '↓'}
				</button>
			</div>
		</div>
	);
};

export default SortByCategoryDropdown;
