import React from 'react';

const ProStatsButtons = ({
	selectedProStatsType,
	handleProBasicStatsClick,
	handleProAdvancedStatsClick
}) => {
	return (
		<div className="mb-0 lg:mb-4 text-xs md:text-base flex flex-col sm:flex-row items-start">
			{/* Pro Basic Stats Button */}
			<button
				className={`mb-2 mr-2 p-2 border border-gray-300 rounded-md whitespace-nowrap ${selectedProStatsType ===
				'basic'
					? 'bg-gray-800 text-white'
					: 'bg-white text-black'}`}
				onClick={handleProBasicStatsClick}
			>
				NFL Basic Stats
			</button>

			{/* Pro Advanced Stats Button */}
			<button
				className={`mx-0 p-2 border border-gray-300 rounded-md whitespace-nowrap ${selectedProStatsType ===
				'advanced'
					? 'bg-gray-800 text-white'
					: 'bg-white text-black'}`}
				onClick={handleProAdvancedStatsClick}
			>
				NFL Advanced Stats
			</button>
		</div>
	);
};

export default ProStatsButtons;
