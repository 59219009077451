import React, { Fragment } from 'react';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import ReactApexChart from 'react-apexcharts';

const RostersPlayersExposuresChart = ({
	data = {},
	totalRosters,
	format,
	platform,
	scoringType,
	isLoading
}) => {
	if (isLoading) {
		return <LoadingSpinner />;
	}

	if (!data || Object.keys(data).length === 0) {
		return <div>No data available.</div>;
	}

	const positionColors = {
		quarterbacks: '#9647B8',
		running_backs: '#15997E',
		wide_receivers: '#E67E22',
		tight_ends: '#2980B9'
	};

	const barChartData = Object.entries(data).flatMap(([ position, players
	]) =>
		Object.entries(players).map(([ playerName, playerData
		]) => ({
			name: playerName,
			exposure: playerData.percentage,
			rosters: playerData.rosters,
			position: playerData.position // Part of the hack with servicer that eventually needs to be refactored
		}))
	);

	const playerNames = barChartData.map((player) => player.name);
	const exposureValues = barChartData.map((player) => player.exposure);
	const barColors = barChartData.map(
		(player) => positionColors[player.position] || '#4A4A4A'
	);

	const totalPlayers = Object.values(data).reduce(
		(count, players) => count + Object.keys(players).length,
		0
	);

	const chartWidth = Math.max(totalPlayers * 44, 795); // Ensures a minimum width of 795px

	// Chart configuration
	const barChartOptions = {
		chart: {
			type: 'bar',
			height: 615,
			width: '100%',
			toolbar: {
				show: false
			}
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: '90%',
				borderRadius: 5,
				dataLabels: { position: 'hidden' },
				distributed: true
			}
		},
		xaxis: {
			categories: playerNames,
			title: { text: 'Players' },
			labels: {
				rotate: -45,
				style: { fontSize: '12px', colors: '#4A4A4A' }
			}
		},
		yaxis: {
			title: {
				text: 'Exposure (%)',
				style: {
					fontSize: '12px',
					colors: '#4A4A4A'
				},
				labels: {
					formatter: (value) => `${Math.floor(value)}`
				},
				tickAmount: Math.ceil(100 / 10)
			},
			labels: {
				style: {
					fontSize: '14px',
					colors: '#4A4A4A'
				}
			},
			min: 0,
			max: 100,
			tickAmount: 10,
			decimalsInFloat: 0
		},
		fill: {
			colors: barColors,
			opacity: 0.8
		},
		stroke: {
			width: 2,
			colors: [
				'#ffffff'
			]
		},
		legend: {
			show: false
		},
		tooltip: {
			custom: ({ dataPointIndex, w }) => {
				const playerName = w.config.xaxis.categories[dataPointIndex];
				const playerData = barChartData.find(
					(player) => player.name === playerName
				);
				const rosters = playerData.rosters;
				const exposurePercentage = playerData.exposure;

				return `
			<div class="px-2 py-1 bg-white shadow-lg border border-gray-300 rounded text-black">
				<strong>${playerName} (${exposurePercentage}%)</strong>
				<ul>
					${rosters
						.map(
							(roster) => `<li style="color: black;">${roster.team_name}</li>`
						)
						.join('')}
				</ul>
			</div>
		`;
			}
		}
	};

	const LegendKey = () => {
		const positionAbbreviations = {
			quarterbacks: 'QB',
			running_backs: 'RB',
			wide_receivers: 'WR',
			tight_ends: 'TE'
		};

		const positions = Object.keys(positionAbbreviations);

		return (
			<div className="absolute top-4 right-4 bg-white border p-2 rounded-lg">
				<div className="grid grid-cols-2 gap-y-1 gap-x-4">
					{positions.map((position) => (
						<div key={position} className="flex items-center">
							<span
								className="inline-block w-3 h-3 rounded-full"
								style={{ backgroundColor: positionColors[position] }}
							/>
							<span className="ml-2 text-sm">
								{positionAbbreviations[position]}
							</span>
						</div>
					))}
				</div>
			</div>
		);
	};

	return (
		<Fragment>
			{/* Updated Platform Information Section */}
			<div className="text-center text-base text-gray-500 py-2 flex flex-col sm:flex-row gap-4 sm:gap-8">
				<div className="flex items-center">
					<span className="font-bold uppercase">Format</span>
					<span className="ml-2">{format ? format : 'All'}</span>
				</div>
				<div className="flex items-center">
					<span className="font-bold uppercase">Platform</span>
					<span className="ml-2">{platform ? platform : 'All'}</span>
				</div>
				<div className="flex items-center">
					<span className="font-bold uppercase">Scoring</span>
					<span className="ml-2">{scoringType ? scoringType : 'All'}</span>
				</div>
				<div className="flex items-center">
					<span className="font-bold uppercase">Total Rosters</span>
					<span className="ml-2">
						{totalRosters ? totalRosters : 'Contact Support'}
					</span>
				</div>
			</div>

			{/* Bar Chart Section with Horizontal Scroll */}
			<div className="relative bg-white p-4 rounded-lg border border-gray-300 mt-4 w-full">
				<h3 className="text-sm font-bold text-gray-800 text-center">
					Players Exposures Chart
				</h3>
				<div className="overflow-x-auto w-full">
					<ReactApexChart
						options={barChartOptions}
						series={[
							{ name: 'Exposure %', data: exposureValues }
						]}
						type="bar"
						height={615}
						width={chartWidth}
					/>
					<LegendKey />
				</div>
			</div>
		</Fragment>
	);
};

export default RostersPlayersExposuresChart;
