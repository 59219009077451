import React, { Fragment } from 'react';

export default function UserRostersAlertsCard() {
	return (
		<div className="bg-white w-full min-w-96 text-gray-800">
			<h2 className="text-lg lg:text-xl font-semibold mb-2" />
			<div className="col-span-12 lg:col-span-10">
				<Fragment>
					{/* Rosters Alets Details Section */}
					<div className="flex justify-center items-center p-4">
						No alerts at the moment.
					</div>
				</Fragment>
			</div>
		</div>
	);
}
