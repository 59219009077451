import React, { useEffect, useRef } from 'react';
import StatusWidget from '../../StatusWidget/StatusWidget';
import getPositionAbbreviation from '../../../utils/getPositionAbbreviation';

const RosterPlayerNotesModalMobile = ({ isOpen, onClose, rosterNotes }) => {
	const modalRef = useRef(null);

	const handleClickOutsideModal = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			onClose();
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutsideModal);
		return () => {
			document.removeEventListener('mousedown', handleClickOutsideModal);
		};
	}, []);

	if (!isOpen) return null;

	return (
		<div className="contents lg:hidden">
			<div
				className="fixed inset-0 flex items-center justify-center"
				style={{
					zIndex: 1000,
					backgroundColor: 'rgba(0, 0, 0, 0.5)'
				}}
			>
				<div
					className="bg-white rounded-lg border border-gray-200 text-gray-800 p-6 w-full max-h-full relative"
					ref={modalRef}
				>
					<h2 className="text-xl font-bold">Roster Player Notes</h2>
					<button
						className="absolute top-2 right-2 p-2 rounded-full hover:bg-gray-200 transition duration-300"
						onClick={onClose}
					>
						<svg
							className="h-5 w-5"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M6 18L18 6M6 6l12 12"
							/>
						</svg>
					</button>

					{/* Display message if no notes exist otherwise display notes */}
					{rosterNotes.length === 0 ? (
						<div>
							<p className="pt-6">No roster player notes exist at this time.</p>
						</div>
					) : (
						<div
							className="overflow-y-auto flex flex-wrap items-start text-base text-gray-500 px-2 py-4"
							style={{ maxHeight: '100vh' }}
						>
							{rosterNotes.map(
								(note, index) =>
									note.note.content ? (
										<div key={index} className="w-full mb-4 border-b pb-4">
											<div className="flex items-center mb-2">
												{/* Image and Name */}
												<div
													className="relative mr-4 mb-2"
													style={{
														width: '40px',
														height: '40px',
														overflow: 'hidden'
													}}
												>
													<img
														src={`${note.image_url}?w=40&h=40`}
														alt={`${note.first_name} ${note.last_name}`}
														className="w-full h-full object-cover rounded-full border border-gray-300"
													/>
													{note.status && (
														<div
															style={{
																position: 'absolute',
																top: '0',
																right: '0'
															}}
														>
															<StatusWidget
																status={note.status}
																description={note.injury_description || ''}
																hideLabel={true}
																is50pXThumbnail={true}
															/>
														</div>
													)}
												</div>
												<span className="text-gray-800">
													{`${note.first_name} ${note.last_name}, ${getPositionAbbreviation(
														note.position
													)}`}
												</span>
											</div>

											{/* Player Details */}
											<div className="flex flex-wrap">
												<div className="flex flex-col mr-6 mb-2">
													<span className="font-bold uppercase">Notes</span>
													<span className="ml-2">{note.note.content}</span>
												</div>
												<div className="flex flex-col mr-6 mb-2">
													<span className="font-bold uppercase">
														Last Updated
													</span>
													<span className="ml-2">
														{new Date(
															note.note.updated_at
														).toLocaleDateString('en-US', {
															year: '2-digit',
															month: '2-digit',
															day: '2-digit'
														})}
													</span>
												</div>
											</div>
										</div>
									) : null
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default RosterPlayerNotesModalMobile;
